<template>
  <div v-if="userDetails">
    <v-form ref="guestShippingAddressForm" v-model="valid" :lazy-validation="lazy">
      <v-row no-gutters v-show="!hideEmail">
        <v-col cols="12">
          <p class="text-uppercase d-none d-sm-block">
            already registered?
            <v-btn class="pl-0 text-black" variant="plain"  @click="signInForm=true" >
              <span class="text-uppercase ml-2 guest-shipping-address-login">click here to login</span>
            </v-btn>
          </p>
          <p class="text-uppercase fontsize-14 d-block d-sm-none">
            already registered?
            <v-btn class="pl-0 text-black"  variant="plain"  @click="signInForm=true" text>
              <span class="text-uppercase fontsize-14 mb-1 ml-2 guest-shipping-address-login">click here to login</span>
            </v-btn>
          </p>
          <v-form ref="loginForm" v-model="validLogin">
          <v-col class="px-0 pb-0 pt-2">        
              <v-text-field
                label="Email Address"
                @input="checkEmail()"
                @change="sendEmailId()"
                ref="email"
                name="email"
                v-model="email"
                :rules="emailRules"
                variant="outlined"
                density="compact"
                :rounded="0"
                class="shipping-address-form"
                color="primary"
              ></v-text-field>              
             
          </v-col>
        
          <span v-show="!passwordFields" class="pt-2">You can create an account after checkout.</span>
          <v-checkbox v-model="newsletter" label="Subscribe to our newsletter" color="indigo"></v-checkbox>
          </v-form>
        </v-col>
      </v-row>
      <p class="h3 mt-4" v-if="hideHeader">Billing Address</p>
      <p class="h3 my-1" v-else>Shipping Address</p>
      <v-row no-gutters class="pt-3">
        <v-col cols="12">
          <v-text-field
            label="First Name"
            ref="firstName"
            v-model="firstname"
            variant="outlined"
            density="compact"
            :rules="requiredFirstNameRules"
            @input="verifyAddress"
            class="shipping-address-form"
            color="primary"
          ></v-text-field>
          <v-text-field
            label="Last Name"
            ref="lastName"
            v-model="lastname"
            variant="outlined"
            density="compact"
            :rules="requiredLastNameRules"
            @input="verifyAddress"
            class="shipping-address-form pt-2"
            color="primary"
          ></v-text-field>
          <v-text-field
            label="Street Address"
            ref="streetAddressOne"
            v-model="streetNoOne"
            variant="outlined"
            density="compact"
            required
            :rules="requiredStreetAddressRules"
            @input="verifyAddress"
            @change="checkPOBoxAddress()"
            color="primary"
            class="shipping-address-form pt-2"
          ></v-text-field>
          <v-text-field
            label="Apt, Suite, Co., C/O etc."
            ref="streetAddressTwo"
            v-model="streetNoTwo"
            variant="outlined"
            density="compact"
            required
            @input="verifyAddress"
            @change="checkPOBoxAddress()"
            color="primary"
            class="shipping-address-form pt-2"
          ></v-text-field>
          <v-text-field
            label="City"
            variant="outlined"
            density="compact"
            ref="city"
            :rules="requiredCityRules"
            v-model="city"
            @input="verifyAddress"
            color="primary"
            class="shipping-address-form pt-2"
          ></v-text-field>
          <v-select
            :items="allCountries"
            variant="outlined"
            density="compact"
            label="Country"
            ref="country"
            item-title="label"
            v-model="countryId"
            item-value="value"
            :selected="countryId"
            @update:modelValue="fetchStates(countryId)"
            :rules="[val => !!val || 'Please select the Country']"
            class="shipping-address-form pt-2"
            color="primary"
          ></v-select>

          <v-text-field
            label="Zip/Postal Code"
            ref="zipCode"
            :rules="zipCodeRules"
            v-model="postcode"
            variant="outlined"
            density="compact"
            @input="verifyAddress"
            class="shipping-address-form pt-2"
            color="primary"
          ></v-text-field>
        
          <v-select
            :items="regionList"
            variant="outlined"
            density="compact"
            label="State/Province"
            v-model="regionId"
            item-title="label"
            item-value="value"
            @update:modelValue="checkRegionName()"
            :rules="[v => !!v || 'Please select the State']"
            class="shipping-address-form pt-4"
            color="primary"
          ></v-select>
 
          <v-text-field
            label="Telephone"
            ref="telephone"
            :rules="phoneNumberRules"
            v-model="telephone"
            variant="outlined"
            density="compact"
            @input="acceptNumber"
            class="shipping-address-form pt-2"
            color="primary"
          ></v-text-field>
          <span class="red--text caption ml-3" v-show="phoneNumberError">Telephone is required</span>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="signInForm" width="800">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0">Sign In</p>
          <v-btn @click="closeSignInForm()" variant="plain"> 
            <v-icon :icon="mdiWindowClose"/>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{errorMessage}}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signInMessage">{{signInMessage}}</v-alert>
          <v-form ref="loginForm" v-model="validLogin">
            <v-col class="py-0">
              <v-text-field
                v-model="email"
                type="email"
                label="Email Address"
                ref="email"
                name="email"
                variant="outlined"
                density="compact"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col class="py-0">
              <v-text-field
                v-model="password"
                label="Password"
                ref="password"
                type="password"
                name="password"
                variant="outlined"
                density="compact"
                :rules="passwordFields? passwordRules:[]"
              ></v-text-field>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn color="primary" block variant="flat"  size="large" @click="signIn()"> <v-progress-circular :size="20" color="white" indeterminate v-if="inProgress"></v-progress-circular> &nbsp;SIGN IN </v-btn>
            </v-col>
            <v-col cols="12" class="py-5" align="center">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="goToForgetPassword()"                
              >Forgot Your Password?</span>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script> 
import { mdiWindowClose } from '@mdi/js'
import { createHelpers } from "vuex-map-fields";
import { fetchStates } from "@/services/util.service"; 
const { mapFields: mapFieldsGuest } = createHelpers({
  getterType: "guestInfo/getField",
  mutationType: "guestInfo/updateField"
});

const { mapFields: mapFieldsAuth } = createHelpers({
  getterType: "auth/getField",
  mutationType: "auth/updateField"
});

export default {
  name: "GuestShippingAddressForm",
  props: ["hideEmail","hideHeader"],
  components: {  },
  data: () => ({
    mdiWindowClose,
    phoneNumberRules: [
      v => !!v || "Telephone is required",
      v => (v && v.length >= 8) || "Phone Number must be at least 8 characters"
    ],
     setCountryCode: "US",
    phoneNumberError: false,
    confirmationDialog: false,
    passwordFields: false,
    signInForm: false,
    newsletter: true,
    regionList: [],
    userDetails: true,
    valid: true, 
    validLogin: false,
    lazy: false,
    vTelInputProps: {
      dropdownOptions: {
        disabledDialCode: false
      },
      inputOptions: {
        showDialCode: true
      }
    },
    textRules: [
      v => !!v || "This field is required",
      v => (v && v.length >= 3) || "This field must be at least 3 characters"
    ],
    zipCodeRules: [
      v => !!v || "Zip code is required",
      v => (v && v.length >= 5) || "This field must be at least 5 characters"
    ],
    emailRules: [
      v => !!v || "Email is required",
      v => /.+@.+\..+/.test(v) || "Email must be valid"
    ],
    phoneNumberRules: [
      v => !!v || "Telephone is required",
      v => (v && v.length >= 8) || "Phone Number must be at least 8 characters"
    ],
    passwordRules: [
      v => !!v || "Password is required",
      v => (v && v.length >= 8) || "Password must be at least 8 characters"
    ],
     requiredFirstNameRules: [
    (v) => !!v || 'First Name is required'
  ],
  requiredLastNameRules: [
    (v) => !!v || 'Last Name  is required'
  ],
    requiredStreetAddressRules: [
    (v) => !!v || 'Street Address is required'
  ],
   requiredCityRules: [
    (v) => !!v || 'City is required'
  ],
  }), 
  computed: {
    ...mapFieldsGuest([
      "one",
  
      "address",
      "address.postcode",
      "address.telephone",
      "address.city",
      "address.countryId",
      "address.regionId",
      "address.region",
      "address.streetNoOne",
      "address.streetNoTwo",
      "address.firstname",
      "address.lastname",
    ]),
    ...mapFieldsAuth([
      "one",
      "one.email",
      "one.password",
      "errorMessage",
      "inProgress",
      "signInMessage"
    ]),
    allCountries: function() {
      return this.$store.state.accountOne.allCountries.map(countries => {
        return {
          value: countries.id,
          label: countries.fullNameEnglish
        };
      });
    },
    countryAndStatesList() {
      return this.$store.state.accountOne.allCountries;
    },
    emailCheckInProgress() {
      return this.$store.state.guestInfo.emailCheckInProgress;
    }
  },
  watch: {
    countryId: function() {
      this.updateRegionList();
    },
    valid() {
      this.$emit("actionValid", {valid:this.valid, email:this.one.email});
    }
  },
  methods: {
    acceptNumber() {
        let x = this.telephone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.telephone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
  async  signIn() {
      let validate=await this.$refs.loginForm.validate()
      if (validate && validate.valid) {
        this.$store.dispatch("auth/changeRedirectFlag", false);
        this.$store.dispatch("auth/login").then(status => {
          if (status) {
              this.$store.dispatch("auth/changeRedirectFlag", true);
              this.$store.dispatch("accountOne/basicInfo");
              this.$store.dispatch("userCartProduct/cartProductList");
              window.open(`/checkout`, "_self");
          } 
        });
      }
    },
    goToForgetPassword(){
      window.open(`/gate/forgot-password`, "_self");
    },
     sendEmailId() {
      this.$emit("actionValid", {valid:this.valid, email:this.one.email});
    },
    checkEmail() {
      this.$store
        .dispatch("guestInfo/checkEmailAvailable", this.one.email)
        .then(data => {
          if (data.exist == false) {
            this.passwordFields = true;
          } else {
            this.passwordFields = false;
          }
        });
    },
    closeSignInForm() {
      this.signInForm = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    verifyAddress() {
      this.$emit("verifyAddress");
    },
    checkRegionName() {
      let regionName = this.regionList.filter(obj => {
        if (obj.value == this.regionId) return obj;
      });
      this.$emit("regionDetails", regionName);
      this.$emit("verifyAddress");
    },
    fetchStates(countryId) {
    if( this.$refs.guestShippingAddressForm)
    {
     this.$refs.guestShippingAddressForm.resetValidation();
    }  
     this.regionList = fetchStates(countryId, this.countryAndStatesList);
      if (this.regionId) {
        let regionName = this.regionList.filter(obj => {
          if (obj.value == this.regionId) return obj;
        });
        this.$emit("regionDetails", regionName);
        this.$emit("verifyAddress");
      }
    },
    updateRegionList() {
      if (this.regionId) this.regionId = this.regionId.toString();
      this.regionList = fetchStates(this.countryId, this.countryAndStatesList);
    },
     validateNumber(number, data) {
      if (data&&data.possible && data.valid) this.phoneNumberError = false;
      else this.phoneNumberError = true;
    },
    async checkPOBoxAddress(){
      let street = [];
      street[0] = this.streetNoOne ? this.streetNoOne : "";
      if(this.streetNoTwo)
        street[1] = this.streetNoTwo ? this.streetNoTwo : "";
      let address = {
        street: street
      }
      await this.$store.dispatch("guestInfo/checkPOBoxAddress", address);
    }
  },
  async created() {
    await this.$store.dispatch("accountOne/fetchCountries");
    await this.fetchStates(this.countryId);
    this.updateRegionList();
    if(this.$refs.guestShippingAddressForm){
      this.$refs.guestShippingAddressForm.resetValidation();
    }
    if (this.valid) {
      this.$emit("actionValid", this.valid);
      this.$emit("verifyAddress");
    }
  }
};
</script>
<style src="./GuestShippingAddressForm.scss" lang="scss" />