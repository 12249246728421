<template>
  <div>
    <div v-if="pageContent.length">
      <div v-html="getPageContent()"></div>
    </div>
  </div>
</template>
<script>
import { addPictureTagInHtmlCode } from "@/services/util.service";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;
 
export default {
  name: "ShippingAndDeliveryComponent",
  components: {},
  computed: {
    pageContent: function () {
      return this.$store.state.layout.pageContent;
    },
  },
  data: () => ({
    pageUrlKey: "shipping-and-delivery",
    variants: [1366,768,360],
    mediaImageUrl
  }),
  methods: {
    openDayOneMobileView() {
      this.showDayOneOptionsMobileView();
      this.showDayOneOptionsMobileView();
      this.closeDaySecondOptionsMobileView();
      this.closeDayThirdOptionsMobileView();
      this.closeDayFourOptionsMobileView();
      this.closeDayFiveOptionsMobileView();
      this.closeDaySixOptionsMobileView();
    },
    openDaySecondMobileView() {
      this.showDaySecondOptionsMobileView();
      this.closeDayOneOptionsMobileView();
      this.closeDayThirdOptionsMobileView();
      this.closeDayFourOptionsMobileView();
      this.closeDayFiveOptionsMobileView();
      this.closeDaySixOptionsMobileView();
    },
    openDayThirdMobileView() {
      this.showDayThirdOptionsMobileView();
      this.closeDaySecondOptionsMobileView();
      this.closeDayOneOptionsMobileView();
      this.closeDayFourOptionsMobileView();
      this.closeDayFiveOptionsMobileView();
      this.closeDaySixOptionsMobileView();
    },
    openDayFourMobileView() {
      this.showDayFourOptionsMobileView();
      this.closeDaySecondOptionsMobileView();
      this.closeDayThirdOptionsMobileView();
      this.closeDayOneOptionsMobileView();
      this.closeDayFiveOptionsMobileView();
      this.closeDaySixOptionsMobileView();
    },
    openDayFiveMobileView() {
      this.showDayFiveOptionsMobileView();
      this.closeDaySecondOptionsMobileView();
      this.closeDayThirdOptionsMobileView();
      this.closeDayFourOptionsMobileView();
      this.closeDayOneOptionsMobileView();
      this.closeDaySixOptionsMobileView();
    },
    openDaySixMobileView() {
      this.showDaySixOptionsMobileView();
      this.closeDaySecondOptionsMobileView();
      this.closeDayThirdOptionsMobileView();
      this.closeDayFourOptionsMobileView();
      this.closeDayFiveOptionsMobileView();
      this.closeDayOneOptionsMobileView();
    },
    showDayOneOptionsMobileView() {
      var x = document.getElementById("dayOneOptionsMobileView");
      x.style.display = "block";
    },
    closeDayOneOptionsMobileView() {
      var x = document.getElementById("dayOneOptionsMobileView");
      x.style.display = "none";
    },
    showDaySecondOptionsMobileView() {
      var x = document.getElementById("daySecondOptionsMobileView");
      x.style.display = "block";
    },
    closeDaySecondOptionsMobileView() {
      var x = document.getElementById("daySecondOptionsMobileView");
      x.style.display = "none";
    },
    showDayThirdOptionsMobileView() {
      var x = document.getElementById("dayThirdOptionsMobileView");
      x.style.display = "block";
    },
    closeDayThirdOptionsMobileView() {
      var x = document.getElementById("dayThirdOptionsMobileView");
      x.style.display = "none";
    },
    showDayFourOptionsMobileView() {
      var x = document.getElementById("dayFourOptionsMobileView");
      x.style.display = "block";
    },
    closeDayFourOptionsMobileView() {
      var x = document.getElementById("dayFourOptionsMobileView");
      x.style.display = "none";
    },
    showDayFiveOptionsMobileView() {
      var x = document.getElementById("dayFiveOptionsMobileView");
      x.style.display = "block";
    },
    closeDayFiveOptionsMobileView() {
      var x = document.getElementById("dayFiveOptionsMobileView");
      x.style.display = "none";
    },
    showDaySixOptionsMobileView() {
      var x = document.getElementById("daySixOptionsMobileView");
      x.style.display = "block";
    },
    closeDaySixOptionsMobileView() {
      var x = document.getElementById("daySixOptionsMobileView");
      x.style.display = "none";
    },

    openDayOne() {
      this.showDayOneOptions();
      this.closeDaySecondOptions();
      this.closeDayThirdOptions();
      this.closeDayFourOptions();
      this.closeDayFiveOptions();
      this.closeDaySixOptions();
    },
    openDaySecond() {
      this.showDaySecondOptions();
      this.closeDayOneOptions();
      this.closeDayThirdOptions();
      this.closeDayFourOptions();
      this.closeDayFiveOptions();
      this.closeDaySixOptions();
    },
    openDayThird() {
      this.showDayThirdOptions();
      this.closeDaySecondOptions();
      this.closeDayOneOptions();
      this.closeDayFourOptions();
      this.closeDayFiveOptions();
      this.closeDaySixOptions();
    },
    openDayFour() {
      this.showDayFourOptions();
      this.closeDaySecondOptions();
      this.closeDayThirdOptions();
      this.closeDayOneOptions();
      this.closeDayFiveOptions();
      this.closeDaySixOptions();
    },
    openDayFive() {
      this.showDayFiveOptions();
      this.closeDaySecondOptions();
      this.closeDayThirdOptions();
      this.closeDayFourOptions();
      this.closeDayOneOptions();
      this.closeDaySixOptions();
    },
    openDaySix() {
      this.showDaySixOptions();
      this.closeDaySecondOptions();
      this.closeDayThirdOptions();
      this.closeDayFourOptions();
      this.closeDayFiveOptions();
      this.closeDayOneOptions();
    },
    showDayOneOptions() {
      var x = document.getElementById("dayOneOptions");
      x.style.display = "block";
    },
    closeDayOneOptions() {
      var x = document.getElementById("dayOneOptions");
      x.style.display = "none";
    },
    showDaySecondOptions() {
      var x = document.getElementById("daySecondOptions");
      x.style.display = "block";
    },
    closeDaySecondOptions() {
      var x = document.getElementById("daySecondOptions");
      x.style.display = "none";
    },
    showDayThirdOptions() {
      var x = document.getElementById("dayThirdOptions");
      x.style.display = "block";
    },
    closeDayThirdOptions() {
      var x = document.getElementById("dayThirdOptions");
      x.style.display = "none";
    },
    showDayFourOptions() {
      var x = document.getElementById("dayFourOptions");
      x.style.display = "block";
    },
    closeDayFourOptions() {
      var x = document.getElementById("dayFourOptions");
      x.style.display = "none";
    },
    showDayFiveOptions() {
      var x = document.getElementById("dayFiveOptions");
      x.style.display = "block";
    },
    closeDayFiveOptions() {
      var x = document.getElementById("dayFiveOptions");
      x.style.display = "none";
    },
    showDaySixOptions() {
      var x = document.getElementById("daySixOptions");
      x.style.display = "block";
    },
    closeDaySixOptions() {
      var x = document.getElementById("daySixOptions");
      x.style.display = "none";
    },
    getPageContent() {
      if (this.pageContent.length) {
        let data = this.pageContent.filter((element) => {
          return element.identifier === "shipping-and-delivery";
        });
        if (data.length > 0) {
          let path = data[0].content;
          var div = document.createElement("div");
          div.innerHTML = path;
          let imgSrcUrls = div.getElementsByTagName("img");
          for (let i = 0; i < imgSrcUrls.length; i++) {
            imgSrcUrls[i].parentElement.innerHTML = addPictureTagInHtmlCode({currentImageTag: imgSrcUrls[i], variants: this.variants, mediaImageUrl })
          }
          return div.innerHTML;
        }
      }
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PATH_URL;
    },
  },
};
</script>
<style lang="scss">


.primary{
  background-color: #2850da !important;
    border-color: #2850da !important;
}

@media (min-width: 600px) {
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}




.background-primary {
  background-color: #2850da;
}
@media (min-width: 1264px) {
  .container-grid {
    max-width: 1185px;
  }
}
.container-grid {
  width: 100%;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}


#secondTable {
  display: none;
}
#thirdTable {
  display: none;
}
#firstColumnTable {
  display: none;
}
#secondColumnTable {
  display: none;
}
#thirdColumnTable {
  display: none;
}

.shipping-delivery-map-image {
  width: 100%;
  min-height: 415px;
}
.shipping-delivery-col-10 {
  max-width: 83%;
}
.shipping-delivery-image {
  width: 100%;
}
.shipping-delivery-col-4 {
  width: 33.33%;
}
.shipping-delivery-row {
  display: flex;
}
.shipping-delivery-height-315 {
  height: 315px;
}
.shipping-delivery-cols-6 {
  width: 50% !important;
}
.shipping-delivery-ups-first {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.shipping-delivery-ups-second {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.shipping-delivery-ups-third {
  background-position: left center;
  background-size: cover;
  background-repeat: no-repeat;
}
.shipping-delivery-page-primary-link {
  color: #2850da !important;
  & .shipping-delivery-page-icon {
    background-image: url("@/assets/right-arrow.png");
    max-width: 22px;
    height: 22px;
    background-position: right center;
    background-size: contain;
  }
}
.shipping-delivery-page-primary-link:hover {
  color: #fff !important;
  & .shipping-delivery-page-icon {
    background-image: url("@/assets/right-arrow-white.png");
    max-width: 22px;
    height: 22px;
    background-position: right center;
    background-size: contain;
  }
}
.shipping-delivery-page-link {
  color: #ccb7ff !important;
  & .shipping-delivery-page-icon {
    background-image: url("@/assets/right-hover-arrow.png");
    max-width: 22px;
    height: 22px;
    background-position: right center;
    background-size: contain;
  }
}
.shipping-delivery-page-link:hover {
  color: #fff !important;
  & .shipping-delivery-page-icon {
    background-image: url("@/assets/right-arrow-white.png");
    max-width: 22px;
    height: 22px;
    background-position: right center;
    background-size: contain;
  }
}
.showonhover {
  width: 30%;
}
.shipping-delivery-hero-min-height-800 {
  min-height: 800px;
}
.shipping-delivery-horizontal-line {
  border: 0.0625rem solid #2850da !important;
  width: 100%;
  content: "";
  display: block;
}
.shipping-delivery-vertical-line {
  border-left: 1px solid #2850da;
  height: 35px;
}
.shipping-delivery-days-button {
  display: flex;
  align-items: center;
  width: 130px;
  border: thin solid #2850da;
}
.shipping-delivery-days-button-mobileview {
  display: flex;
  align-items: center;
  width: 100%;
  border: none !important;
}

.shipping-delivery-dayone-inside {
  display: flex;
  align-items: center;
  width: 130px;
}
.shipping-delivery-days-options {
  display: none;
}
.shipping-delivery-days-button:hover .shipping-delivery-days-options {
  display: block;
  position: absolute;
  background-color: #fff;
  width: 130px;
  margin-left: -1px;
  z-index: 9999;
  border: thin solid #2850da;
}
#dayOneOptions {
  display: none;
}
#daySecondOptions {
  display: none;
}
#dayThirdOptions {
  display: none;
}
#dayFourOptions {
  display: none;
}
#dayFiveOptions {
  display: none;
}
#daySixOptions {
  display: none;
}
#dayOneOptionsMobileView {
  display: none;
}
#daySecondOptionsMobileView {
  display: none;
}
#dayThirdOptionsMobileView {
  display: none;
}
#dayFourOptionsMobileView {
  display: none;
}
#dayFiveOptionsMobileView {
  display: none;
}
#daySixOptionsMobileView {
  display: none;
}
.shipping-delivery-days-options-mobileview {
  display: block;
  position: absolute;
  background-color: #fff;
  width: 130px;
  margin-left: -1px;
  z-index: 9999;
  border: thin solid #2850da;
}
.shipping-delivery-dayone-square {
  width: 35px;
  height: 35px;
  background-color: #2850da;
}
.shipping-delivery-daysix-inside-square {
  width: 35px;
  height: 35px;
  border-bottom: thin solid #2850da;
}
.shipping-delivery-daysecond-square {
  opacity: 0.8 !important;
  width: 35px;
  height: 35px;
  background-color: #2850da;
}
.shipping-delivery-daythird-square {
  opacity: 0.6 !important;
  width: 35px;
  height: 35px;
  background-color: #2850da;
}
.shipping-delivery-dayfour-square {
  opacity: 0.4 !important;
  width: 35px;
  height: 35px;
  background-color: #2850da;
}
.shipping-delivery-dayfive-square {
  opacity: 0.2 !important;
  width: 35px;
  height: 35px;
  background-color: #2850da;
}
.shipping-delivery-daysix-square {
  opacity: 0 !important;
  width: 35px;
  height: 35px;
}

.plus {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  background-position: center;
  background-size: 40% 2px, 2px 40%; 
  background-repeat: no-repeat;
}
.minus {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  background-position: center;
  background-size: 40% 2px;  
  background-repeat: no-repeat;
}
@media (min-width: 600px) and (max-width: 768px) {
  .shipping-delivery-days-button {
    display: flex;
    align-items: center;
    width: 100px;
    border: thin solid #2850da;
  }
}
@media screen and (max-width: 600px) {
  .shipping-delivery-cols-6 {
    width: 100%;
  }

  .shipping-delivery-row {
    display: block;
  }
  .shipping-delivery-col-4 {
    width: 100%;
  }
}
</style>
<style src="./ShippingAndDeliveryComponent.scss" lang="scss" scoped />