<template>
  <div class="white">
   <div v-if="pageContent.length">
      <div v-html="getPageContent()"></div>
    </div>
    <FooterComponent />
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
import { addPictureTagInHtmlCode } from "@/services/util.service";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;
 
export default {
  name: "Careers",
  data() {
    return {
       variants: [1366,768,360],
    };
  },
   metaInfo() {
        return {
            link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ] 
        }
  },
  components: {
    FooterComponent
  },
  watch: {},
  computed: {
    pageContent: function() {
      return this.$store.state.layout.pageContent;
    },
    canonical: function() {
      return this.$route.meta.canonical;
    }, 
  },
  methods: {
    getPageContent() {
      if (this.pageContent.length) {
        let data = this.pageContent.filter(element => {
          return element.identifier === "careers-page";
        });
        if (data.length > 0) {
          let path = data[0].content;
          var div = document.createElement("div");
          div.innerHTML = path;
          let imgSrcUrls = div.getElementsByTagName("img");
          for (let i = 0; i < imgSrcUrls.length; i++) {
            imgSrcUrls[i].parentElement.innerHTML = addPictureTagInHtmlCode({currentImageTag: imgSrcUrls[i], variants: this.variants, mediaImageUrl })
          }
          return div.innerHTML;
        }
      }
    },
     imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PATH_URL;
    },
  },
  mounted() {},
  created() {}
};
</script>
<style src="./careers.scss" lang="scss" scopped/>