<template>
  <div>
    <HomeComponent/>
    <FooterComponent/>
  </div>
</template>
<script> 
const baseUrl = import.meta.env.VITE_BASE_URL;
import HomeComponent from "@/components/crane/home/HomeComponent.vue";
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
export default {
  name: "HomePage",
  data: () => ({ 
  }),
   components: {
    HomeComponent,
    FooterComponent
  },
  computed:{
    canonical: function() {
      return this.$route.meta.canonical;
    }, 
  },
   metaInfo() {
        return {
           titleTemplate: "Personalized Stationery | Crane Personalized Stationery Cards | Boxed Sets",
           title: "Personalized Stationery | Crane Personalized Stationery Cards | Boxed Sets",
           description  : "Explore the personalized cards, invitations, and more quality stationery at Crane. Customize the stationery you need to share the special moments in your life." ,
           keywords  : "stationery, personalized stationery, stationary, wedding invitations,  wedding stationery, crane stationery, holiday invitations, crane, crane personalized stationery, stationary" ,
           robots :"INDEX,FOLLOW",
         
             link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ] 
        }
  },
   created() {
       window.criteo_q.push(           
            { event: "setEmail", email: "" }, // Can be an empty string         
            { event: "viewHome" }
        );
   }
};
</script>
 