<template>
  <div class="white">
    <v-container class="my-10" ref="divOne">
      <h1 class="font-weight-medium">Terms & Conditions</h1>
      <p
        class="my-6 fontcolor-black-light"
      >Please read these Terms and Conditions carefully before using this website. Your use of this website confirms your unconditional acceptance of the following Terms and Conditions. If you do not accept these terms and conditions, do not use this website.</p>
      <h3 class="font-weight-medium my-6">Our Website</h3>
      <p
        class="my-6 fontcolor-black-light"
      >We make all reasonable effort to accurately display our products and the products of third parties on our website. However, we cannot guarantee your computer will accurately display the product features (e.g., color) and therefore do not guarantee the color of the products ordered will match exactly the color you see on your computer. We retain the right to change all features, specifications, content, products and prices of products or services at any time without notice and the inclusion of products and services on this website is not a guaranty that these products and services will be available at any time.</p>

      <h3 class="font-weight-medium my-6">Laws</h3>
      <p
        class="my-6 fontcolor-black-light"
      >It is your responsibility to ascertain and obey all applicable local, state, federal and international laws (including minimum age requirements) relating to the possession, use and sale of any item purchased from this website. By placing an order, you represent that the products ordered will be used only in a lawful manner.</p>

      <h3 class="font-weight-medium my-6">Shipping</h3>
      <p
        class="my-6 fontcolor-black-light"
      >Items will be shipped to an address designated by the purchase as long as that shipping address is compliant with the shipping restrictions contained on this website and U.S. export laws and regulations. No order will be shipped contrary to U.S. export laws and regulations. All purchases from this website are made pursuant to a shipment contract. As a result, risk of loss and title of the purchased items pass to you upon delivery of the items to the carrier. It is your responsibility for filing any claims with carriers for damaged and/or lost shipments.</p>
      <h3 class="font-weight-medium my-6">Use Of This Website</h3>
      <p
        class="mt-6 mb-0 pb-0 fontcolor-black-light"
      >The website design and all text, graphics, information, content, and other material displayed on or that can be downloaded from this website are the property of Crane & Co., Inc. or are used with permission from certain third parties and are protected by copyright, trademark and other laws and may not be used except as permitted in these terms and conditions or with the prior written permission of Crane & Co., Inc. As such, you may not, without the written permission of Crane & Co., Inc.:</p>
      <ul>
        <li class="fontcolor-black-light">modify the information or materials located on this website; or</li>
        <li class="fontcolor-black-light">reproduce or publicly display, or distribute or otherwise use any such materials for any public or commercial purpose; or</li>
        <li class="fontcolor-black-light">link to this website from another website; or</li>
        <li class="fontcolor-black-light">display this website or any material displayed on this website in frames or through similar means on another website; or</li>
        <li class="fontcolor-black-light">post or transmit any unlawful, threatening, defaming, libelous, obscene, pornographic or profane material or any material that could constitute or encourage or that would be considered a criminal offense or give rise to civil liability, or otherwise violate any law; or</li>
        <li class="fontcolor-black-light">otherwise use the content of this website.</li>
      </ul>
      <p
        class="mb-6 fontcolor-black-light"
      >Unauthorized use of any such information or materials may violate copyright laws, trademark laws, laws of privacy and publicity, and other laws and regulations.</p>
      <h3 class="font-weight-medium my-6">Trademarks</h3>
      <p
        class="my-6 fontcolor-black-light"
      >Certain trademarks, trade names, service marks and logos used or displayed on this website registered and unregistered trademarks, trade names and service marks of Crane & Co., Inc. or other trademarks, trade names and service marks used or displayed on this website are the registered and unregistered trademarks, trade names and service marks of their respective owners. Nothing contained on this website grants or should be construed as granting, by implication, estoppels, or otherwise, any license or right to use any trademarks, trade names, service marks or logos displayed on this website without the written permission of Crane & Co., Inc. or other third party owner.</p>
      <h3 class="font-weight-medium my-6">Liability</h3>
      <p
        class="my-6 fontcolor-black-light"
      >Crane & Co., Inc. does not assume any responsibility, and shall not be liable for, any damages to, or viruses that may infect, your computer, telecommunication equipment, or other property caused by or arising from your access to, use of, or browsing this web site or your downloading of any materials, from this web site. In no event will Crane & Co., Inc., and its respective officers, directors, shareholders, affiliates, agents, successors, assigns, retail partners nor any party involved in the creation, production or transmission of this web site be liable to any party for any indirect, special, punitive, incidental or consequential damages (including, without limitation, those resulting from lost profits, lost data or business interruption) arising out of the use, inability to use, or the results of use of this web site, any web sites linked to this web site, or the materials, information or services contained at any or all such sites, whether based warranty, contract, tort or any other legal theory and whether or not advised of the possibility of such damages. The foregoing limitations of liability do not apply to the extent prohibited by law.</p>
      <h3 class="font-weight-medium my-6">Revisions</h3>
      <p
        class="my-6 fontcolor-black-light"
      >Crane & Co., Inc. may revise these terms and conditions at any time by updating this posting.</p>
      <h3 class="font-weight-medium my-6">Governing Law</h3>
      <p
        class="my-6 fontcolor-black-light"
      >These terms and conditions will be governed by and construed in accordance with the laws of the Commonwealth of Massachusetts. Any action seeking legal or equitable relief arising out of or relating to this website shall be brought only in the courts of the Commonwealth of Massachusetts or the United States District Court of Massachusetts, Springfield.</p>
      <h3 class="font-weight-medium my-6">Termination</h3>
      <p
        class="my-6 fontcolor-black-light"
      >Crane & Co., Inc. has the right to suspend or terminate your account or your use of this website at any time, for any reason or for no reason. You are personally liable for any orders that you place or charges that you make prior to termination. Crane reserves the right to change, suspend, or discontinue all or any aspect of this web at any time without notice.</p>
    </v-container>
    <FooterComponent />
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import * as easings from "vuetify/lib/services/goto/easing-patterns";
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
 
export default {
  name: "TermsAndConditions",
  data() {
    return {
      easings: Object.keys(easings)
    };
  },
  components: {
    FooterComponent
  },
  watch: {},
  computed: {
    canonical: function() {
      return this.$route.meta.canonical;
    }
  },
    metaInfo() {
        return {
             link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ] 
        }
  },
  methods: {
      pageScrollUp() {
      const data=  this.$refs.divOne
      if(data){
        this.$smoothScroll({
        scrollTo: data.$el.scrollTop, 
        duration: 300, 
        easingFunction: "easeInOutCubic",
        updateHistory: false
      })
      }

    }
  },
  mounted() {
    this.pageScrollUp();
  },
  
};
</script>