<template>
  <div class="white d-flex flex-column page-height">
    <v-container class="mb-auto px-4">
      <v-row>
        <v-col cols="12" class="d-flex justify-space-between align-center">
          <span
            class="my-3 font-weight-medium regular-font text-primary h3 text-uppercase"
            >thank you for your purchase!</span
          >
          <v-btn
            text
            class="text-primary"
            @click="printOrder('showInPrint')"
            v-if="userAuth"
            >Print receipt</v-btn
          >
        </v-col>
        <v-col cols="12" class="pt-1">
          <p class="body-font h4 mb-3" v-if="userAuth">
            Your order number is :
            <router-link
              to
              class="text-decoration-none text-primary fontcolor-black-light"
            >
              <span @click="goToOrder(orderNo)">{{
                orderProduct.incrementId
              }}</span>
            </router-link>
          </p>
          <p class="body-font h4 mb-3" v-if="!userAuth">
            Your order # is : {{ orderProduct.incrementId }}
          </p>
          <p class="body-font h4 mb-3">
            We'll email you an order confirmation with details and tracking
            info.
          </p>
          <v-hover v-slot="{ isHovering, props }">
            <v-btn
              class="text-uppercase ls-0 body pb-1 productpage-button"
              :variant="isHovering ? 'outlined' : '' "
              v-bind="props"
              :class="isHovering ? 'text-primary bg-white' : 'text-white bg-primary'"
              tile
              depressed
              @click="goToHomePage()"
              >Continue Shopping</v-btn
            >
          </v-hover>
        </v-col>
        <v-col cols="12" class="pt-1" v-if="userAuth">
          <v-hover v-slot="{ isHovering, props }">
            <v-btn
              class="text-uppercase ls-0 body pb-1 productpage-button"
              :variant="isHovering ? 'outlined' : '' "
              v-bind="props"
              :class="isHovering ? 'text-primary bg-white' : 'text-white bg-primary'"
              tile
              depressed
              @click="goToOrder(orderNo)"
              >View Order</v-btn
            >
          </v-hover>
        </v-col>
        <v-col cols="12" class="" v-if="!userAuth">
          <p class="body-font h4 mb-3">
            You can track your order status by creating an account.
          </p>
          <p class="body-font h4 mb-3">
            Email Address: {{ orderProduct.customerEmail }}
          </p>
          <v-hover v-slot="{ isHovering, props }">
            <v-btn
              class="text-uppercase ls-0 body pb-1 mb-4 productpage-button"
              :variant="isHovering ? 'outlined' : '' "
              v-bind="props"
              :class="isHovering ? 'text-primary bg-white' : 'text-white bg-primary'"
              tile
              depressed
              @click="goToSignUp()"
              >Create an Account</v-btn
            >
          </v-hover>
        </v-col>
      </v-row>
      <v-row no-gutters id="showInPrint" class="d-none">
        <v-col cols="12">
          <v-row
            align="center"
            id="showInPrintOnly"
            class="order-products-heading"
          >
            <v-col cols="auto">
              <h1 class="order-product-id">
                Order #{{ orderProduct.incrementId }}
              </h1>
            </v-col>
            <v-col cols="auto">
              <v-btn class="order-product-status" tile variant="outlined" text>
                <span
                  class="text-uppercase text-black"
                  v-if="
                    orderProduct.status == 'failed_to_ship' ||
                    orderProduct.status == 'waiting_to_ship'
                  "
                  >Processing Order</span
                >
                <span class="text-uppercase text-black" v-else>{{
                  orderProduct.status
                }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <p class="pt-4 mb-3">{{ dateString(orderProduct.createdAt) }}</p>
          <ViewOrderTabs
            :viewOrderItem="orderProduct"
            :productInvoice="productInvoice"
          ></ViewOrderTabs>
        </v-col>
      </v-row>
    </v-container>
    <img
      src="https://fp.listrakbi.com/fp/yJ3fjhPTdCKx.jpg"
      height="1"
      width="1"
    />
    <FooterComponent />
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
import ViewOrderTabs from "@/components/me/my-orders/ViewOrderTabs.vue";
import { getCookieByName } from "@/services/auth";
 const crGuestQuoteId = import.meta.env.VITE_GUEST_QUOTE_ID;

export default {
  name: "OrderSuccess",
  data() {
    return {
    
    };
  },
  components: {
    FooterComponent,
    ViewOrderTabs,
  },
  watch: {},
  computed: {
    canonical: function () {
      return this.$route.meta.canonical;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function () {
      return getCookieByName(crGuestQuoteId); 
    },
    orderNo: function () {
      return this.$route.params.orderNumber;
    },
    userEmailAddress: function () {
      return JSON.parse(localStorage.getItem("cr_guestShippingAddress"));
    },
    orderProduct() {
      return this.$store.state.meProductOne.orderProduct;
    },
    productInvoice() {
      return this.$store.state.meProductOne.productInvoice;
    },
  },
  metaInfo() {
    return {
      link: [
        {
          rel: "canonical",
          href: `${
            this.canonical ? window.location.href.split("?")[0] : baseUrl
          }`,
        },
      ],
    };
  },
  methods: {
    goToOrder(orderNo) {
      window.open("/me/my-orders/" + orderNo, "_self");
    },
    goToHomePage() {
      this.$router.push({
        name: "HomePage",
      });
    },
    goToSignUp() {
      window.open("/gate/sign-up", "_self");
    },
    printOrder(divName) {
      const prtHtml = document.getElementById(divName).innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
      <head>
      ${stylesHtml}
      </head>
      <body>
      ${prtHtml}
      </body>
      </html>`);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    isoStringToNormalDate(date) {
      let newDateTime = Date.parse(date);
      let tmp = new Date(newDateTime).toLocaleDateString().split("/");
      return (date = tmp[1] + "/" + tmp[0] + "/" + tmp[2]);
    },
    dateString(date) {
      let newDateTime = Date.parse(date);
      return new Date(newDateTime).toDateString();
    },
  },
  mounted() {},

  created() {
    if (this.userAuth) {
      this.$store.dispatch("userCartProduct/cartProductList"); 
      this.$store.dispatch("meProductOne/fetchOrderProduct", this.orderNo);
    } else {
      if (this.guestQuoteId) {
        this.$store.dispatch("cartProduct/cartProductList", this.guestQuoteId);
        this.$store.dispatch("cartProduct/productTotalAmt", this.guestQuoteId);
      }
      this.$store.dispatch("meProductOne/fetchOrderProduct", this.orderNo);
    }
  },
};
</script>