<template>
  <div>
   </div>
</template>
<script>
 export default {
  name: "ResetPasswordPage",
  created() {
      window.open("/gate/reset-password/"+this.$route.query.token, "_self");
      
  }
};
</script>
