<template>
  <div class="mb-12">
    <div v-if="pageContent.length">
      <div v-html="getPageContent()"></div>
    </div>
  </div>
</template>
<script>
import imageData from "@/assets/c4aa164d9b01387a88228c67620f7faf861b5544.png";
import { addPictureTagInHtmlCode } from "@/services/util.service";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;

export default {
  name: "FaqComponent",
  data() {
    return {
      image: imageData,
      variants: [1366,768,360],
      mediaImageUrl
    };
  },
  computed: {
    pageContent: function () {
      return this.$store.state.layout.pageContent;
    },
  },
  methods: {
    getPageContent() {
      if (this.pageContent.length) {
        let data = this.pageContent.filter((element) => {
          return element.identifier === "frequently-asked-questions";
        });
        if (data.length > 0) {
          let path = data[0].content;
          var div = document.createElement("div");
          div.innerHTML = path;
          let imgSrcUrls = div.getElementsByTagName("img");
          for (let i = 0; i < imgSrcUrls.length; i++) {
            imgSrcUrls[i].parentElement.innerHTML = addPictureTagInHtmlCode({ currentImageTag: imgSrcUrls[i], variants: this.variants, mediaImageUrl })
          }
          return div.innerHTML;
        }
      }
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PATH_URL;
    },
    scrollToFaqHead(headName) {
      var element = this.$el.querySelector("#" + this.removeSpace(headName));
      var top = element.offsetTop;
      window.scrollTo(0, top - 60);
    },
    removeSpace(faqHead) {
      return faqHead
        .trim()
        .replace(/[^a-z0-9]+/gi, "-")
        .toLowerCase();
    },
  },
  mounted() {
    window.onload = function () {


      document.getElementById("ordersFirstButton").onclick = function () {
        expandOrders("ordersFirstButton");
      };
      document.getElementById("ordersSecondButton").onclick = function () {
        expandOrders("ordersSecondButton");
      };
      document.getElementById("ordersThirdButton").onclick = function () {
        expandOrders("ordersThirdButton");
      };
      document.getElementById("ordersFourthButton").onclick = function () {
        expandOrders("ordersFourthButton");
      };
      document.getElementById("ordersFifthButton").onclick = function () {
        expandOrders("ordersFifthButton");
      };
      document.getElementById("ordersSixthButton").onclick = function () {
        expandOrders("ordersSixthButton");
      };
      document.getElementById("ordersSeventhButton").onclick = function () {
        expandOrders("ordersSeventhButton");
      };
      document.getElementById("paymentFirstButton").onclick = function () {
        expandOrders("paymentFirstButton");
      };
      document.getElementById("paymentSecondButton").onclick = function () {
        expandOrders("paymentSecondButton");
      };
      document.getElementById("paymentThirdButton").onclick = function () {
        expandOrders("paymentThirdButton");
      };
      document.getElementById("paymentFourthButton").onclick = function () {
        expandOrders("paymentFourthButton");
      };
      document.getElementById("shippingFirstButton").onclick = function () {
        expandOrders("shippingFirstButton");
      };
      document.getElementById("shippingSecondButton").onclick = function () {
        expandOrders("shippingSecondButton");
      };
      document.getElementById("shippingThirdButton").onclick = function () {
        expandOrders("shippingThirdButton");
      };
      document.getElementById("shippingFourthButton").onclick = function () {
        expandOrders("shippingFourthButton");
      };
      document.getElementById("shippingFifthButton").onclick = function () {
        expandOrders("shippingFifthButton");
      };
      document.getElementById("shippingSixthButton").onclick = function () {
        expandOrders("shippingSixthButton");
      };
      document.getElementById("shippingSeventhButton").onclick = function () {
        expandOrders("shippingSeventhButton");
      };
      document.getElementById("contactFirstButton").onclick = function () {
        expandOrders("contactFirstButton");
      };
      document.getElementById("contactSecondButton").onclick = function () {
        expandOrders("contactSecondButton");
      };
      document.getElementById("paperFirstButton").onclick = function () {
        expandOrders("paperFirstButton");
      };
      document.getElementById("paperSecondButton").onclick = function () {
        expandOrders("paperSecondButton");
      };

      function expandOrders(divId) {
        var array = ["ordersFirstButton", "ordersSecondButton", "ordersThirdButton", "ordersFourthButton", "ordersFifthButton", "ordersSixthButton", "ordersSeventhButton", "paymentFirstButton", "paymentSecondButton", "paymentThirdButton", "paymentFourthButton", "shippingFirstButton", "shippingSecondButton", "shippingThirdButton", "shippingFourthButton", "shippingFifthButton", "shippingSixthButton", "shippingSeventhButton", "contactFirstButton", "contactSecondButton", "paperFirstButton", "paperSecondButton"];

        array.filter((val) => {
          if (val == divId) {
            document.getElementById(val).classList.toggle("active");
            var content = document.getElementById(val).nextElementSibling;
            if (content.style.maxHeight) {
              content.style.maxHeight = null;
            } else {
              content.style.maxHeight = content.scrollHeight + "px";
            }
          } else {
            document.getElementById(val).classList.remove("active");
            var content2 = document.getElementById(val).nextElementSibling;
            content2.style.maxHeight = null;
          }
        });
      }



      document.getElementById("faqPaper").onclick = function () {
        scrollToDiv("paper", "faqPaper");
      };
      
      document.getElementById("faqOrders").onclick = function () {
        scrollToDiv("orders", "faqOrders");
      };
      
      document.getElementById("faqPayment").onclick = function () {
        scrollToDiv("payment", "faqPayment");
      };
      document.getElementById("faqShipping").onclick = function () {
        scrollToDiv("shipping", "faqShipping");
      };
      document.getElementById("faqContact").onclick = function () {
        scrollToDiv("contact", "faqContact");
      };
      function scrollToDiv(divId, textId) {

        var elmnt = document.getElementById(divId);
        elmnt.scrollIntoView();
        var faqIds = [
          "faqPaper",
          "faqProofs",
          "faqOrders",
          "faqCustom",
          "faqPayment",
          "faqShipping",
          "faqContact"
        ];

        faqIds.filter((val) => {
          if (val == textId) {
            document.getElementById(val).style.color = "#000";
          } else {
            document.getElementById(val).style.color = "#2805da";
          }
        });
         
      }
    };
  },
};
</script>
<style>
.faq-content > .v-expansion-panel-content__wrap {
  padding-left: 0 !important;
}
.faq-panel.v-expansion-panel--active
  > .v-expansion-panel-header--active
  .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate)
  .v-icon {
  color: #2805da !important;
}
.faq-head-list .router-link-active {
  color: #1d1d1d !important;
}
.heroContent{
  padding-left: 60px;
  padding-right: 60px;
}
</style>
<style src="./FaqComponent.scss" lang="scss"/>
