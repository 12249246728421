 <template>
  <div>
    <ShippingAndDeliveryComponent></ShippingAndDeliveryComponent>
   
    <PostScriptBlog />
    <FooterComponent /> 
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;

import ShippingAndDeliveryComponent from "@/components/crane/shipping-delivery/ShippingAndDeliveryComponent.vue";
import PostScriptBlog from "@/components/crane/home/PostScriptBlog.vue";
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
import { goToCategoryPageRouting } from "@/services/util.service";
export default {
  name: "ShippingDeliveryCmsPage",
  components: {
    ShippingAndDeliveryComponent,
    PostScriptBlog,
    FooterComponent,
  },
  computed: {
    canonical: function () {
      return this.$route.meta.canonical;
    },
  },
  data: () => ({}),
  metaInfo() {
    return {
      link: [
        {
          rel: "canonical",
          href: `${
            this.canonical ? window.location.href.split("?")[0] : baseUrl
          }`,
        },
      ],
    };
  },
  methods: {
    routeToPage(pageName) {
      if (pageName == "CategoryPage") {
        let vm = this;
        goToCategoryPageRouting(vm, { n: "Stationery", id: 10 });
      }
    },
  },
 
};
</script>
