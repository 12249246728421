<template>
  <div>
    <div v-html="getPostScriptContent()"></div>
  </div>
</template>
<script>
import { addPictureTagInHtmlCode } from "@/services/util.service";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;

export default {
  name: "PostScriptBlog",
  data: () => ({
    variants: [1366,768,360],
    mediaImageUrl
  }),
  computed: {
      homeBaseUrl() {
      return import.meta.env.VITE_HOME_PAGE_URL;
    },
     postScriptContent: function() {
      return this.$store.state.layout.cmsBlockContent;
    }
  },
  methods: {
      imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PATH_URL;
    },
     getPostScriptContent(){
      if (this.postScriptContent.length) {
        let data = this.postScriptContent.filter(element => {
          return element.identifier === "post-script";
        });
         if(data.length > 0){
        let path = data[0].content;
        var div = document.createElement("div");
        div.innerHTML = path;
        let imgSrcUrls = div.getElementsByTagName("img");
        for (let i = 0; i < imgSrcUrls.length; i++) {
          imgSrcUrls[i].parentElement.innerHTML = addPictureTagInHtmlCode({currentImageTag: imgSrcUrls[i], variants: this.variants, mediaImageUrl })
        }
        return div.innerHTML;
      }
      }
    },
     goToLink(pageName) {
      window.location.replace(this.homeBaseUrl+pageName);
    
    }
  },

};
</script>
<style lang="scss">
.post-script-block-image{
  width:100%;
  height:auto;
}
.postscriptblog-image-card .v-ripple__container{
    opacity: 0 !important;
  }
  
  .postscriptblog-image-card.v-card--link:focus:before {
    opacity: 0 !important;
  }
  .postscriptblog-line {
    border-bottom: 1px solid #3d4973;
    width: 1.87rem;
    margin: 1.53rem 0rem 1.21rem 0rem;
    size: 1.87rem;
}
.post-script-link{
    color: #2c50da !important;
    & .home-page-icon{
    background-image: url('@/assets/blue-arrow.png'); 
    max-width:22px;
    height:13px;
    background-position: right center;
    background-size:contain;
}
}
.post-script-link:hover{
    color: #CCB7FF !important;
    & .home-page-icon{
    background-image: url('@/assets/hover-arrow.png');
    max-width:22px;
   height:13px;
   background-position: right center;
   background-size:contain;
}
}
.post-script-row{
  display:flex;
  height:100%;
  width:100%;
}
.post-script-col-4{
  width:33.33%;
  display:flex;
}
@media screen and (max-width: 600px) {
  .post-script-row{
  display:block;
  height:100%;
  width:100%;
}
.post-script-col-4{
  width:100%;
  display:block;
}
.container{
  padding:0px;
}
}
@media (min-width: 1264px)
{
  .container-grid {
    max-width: 1185px;
}
}
.container-grid{
    width: 100%;
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
}
</style>
<style src="./PostScriptBlog.scss" lang="scss" scoped /> 