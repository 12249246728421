<template>
  <div>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
export default {
  name: "CCUpdate",
  components: { SnackbarComponent },
  data: () => ({
    snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0
    }
  }),
  computed: {
    userAuth: function() {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function() {
      return this.$store.state.guestInfo.guestQuoteId;
    },
      canonical: function() {
      return this.$route.meta.canonical;
    }, 
  },
    metaInfo() {
        return {
            link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ] 
        }
  },
  mounted() {
    if (this.$route.params.mode == "4") {
      if (
        this.$route.params.ccid &&
        this.$route.params.quantity &&
        this.$route.params.itemNumber
      ) {
        if (this.userAuth) {
          this.$store
            .dispatch("userCartProduct/updateToCart", {
              sku: this.$route.params.itemNumber,
              qty: this.$route.params.quantity,
              ccid: this.$route.params.ccid,
              itemId: this.$route.params.itemId
            })
            .then(() => {
              this.snackbar = {
                message: "Product Updated Successfully",
                color: "success",
                show: true,
                timeout: 3000
              };
              setTimeout(() => {
                this.$router.push({
                  name: "CartProducts"
                });
              }, 5000);
            })
            .catch(error => {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 3000
              };
              setTimeout(() => {
                this.$router.push({
                  name: "CartProducts"
                });
              }, 5000);
            });
        } else {
          this.$store
            .dispatch("cartProduct/updateToCart", {
              sku: this.$route.params.itemNumber,
              qty: this.$route.params.quantity,
              ccid: this.$route.params.ccid,
              itemId: this.$route.params.itemId
            })
            .then(() => {
              this.snackbar = {
                message: "Product Updated Successfully",
                color: "success",
                show: true,
                timeout: 3000
              };
              setTimeout(() => {
                this.$router.push({
                  name: "CartProducts"
                });
              }, 5000);
            })
            .catch(error => {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 3000
              };
              setTimeout(() => {
                this.$router.push({
                  name: "CartProducts"
                });
              }, 5000);
            });
        }
      } else {
        this.snackbar = {
          message: "Required parameters are missing",
          color: "error",
          show: true,
          timeout: 2000
        };
        setTimeout(() => {
          this.$router.push({
            name: "CartProducts"
          });
        }, 2000);
      }
    } else {
      this.snackbar = {
        message: "This feature is currently unavailable",
        color: "error",
        show: true,
        timeout: 2000
      };
      setTimeout(() => {
        this.$router.push({
          name: "CartProducts"
        });
      }, 2000);
    }
  },
  created() {}
};
</script>
