<template>
  <div class="white d-flex flex-column page-height">
    <v-container class="mt-12 mb-auto">
      <h1 class="my-6 font-weight-medium">You are signed out</h1>
      <h3 class="font-weight-light">
        You have signed out and will go to our homepage in
        {{ countDown }} seconds.
      </h3>
    </v-container>
    <FooterComponent />
  </div>
</template>
<script>
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
export default {
  name: "LogOutSuccess",
  data() {
    return {
      countDown: 2,
    };
  },
  components: {
    FooterComponent,
  },
  watch: {},
  computed: {},
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
  },
  created() {
    this.countDownTimer();
    setTimeout(() => {
      this.$store.dispatch("auth/logout");
      try {
        window.DD_RUM.onReady(function () {
          window.DD_RUM.clearUser();
        });
      } catch (error) {
        console.error("error in dd user clear", error);
      }

      this.$router.push({
        name: "HomePage",
      });
    }, 2000);
  },
};
</script>
<style src="./careers.scss" lang="scss" scopped/>