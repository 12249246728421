<template>
  <div class="height-100"></div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
const zinreloRewardsShow = import.meta.env.VITE_ZINRELO_REWARDS_SHOW;
export default {
  name: "ZinreloRewards",
  components: {},
  data: () => ({}),
  computed: {
    canonical: function () {
      return this.$route.meta.canonical;
    },
    show: function () {
      return this.$route.params.show;
    },
  },
  metaInfo() {
    return {
      link: [
        {
          rel: "canonical",
          href: `${
            this.canonical ? window.location.href.split("?")[0] : baseUrl
          }`,
        },
      ],
    };
  },

  created() {
    if (this.show) {
      if (this.show.toLowerCase() == "on")
        localStorage.setItem(zinreloRewardsShow, "on");
      else localStorage.setItem(zinreloRewardsShow, "off");
    } else localStorage.removeItem(zinreloRewardsShow);

    window.location.href= "/";
  },
};
</script>