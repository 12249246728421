<template>
  <div>
  
    <div v-if="pageContent.length">
      <div v-html="getPageContent()"></div>
    </div>
    <PostScriptBlog />
    <FooterComponent /> 
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import PostScriptBlog from "@/components/crane/home/PostScriptBlog.vue";
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
import imageData from '@/assets/c4aa164d9b01387a88228c67620f7faf861b5544.png'
import { goToCategoryPage, addPictureTagInHtmlCode } from "@/services/util.service";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;
export default {
  name: "OurPapers",
  components: {
    PostScriptBlog,
    FooterComponent
  },
  computed: {
     pageContent: function() {
      return this.$store.state.layout.pageContent;
    },
    etiquetteGuideContent: function() {
      return this.$store.state.block.one;
    },
    canonical: function() {
      return this.$route.meta.canonical;
    }
  },
  data: () => ({
    image: imageData,
    blockId: "175",
    variants: [1366,768,360],
    mediaImageUrl
  }),
  metaInfo() {
        return {
             link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ] 
        }
  },
  methods: {
     getPageContent() {
      if (this.pageContent.length) {
        let data = this.pageContent.filter(element => {
          return element.identifier === "our-papers-page";
        });
        if (data.length > 0) {
          let path = data[0].content;
          var div = document.createElement("div");
          div.innerHTML = path;
          let imgSrcUrls = div.getElementsByTagName("img");
          for (let i = 0; i < imgSrcUrls.length; i++) {
            imgSrcUrls[i].parentElement.innerHTML = addPictureTagInHtmlCode({currentImageTag: imgSrcUrls[i], variants: this.variants, mediaImageUrl })
          }
          return div.innerHTML;
        }
      }
    },
      imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PATH_URL;
    },
     routeToPage(pageName) {
  
       if (pageName == "CategoryPage") {
        let vm = this;
        goToCategoryPage(vm, "stationery", null, null, 10);
      }
    },
     initialLink() {
      return import.meta.env.VITE_HOME_PAGE_URL;
    },
    goToPage(pageLink) {
      window.location.replace(this.initialLink()+pageLink);
     
    },
  },
 
};
</script>
<style src="./our_papers.scss" lang="scss"/> 