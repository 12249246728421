<template>
  <div v-resize="resize">
    <iframe
      :src="url"
      :height="frameHeight"
      :width="isDesktop ? frameWidth : frameWidthDesktop"
    ></iframe>
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
const craneConnectionUrl = import.meta.env.VITE_CC_CONNECTION_URL;
export default {
  name: "CraneConnection",
  components: {},
  data: () => ({
    queryString: "",
    url: "",
    frameWidth: "",
    frameHeight: "",
    frameWidthDesktop: "",
  }),
  computed: {
    isDesktop: function () {
      return this.$vuetify.display.smAndDown ? true : false;
    },
    canonical: function () {
      return this.$route.meta.canonical;
    },
  },
  metaInfo() {
    return {
      link: [
        {
          rel: "canonical",
          href: `${
            this.canonical ? window.location.href.split("?")[0] : baseUrl
          }`,
        },
      ],
    };
  },
  methods: {
    resize() {
      this.iframeWidthHeight();
    },
    iframeWidthHeight() {
      this.frameWidth = window.innerWidth;
      this.frameWidthDesktop = window.innerWidth - 20;
      this.frameHeight = window.innerHeight - 60;
    },
  },
  created() {
    if (this.$route.params.ccid) {
      this.queryString =
        "RetailerID=" +
        this.$route.params.retailorId +
        "&CCID=" +
        this.$route.params.ccid +
        "&Quantity=" +
        this.$route.params.quantity +
        "&sav_itemId=" +
        this.$route.params.savItemId +
        "&sav_itemNumber=" +
        this.$route.params.itemNumber;
    } else {
      this.queryString =
        "RetailerID=" +
        this.$route.params.retailorId +
        "&ItemNumber=" +
        this.$route.params.itemNumber +
        "&Quantity=" +
        this.$route.params.quantity;
    }
    this.url = craneConnectionUrl + this.queryString;
  },
};
</script>
<style src="./crane_connection.scss" lang="scss" scoped />