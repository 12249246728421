<template>
  <div>
   <div class="container-grid py-10 pl-7">
    <div v-html="getReturnPolicyContent()"></div>
   </div>
    <FooterComponent/>
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import FooterComponent from "@/components/layout/footer/FooterComponent.vue"; 
export default {
  name: "ReturnPolicy",
  
  components: {
    FooterComponent
  },
  watch: {},
  computed: {
    canonical: function() {
      return this.$route.meta.canonical;
    },
     returnPolicyContent: function() {
      return this.$store.state.layout.cmsBlockContent;
    }
  },
   metaInfo() {
        return {
             link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ] 
        }
  },
  methods: {
    getReturnPolicyContent(){
      if (this.returnPolicyContent.length) {
        let data = this.returnPolicyContent.filter(element => {
          return element.identifier === "return-policy";
        });
         if(data.length > 0){
        let path = data[0].content;
        var div = document.createElement("div");
        div.innerHTML = path;
        let imgSrcUrls = div.getElementsByTagName("img");
        for (let i = 0; i < imgSrcUrls.length; i++) {
          imgSrcUrls[i].parentElement.innerHTML = addPictureTagInHtmlCode({currentImageTag: imgSrcUrls[i], variants: this.variants, mediaImageUrl })
        }
        return div.innerHTML;
      }
      }
    },
  },
 
};
</script>
<style>
@media (min-width: 1264px)
{
  .container-grid {
    max-width: 1185px;
}
}
.container-grid{
    width: 100%;
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
}
</style>