<template>
  <div class="white h-100">
    <v-overlay
      v-model="paymentInProgress"
      :opacity="0.8"
      contained
      class="align-center justify-center"
      ref="divOne"
    >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <div class="h3 text-white mt-10">
              Do not refresh or click back. Your payment is currently being
              processed.
            </div>
          </v-col>
          <v-col cols="12">
            <v-progress-circular
              indeterminate
              size="80"
              color="white"
              align="center"
              justify="center"
              class="mx-auto"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
    <v-overlay
      v-model="storeCreditInProgress"
      :opacity="0.8"
      contained
      class="align-center justify-center"
      ref="divOne"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-progress-circular
              indeterminate
              size="80"
              color="white"
              align="center"
              justify="center"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>

    <v-container
      v-if="cartProductsProgress || shippingInformationsInProgress"
      class="text-center"
      ref="divOne"
    >
      <v-progress-circular
        class="mt-12"
        :size="80"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <v-container v-else ref="divOne">
      <v-row
        align="center"
        justify="center"
        class="py-5"
        no-gutters
        v-if="cartProductsItem.length && (guestQuoteId || userAuth)"
      >
        <v-col cols="12" md="7">
          <v-tabs hide-slider fixed-tabs v-model="tabs" :height="35">
            <v-tab
              class="productpage-tab-slider myShipping text-uppercase fontsize-14 regular-font ls-n008 px-0 rounded-0"
              value="shippingTab"
              v-show="showShippingTab"
              @click="changedToShippTab()"
              >Shipping</v-tab
            >
            <v-tab
              class="productpage-tab-slider text-uppercase fontsize-14 regular-font ls-n008 px-0 rounded-0"
              value="reviewTab"
              :disabled="!valid"
              :class="giftCardProduct ? 'checkout-tabs' : ''"
              >Review & Payments</v-tab
            >
          </v-tabs>
        </v-col>
        <v-col cols="12">
          <v-window touchless v-model="tabs">
            <v-window-item value="shippingTab" :disabled="valid">
              <v-row
                v-if="isMobileDevice && estimatedShippingMethods.length > 0"
                class="pt-3"
              >
                <v-col cols="12">
                  <v-alert text>
                    <p>
                      <strong
                        >Estimated Total : ${{
                          priceFormatter(
                            estimatedShipping.amount +
                              cartProductTotalAmt.grandTotal
                          )
                        }}</strong
                      >
                    </p>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-3 pt-sm-6">
                <v-col cols="12" sm="7" md="8" class="pr-sm-3">
                  <!-- <div
                    class="g-recaptcha"
                    data-sitekey="6LeBULEqAAAAAHL76utRZsj-Ki8hZWkX1P4ZeC5i"
                    data-callback="successCall"
                  ></div> -->
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="8"
                      class="pt-sm-6"
                      v-if="!this.userAuth"
                    >
                      <p class="h3 mt-4">
                        <span class="text-primary">Checkout Method</span>
                      </p>
                      <GuestShippingAddressForm
                        @actionValid="checkGuestFormValid"
                        :hideEmail="hideEmailField"
                        @verifyAddress="verifyAddress"
                        ref="guestAddressForm"
                      />
                    </v-col>

                    <v-col cols="12" class="pt-sm-6" v-else>
                      <p class="h3 mb-2">
                        <span class="text-primary">Checkout Method</span>
                      </p>
                      <v-divider color="primary"></v-divider>
                      <div v-if="basicInfo.addresses">
                        <div v-if="basicInfo.addresses.length > 0">
                          <v-row class="mt-2 mx-1">
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                              class="my-2 py-0 h-100"
                              v-for="(address, index) in addressesList"
                              :key="index"
                              :class="
                                userSelectedAddressIndex == index && index == 0
                                  ? 'primary-all-side-border'
                                  : userSelectedAddressIndex == index &&
                                    index > 0 &&
                                    !isMobileDevice
                                  ? 'primary-tbr-side-border'
                                  : isMobileDevice &&
                                    userSelectedAddressIndex == index
                                  ? 'primary-all-side-border'
                                  : isMobileDevice
                                  ? ''
                                  : 'primary-right-side-border'
                              "
                            >
                              <v-container fluid fill-height class="pa-0">
                                <v-col
                                  cols="10"
                                  sm="12"
                                  class="pa-0 mb-auto align-self-start"
                                >
                                  <BillingAddressComponent
                                    :address="address"
                                    class="pa-3"
                                  />
                                  <v-btn
                                    class="ma-3 d-none d-sm-inline-block"
                                    variant="flat"
                                    color="primary"
                                    rounded="0"
                                    v-if="lastAddedAddressBookIndex == index"
                                    @click="editLocalAddress(address, true)"
                                    >Edit</v-btn
                                  >
                                </v-col>
                                <v-col
                                  cols="2"
                                  class="pa-0 d-sm-none text-center align-self-start h-100"
                                >
                                  <v-btn
                                    icon
                                    depressed
                                    v-if="lastAddedAddressBookIndex == index"
                                    @click="editLocalAddress(address, true)"
                                  >
                                    <v-icon :icon="mdiPencil" />
                                  </v-btn>
                                </v-col>
                                <v-col class="align-self-end pa-0">
                                  <v-btn
                                    v-if="userSelectedAddressIndex != index"
                                    class="primary mx-sm-3 float-right"
                                    :block="isMobileDevice"
                                    @click="shipHereAddress(address, index)"
                                    variant="flat"
                                    rounded="0"
                                    color="primary"
                                    >Ship Here</v-btn
                                  >
                                </v-col>
                              </v-container>
                            </v-col>

                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                              class="py-0 h-100 mt-2"
                              v-if="userLocalAddress"
                              :class="
                                userSelectedAddressIndex != -1
                                  ? 'primary-right-side-border'
                                  : 'primary-all-side-border pa-0'
                              "
                            >
                              <v-container fluid fill-height class="pa-0">
                                <v-col cols="10" sm="12" class="pa-0">
                                  <BillingAddressComponent
                                    :address="userLocalAddress"
                                    class="pa-3 mb-auto"
                                  />
                                  <v-btn
                                    class="ma-3 d-none d-sm-inline-block"
                                    size="large"
                                    variant="flat"
                                    color="primary"
                                    rounded="0"
                                    @click="
                                      editLocalAddress(userLocalAddress, false)
                                    "
                                    >Edit</v-btn
                                  >
                                </v-col>
                                <v-col
                                  cols="2"
                                  class="pa-0 d-sm-none text-center h-100"
                                >
                                  <v-btn
                                    icon
                                    depressed
                                    @click="
                                      editLocalAddress(userLocalAddress, false)
                                    "
                                  >
                                    <v-icon :icon="mdiPencil" />
                                  </v-btn>
                                </v-col>
                                <v-col class="pa-0">
                                  <v-btn
                                    v-if="userSelectedAddressIndex != -1"
                                    class="primary mx-sm-3 float-right"
                                    :block="isMobileDevice"
                                    tile
                                    depressed
                                    @click="
                                      shipHereAddress(userLocalAddress, -1)
                                    "
                                    variant="flat"
                                    rounded="0"
                                    color="primary"
                                    >Ship Here</v-btn
                                  >
                                </v-col>
                              </v-container>
                            </v-col>
                          </v-row>
                          <v-row v-if="!userLocalAddressFlag">
                            <v-col cols="auto" class="mt-3">
                              <v-btn
                                color="primary"
                                @click="openDialogForEditAddress()"
                                variant="flat"
                                rounded="0"
                                size="default"
                                >New Address</v-btn
                              >
                            </v-col>
                          </v-row>
                        </div>
                        <div v-else>
                          <v-row>
                            <v-col cols="12" sm="8">
                              <p class="h3 mt-1">Shipping Address</p>
                              <ShippingAddressFormComponent
                                @actionValid="checkFormValid"
                                :hideAndShowFields="hideAndShowFields"
                                @verifyAddress="verifyAddress"
                                ref="userAddressForm"
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                      <div v-else class="text-center pt-3">
                        <v-progress-circular
                          :size="40"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mt-3" v-if="!addressContainPOBox">
                      <div>
                        <p class="h3 mb-1">
                          <span class="text-primary">Verify Your Address</span>
                        </p>
                        <v-col class="pa-0" v-if="geoCodedAddress">
                          <VerifyAddressComponent
                            :geoCodedAddress="geoCodedAddress"
                            :geoCodedAddressFlag="geoCodedAddressFlag"
                            :validAddresses="validAddresses"
                          />
                        </v-col>
                        <v-col
                          class="pa-0 text-center"
                          v-else-if="addressInProgress"
                        >
                          <v-progress-circular
                            :size="40"
                            color="primary"
                            indeterminate
                          ></v-progress-circular>
                        </v-col>
                        <v-col class="pa-0" v-else>
                          <v-alert
                            :icon="mdiAlert"
                            variant="tonal"
                            type="error"
                            class="my-2"
                            text
                            >No Address is available</v-alert
                          >
                        </v-col>
                        <p
                          class="body pb-3 text-red"
                          v-if="
                            !addressInProgress &&
                            address &&
                            address.countryId == 'CA'
                          "
                        >
                          Please note that our website does not collect duties
                          and taxes on international orders. These charges are
                          the responsibility of the recipient and are typically
                          due upon delivery. The amount of duties and taxes
                          charged will vary depending on the country of
                          destination and the value of your order.
                        </p>
                        <p class="h3 my-2">
                          <span class="text-primary">Shipping Methods</span>
                        </p>
                        <v-row no-gutters>
                          <v-col
                            cols="12"
                            v-if="inProgress"
                            class="text-center"
                          >
                            <v-progress-circular
                              :size="40"
                              color="primary"
                              indeterminate
                            ></v-progress-circular>
                          </v-col>
                          <v-col
                            cols="12"
                            v-else-if="estimatedShippingMethods.length > 0"
                            class="estimated-shipping-class"
                          >
                            <v-radio-group
                              mandatory
                              v-model="estimatedShipping"
                            >
                              <div
                                v-for="(
                                  method, index
                                ) in estimatedShippingMethods"
                                :key="index"
                              >
                                <v-row no-gutters class="mt-0">
                                  <v-col cols="4" sm="4">
                                    <v-radio
                                      :label="method.carrierTitle"
                                      :value="method"
                                      color="primary"
                                    ></v-radio>
                                  </v-col>
                                  <v-col cols="4" sm="6">{{
                                    method.methodTitle
                                  }}</v-col>
                                  <v-col cols="4" sm="2" class="text-right"
                                    >${{ priceFormatter(method.amount) }}</v-col
                                  >
                                </v-row>
                              </div>
                            </v-radio-group>
                          </v-col>
                          <v-col cols="12" v-else>
                            <v-alert
                              :icon="mdiAlert"
                              variant="tonal"
                              type="error"
                              class="my-2"
                              text
                              >No shipping methods is available</v-alert
                            >
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col
                            cols="12"
                            class="mt-4 d-flex justify-center flex-column align-center"
                            v-show="!showNext"
                          >
                            <h3 v-if="!showNext" class="mb-4">
                              Please click on the checkbox below.
                            </h3>
                            <div id="crane-recaptcha"></div>
                          </v-col>
                          <v-col
                            cols="12"
                            class="d-flex justify-center justify-sm-end"
                            v-if="showNext"
                          >
                            <v-btn
                              class="text-uppercase mt-1"
                              color="primary"
                              href="javascript:void(0);"
                              @click="next()"
                              size="large"
                              rounded="0"
                              >NEXT</v-btn
                            >
                            <!-- <form @submit.prevent="onSubmit">
                              <button type="submit">Checkout</button>
                            </form> -->
                          </v-col>
                        </v-row>
                        <!-- test <div id="recaptcha-container"></div> -->
                      </div>
                    </v-col>
                    <v-col cols="12" class="pt-3" v-else>
                      <v-alert type="error" class="my-2" text
                        >Shipping to PO Boxes is not permitted. Please use a
                        different shipping address</v-alert
                      >
                    </v-col>
                  </v-row>
                  <div class="myDivider"></div>
                </v-col>
                <v-col
                  cols="12"
                  sm="5"
                  md="4"
                  class="d-none d-sm-block pl-3 pt-6"
                >
                  <p class="h3 mb-2">
                    <span class="text-primary">Order Summary</span>
                  </p>

                  <v-divider class="bg-primary mb-5"></v-divider>
                  <v-col
                    align="center"
                    justify="center"
                    v-if="cartProductsProgress"
                  >
                    <v-progress-circular
                      :size="60"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </v-col>
                  <v-expansion-panels
                    v-model="cartItemPanel"
                    class="checkpout-expansion-panel"
                    v-else
                  >
                    <v-expansion-panel elevation="0">
                      <v-expansion-panel-title class="pr-4"
                        >{{ cartProductsItem.length }} Items In
                        cart</v-expansion-panel-title
                      >
                      <v-expansion-panel-text class="checkout-expansion">
                        <div class="checkout-expansion-maxheight">
                          <CartProductList :checkOut="chekoutFlag" />
                        </div>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <v-row v-if="rewardsPoints" no-gutters>
                    <v-divider color="primary"></v-divider>
                    <v-col cols="12" class="my-2">
                      You will earn
                      <span class="text-primary text-underline"
                        >{{ rewardsPoints }}
                      </span>
                      Reward Points with this Purchase.
                    </v-col>
                    <v-divider color="primary"></v-divider>
                  </v-row>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item value="reviewTab">
              <v-row
                no-gutters
                class="pt-sm-6"
                v-if="shippingInformations || giftCardProduct"
              >
                <v-col cols="12" sm="7" md="8" class="px-3 pr-sm-3">
                  <v-row>
                    <v-col cols="12" v-if="shippingInformations" class="mt-4">
                      <div v-if="shippingInformations.totals.grandTotal !== 0">
                        <v-radio-group
                          :mandatory="false"
                          v-model="paymentMethod"
                        >
                          <v-radio
                            :label="authnetcimPayment.title"
                            :value="authnetcimPayment.code"
                            class="text-black"
                            color="primary"
                          ></v-radio>
                        </v-radio-group>
                        <div v-if="paymentMethod == 'authnetcim'" class="mb-3">
                          <v-checkbox
                            class="mt-0 pt-0"
                            label="My billing and shipping address are the same"
                            v-model="sameAddress"
                            @change="resetFields()"
                            dense
                            :readonly="readOnlyCheckBox"
                            hide-details
                            color="primary"
                          ></v-checkbox>
                          <v-row v-if="sameAddress">
                            <v-col cols="12" class="pl-sm-10">
                              <BillingAddressComponent :address="address" />
                              <v-btn
                                class="primary my-3"
                                size="large"
                                variant="flat"
                                color="primary"
                                rounded="0"
                                v-if="editBillingButton"
                                @click="editBillBook()"
                                >Edit</v-btn
                              >
                            </v-col>
                          </v-row>
                          <v-row v-else>
                            <v-col cols="12" sm="8" v-if="this.userAuth">
                              <v-row v-if="addressLength > 0">
                                <v-col cols="12">
                                  <v-select
                                    v-model="selectedBillingAddress"
                                    density="comfortable"
                                    :items="billingAddressList"
                                    variant="outlined"
                                    return-object
                                    class="billing-address-select"
                                    @update:modelValue="changeBillingAddress()"
                                    item-value="id"
                                    item-title="id"
                                  >
                                    <template v-slot:selection="{ item }">
                                      <div v-if="item.raw.value">
                                        <span class>New Address</span>
                                      </div>
                                      <div
                                        class="d-inline-block text-truncate pt-sm-0"
                                        v-else
                                      >
                                        <span class
                                          >{{ item.raw.firstname }}
                                          {{ item.raw.lastname }}</span
                                        >
                                        <span
                                          class="body pl-1"
                                          v-if="item.raw.streetNoOne"
                                          >{{ item.raw.streetNoOne }},
                                          {{ item.raw.streetNoTwo }}</span
                                        >
                                        <span class="body pl-1" v-else
                                          >{{
                                            item.raw.street
                                              ? item.raw.street[0]
                                              : ""
                                          }}
                                          ,{{
                                            item.raw.street
                                              ? item.raw.street[1]
                                              : ""
                                          }}</span
                                        >
                                        <span class="pl-1"
                                          >{{ item.raw.city }}
                                          {{ item.raw.postcode }}</span
                                        >
                                      </div>
                                    </template>

                                    <template v-slot:item="{ props, item }">
                                      <v-list-item-content v-bind="props">
                                        <v-list-item-title
                                          class="myItems1"
                                          v-if="item.raw.value"
                                        >
                                          <span class="ml-2">New Address</span>
                                        </v-list-item-title>
                                        <v-list-item-title
                                          class="myItems"
                                          v-else
                                        >
                                          <span class="ml-2"
                                            >{{ item.raw.firstname }}
                                            {{ item.raw.lastname }},</span
                                          >
                                          <span
                                            class="body"
                                            v-if="item.raw.streetNoOne"
                                            >{{ item.raw.streetNoOne }},
                                            {{ item.raw.streetNoTwo }}</span
                                          >
                                          <span class="body" v-else
                                            >{{
                                              item.raw.street
                                                ? item.raw.street[0]
                                                : ""
                                            }}
                                            ,{{
                                              item.raw.street
                                                ? item.raw.street[1]
                                                : ""
                                            }}</span
                                          >
                                          <span class
                                            >{{ item.raw.city }},
                                            {{ item.raw.postcode }}</span
                                          >
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </v-col>
                                <v-col
                                  cols="12"
                                  class="d-flex justify-end pa-0"
                                  v-if="!showNewBillingForm"
                                >
                                  <v-btn
                                    class="bg-primary ma-3 text-uppercase"
                                    @click="cancelBillingAddress"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    class="bg-success ma-3 text-uppercase"
                                    @click="updateBillingAddress"
                                    >Update</v-btn
                                  >
                                </v-col>
                              </v-row>
                              <v-row v-if="showNewBillingForm">
                                <v-col cols="12">
                                  <ShippingAddressFormComponent
                                    @actionValid="checkFormValid"
                                    :hideAndShowFields="hideAndShowFields"
                                    @verifyAddress="verifyAddress"
                                    ref="billingForm"
                                  />
                                </v-col>
                                <v-col cols="12" class="py-0">
                                  <v-checkbox
                                    label="Save in Address Book"
                                    v-model="saveInBillingAddressBook"
                                    class="mt-0"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" sm="8" v-else>
                              <GuestShippingAddressForm
                                @actionValid="checkGuestFormValid"
                                :hideHeader="true"
                                :hideEmail="hideEmailField"
                                @verifyAddress="verifyAddress"
                                ref="addressForm"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="8"
                              class="d-flex justify-end pa-0"
                              v-if="showNewBillingForm"
                            >
                              <v-btn
                                class="bg-primary ma-3 text-uppercase"
                                @click="cancelBillingAddress"
                                >Cancel</v-btn
                              >
                              <v-btn
                                class="bg-success ma-3 text-uppercase"
                                @click="newBillingAddress"
                                >Update</v-btn
                              >
                            </v-col>
                          </v-row>
                          <v-row>
                            <CardPayment
                              @submitCardPayment="submitCardPayment"
                            />
                          </v-row>
                        </div>
                      </div>
                      <div
                        v-else-if="shippingInformations.totals.grandTotal == 0"
                      >
                        <v-radio-group
                          :mandatory="false"
                          v-model="paymentMethodFree"
                        >
                          <p class="h3 mb-5">Payment Method</p>
                          <v-radio
                            :label="freePayment.title"
                            :value="paymentMethodFree"
                            class="black--text"
                            color="primary"
                          ></v-radio>
                        </v-radio-group>
                        <div>
                          <v-checkbox
                            label="My billing and shipping address are the same"
                            v-model="sameAddress"
                            @change="resetFields()"
                            dense
                            :readonly="readOnlyCheckBox"
                            hide-details
                          ></v-checkbox>
                          <v-row v-if="sameAddress">
                            <v-col cols="12" class="pl-sm-10">
                              <BillingAddressComponent :address="address" />
                              <v-btn
                                class="my-3"
                                size="large"
                                variant="flat"
                                color="primary"
                                rounded="0"
                                v-if="editBillingButton"
                                @click="editBillBook()"
                                >Edit</v-btn
                              >
                            </v-col>
                          </v-row>
                          <v-row v-else>
                            <v-col cols="12" sm="8" v-if="this.userAuth">
                              <v-row v-if="addressLength > 0">
                                <v-col cols="12">
                                  <v-select
                                    v-model="selectedBillingAddress"
                                    dense
                                    :items="billingAddressList"
                                    outlined
                                    return-object
                                    class="billing-address-select"
                                    @update:modelValue="changeBillingAddress()"
                                    item-value="id"
                                    item-title="id"
                                  >
                                    <template v-slot:selection="{ item }">
                                      <div v-if="item.raw.value">
                                        <span class>New Address</span>
                                      </div>
                                      <div
                                        class="d-inline-block text-truncate pt-sm-0"
                                        v-else
                                      >
                                        <span class
                                          >{{ item.raw.firstname }}
                                          {{ item.raw.lastname }}</span
                                        >
                                        <span
                                          class="body pl-1"
                                          v-if="item.raw.streetNoOne"
                                          >{{ item.raw.streetNoOne }},
                                          {{ item.raw.streetNoTwo }}</span
                                        >
                                        <span class="body pl-1" v-else
                                          >{{
                                            item.raw.street
                                              ? item.raw.street[0]
                                              : ""
                                          }}
                                          ,{{
                                            item.raw.street
                                              ? item.raw.street[1]
                                              : ""
                                          }}</span
                                        >
                                        <span class="pl-1"
                                          >{{ item.raw.city }}
                                          {{ item.raw.postcode }}</span
                                        >
                                      </div>
                                    </template>

                                    <template v-slot:item="{ props, item }">
                                      <v-list-item-content v-bind="props">
                                        <v-list-item-title
                                          v-if="item.raw.value"
                                        >
                                          <span class="ml-2">New Address</span>
                                        </v-list-item-title>
                                        <v-list-item-title v-else>
                                          <span class="ml-2"
                                            >{{ item.raw.firstname }}
                                            {{ item.raw.lastname }},</span
                                          >
                                          <span
                                            class="body"
                                            v-if="item.raw.streetNoOne"
                                            >{{ item.raw.streetNoOne }},
                                            {{ item.raw.streetNoTwo }}</span
                                          >
                                          <span class="body" v-else
                                            >{{
                                              item.raw.street
                                                ? item.raw.street[0]
                                                : ""
                                            }}
                                            ,{{
                                              item.raw.street
                                                ? item.raw.street[1]
                                                : ""
                                            }}</span
                                          >
                                          <span class
                                            >{{ item.raw.city }},
                                            {{ item.raw.postcode }}</span
                                          >
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </v-col>
                                <v-col
                                  cols="12"
                                  class="d-flex justify-end pa-0"
                                  v-if="!showNewBillingForm"
                                >
                                  <v-btn
                                    class="bg-primary ma-3 text-uppercase"
                                    @click="cancelBillingAddress"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    class="bg-success ma-3 text-uppercase"
                                    @click="updateBillingAddress"
                                    >Update</v-btn
                                  >
                                </v-col>
                              </v-row>
                              <v-row v-if="showNewBillingForm">
                                <v-col cols="12">
                                  <ShippingAddressFormComponent
                                    @actionValid="checkFormValid"
                                    :hideAndShowFields="hideAndShowFields"
                                    @verifyAddress="verifyAddress"
                                    ref="billingForm"
                                  />
                                </v-col>
                                <v-col cols="12" class="py-0">
                                  <v-checkbox
                                    label="Save in Address Book"
                                    v-model="saveInBillingAddressBook"
                                    class="mt-0"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" sm="8" v-else>
                              <GuestShippingAddressForm
                                @actionValid="checkGuestFormValid"
                                :hideEmail="hideEmailField"
                                @verifyAddress="verifyAddress"
                                ref="addressForm"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="8"
                              class="d-flex justify-end pa-0"
                              v-if="showNewBillingForm"
                            >
                              <v-btn
                                class="bg-primary ma-3 text-uppercase"
                                @click="cancelBillingAddress"
                                >Cancel</v-btn
                              >
                              <v-btn
                                class="bg-success ma-3 text-uppercase"
                                @click="newBillingAddress"
                                >Update</v-btn
                              >
                            </v-col>
                          </v-row>
                          <v-row class="pl-3">
                            <v-col
                              cols="12"
                              v-if="
                                giftCardCreditBalance >
                                shippingInformations.totals.subtotal +
                                  shippingInformations.totals
                                    .baseShippingInclTax
                              "
                            >
                              <p>
                                Gift Card amount applied to order:${{
                                  shippingInformations.totals.subtotal +
                                  shippingInformations.totals
                                    .baseShippingInclTax
                                }}
                              </p>
                            </v-col>
                            <v-col cols="12" align="right">
                              <v-btn
                                class="bg-primary mx-3 text-uppercase"
                                @click="placeOrderFree()"
                                tile
                                depressed
                                >Place Order</v-btn
                              >
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                      <v-divider></v-divider>
                    </v-col>

                    <v-col cols="12" v-else>
                      <v-col cols="8" class="pl-1" v-if="!userAuth">
                        <v-form ref="emailForm" v-model="validEmail">
                          <v-col class="px-0 py-0 mb-n3">
                            <v-text-field
                              label="Email Address"
                              @input="checkEmail()"
                              @change="sendEmailId()"
                              ref="email"
                              name="email"
                              v-model="emailId"
                              :rules="emailRules"
                              variant="outlined"
                              density="compact"
                            ></v-text-field>
                          </v-col>

                          <span v-show="!passwordFields"
                            >You can create an account after checkout.</span
                          >
                        </v-form>
                      </v-col>
                      <div
                        v-if="
                          giftCardProduct &&
                          cartProductTotalAmt.grandTotal !== 0
                        "
                      >
                        <v-radio-group
                          :mandatory="false"
                          v-model="paymentMethod"
                        >
                          <p class="h3 mb-5">Payment Method</p>
                          <v-radio
                            :label="giftCardProductTitle"
                            :value="giftCardProductCode"
                            class="black--text"
                            color="primary"
                          ></v-radio>
                        </v-radio-group>
                        <div v-if="paymentMethod === 'authnetcim'">
                          <v-row>
                            <v-col cols="12" sm="8" v-if="this.userAuth">
                              <v-row v-if="addressLength > 0">
                                <v-col cols="12">
                                  <v-select
                                    v-model="selectedBillingAddress"
                                    dense
                                    :items="billingAddressList"
                                    outlined
                                    return-object
                                    class="billing-address-select"
                                    @update:modelValue="changeBillingAddress()"
                                    item-value="id"
                                    item-title="id"
                                  >
                                    <template v-slot:selection="{ item }">
                                      <div v-if="item.raw.value">
                                        <span class>New Address</span>
                                      </div>
                                      <div
                                        class="d-inline-block text-truncate pt-sm-0"
                                        v-else
                                      >
                                        <span class
                                          >{{ item.raw.firstname }}
                                          {{ item.raw.lastname }}</span
                                        >
                                        <span
                                          class="body pl-1"
                                          v-if="item.raw.streetNoOne"
                                          >{{ item.raw.streetNoOne }},
                                          {{ item.raw.streetNoTwo }}</span
                                        >
                                        <span class="body pl-1" v-else
                                          >{{
                                            item.raw.street
                                              ? item.raw.street[0]
                                              : ""
                                          }}
                                          ,{{
                                            item.raw.street
                                              ? item.raw.street[1]
                                              : ""
                                          }}</span
                                        >
                                        <span class="pl-1"
                                          >{{ item.raw.city }}
                                          {{ item.raw.postcode }}</span
                                        >
                                      </div>
                                    </template>

                                    <template v-slot:item="{ props, item }">
                                      <v-list-item-content v-bind="props">
                                        <v-list-item-title
                                          v-if="item.raw.value"
                                        >
                                          <span class="ml-2">New Address</span>
                                        </v-list-item-title>
                                        <v-list-item-title v-else>
                                          <span class="ml-2"
                                            >{{ item.raw.firstname }}
                                            {{ item.raw.lastname }},</span
                                          >
                                          <span
                                            class="body"
                                            v-if="item.raw.streetNoOne"
                                            >{{ item.raw.streetNoOne }},
                                            {{ item.raw.streetNoTwo }}</span
                                          >
                                          <span class="body" v-else
                                            >{{
                                              item.raw.street
                                                ? item.raw.street[0]
                                                : ""
                                            }}
                                            ,{{
                                              item.raw.street
                                                ? item.raw.street[1]
                                                : ""
                                            }}</span
                                          >
                                          <span class
                                            >{{ item.raw.city }},
                                            {{ item.raw.postcode }}</span
                                          >
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </v-col>
                                <v-col
                                  cols="12"
                                  class="d-flex justify-end pa-0"
                                  v-if="!showNewBillingForm"
                                >
                                  <v-btn
                                    class="bg-primary ma-3 text-uppercase"
                                    @click="cancelBillingAddress"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    class="bg-success ma-3 text-uppercase"
                                    @click="updateBillingAddress"
                                    >Update</v-btn
                                  >
                                </v-col>
                              </v-row>
                              <v-row v-else-if="showNewBillingForm">
                                <v-col cols="12">
                                  <ShippingAddressFormComponent
                                    @actionValid="checkFormValid"
                                    :hideAndShowFields="hideAndShowFields"
                                    @verifyAddress="verifyAddress"
                                    ref="billingForm"
                                  />
                                </v-col>
                                <v-col cols="12" class="py-0">
                                  <v-checkbox
                                    label="Save in Address Book"
                                    v-model="saveInBillingAddressBook"
                                    class="mt-0"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" sm="8" v-else>
                              <GuestShippingAddressForm
                                @actionValid="checkGuestFormValid"
                                :hideHeader="true"
                                :hideEmail="hideEmailFieldGiftCardProduct"
                                @verifyAddress="verifyAddress"
                                ref="addressFormGuest"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="8"
                              class="d-flex justify-end pa-0"
                              v-if="showNewBillingForm"
                            >
                              <v-btn
                                class="bg-primary ma-3 text-uppercase"
                                @click="cancelBillingAddress"
                                >Cancel</v-btn
                              >
                              <v-btn
                                class="bg-success ma-3 text-uppercase"
                                @click="newBillingAddress"
                                >Update</v-btn
                              >
                            </v-col>
                          </v-row>
                          <v-row>
                            <CardPayment
                              @submitCardPayment="submitCardPayment"
                            />
                          </v-row>
                        </div>
                      </div>
                      <div
                        v-else-if="
                          giftCardProduct && cartProductTotalAmt.grandTotal == 0
                        "
                      >
                        <v-radio-group
                          :mandatory="false"
                          v-model="paymentMethodFree"
                        >
                          <p class="h3 mb-5">Payment Method</p>
                          <v-radio
                            :label="giftCardFreeProductTitle"
                            :value="paymentMethodFree"
                            class="text-black"
                            color="primary"
                          ></v-radio>
                        </v-radio-group>
                        <div>
                          <v-checkbox
                            label="My billing and shipping address are the same"
                            v-model="sameAddress"
                            @change="resetFields()"
                            dense
                            :readonly="readOnlyCheckBox"
                            hide-details
                          ></v-checkbox>
                          <v-row v-if="sameAddress">
                            <v-col cols="12" class="pl-sm-10">
                              <BillingAddressComponent :address="address" />
                              <v-btn
                                class="my-3"
                                size="large"
                                variant="flat"
                                color="primary"
                                rounded="0"
                                v-if="editBillingButton"
                                @click="editBillBook()"
                                >Edit</v-btn
                              >
                            </v-col>
                          </v-row>
                          <v-row v-else>
                            <v-col cols="12" sm="8" v-if="this.userAuth">
                              <v-row v-if="addressLength > 0">
                                <v-col cols="12">
                                  <v-select
                                    v-model="selectedBillingAddress"
                                    dense
                                    :items="billingAddressList"
                                    outlined
                                    return-object
                                    class="billing-address-select"
                                    @update:modelValue="changeBillingAddress()"
                                    item-value="id"
                                    item-title="id"
                                  >
                                    <template v-slot:selection="{ item }">
                                      <div v-if="item.raw.value">
                                        <span class>New Address</span>
                                      </div>
                                      <div
                                        class="d-inline-block text-truncate pt-sm-0"
                                        v-else
                                      >
                                        <span class
                                          >{{ item.raw.firstname }}
                                          {{ item.raw.lastname }}</span
                                        >
                                        <span
                                          class="body pl-1"
                                          v-if="item.raw.streetNoOne"
                                          >{{ item.raw.streetNoOne }},
                                          {{ item.raw.streetNoTwo }}</span
                                        >
                                        <span class="body pl-1" v-else
                                          >{{
                                            item.raw.street
                                              ? item.raw.street[0]
                                              : ""
                                          }}
                                          ,{{
                                            item.raw.street
                                              ? item.raw.street[1]
                                              : ""
                                          }}</span
                                        >
                                        <span class="pl-1"
                                          >{{ item.raw.city }}
                                          {{ item.raw.postcode }}</span
                                        >
                                      </div>
                                    </template>

                                    <template v-slot:item="{ props, item }">
                                      <v-list-item-content v-bind="props">
                                        <v-list-item-title
                                          v-if="item.raw.value"
                                        >
                                          <span class="ml-2">New Address</span>
                                        </v-list-item-title>
                                        <v-list-item-title v-else>
                                          <span class="ml-2"
                                            >{{ item.raw.firstname }}
                                            {{ item.raw.lastname }},</span
                                          >
                                          <span
                                            class="body"
                                            v-if="item.raw.streetNoOne"
                                            >{{ item.raw.streetNoOne }},
                                            {{ item.raw.streetNoTwo }}</span
                                          >
                                          <span class="body" v-else
                                            >{{
                                              item.raw.street
                                                ? item.raw.street[0]
                                                : ""
                                            }}
                                            ,{{
                                              item.raw.street
                                                ? item.raw.street[1]
                                                : ""
                                            }}</span
                                          >
                                          <span class
                                            >{{ item.raw.city }},
                                            {{ item.raw.postcode }}</span
                                          >
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </v-col>
                                <v-col
                                  cols="12"
                                  class="d-flex justify-end pa-0"
                                  v-if="!showNewBillingForm"
                                >
                                  <v-btn
                                    class="bg-primary ma-3 text-uppercase"
                                    @click="cancelBillingAddress"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    class="bg-success ma-3 text-uppercase"
                                    @click="updateBillingAddress"
                                    >Update</v-btn
                                  >
                                </v-col>
                              </v-row>
                              <v-row v-if="showNewBillingForm">
                                <v-col cols="12">
                                  <ShippingAddressFormComponent
                                    @actionValid="checkFormValid"
                                    :hideAndShowFields="hideAndShowFields"
                                    @verifyAddress="verifyAddress"
                                    ref="billingForm"
                                  />
                                </v-col>
                                <v-col cols="12" class="py-0">
                                  <v-checkbox
                                    label="Save in Address Book"
                                    v-model="saveInBillingAddressBook"
                                    class="mt-0"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" sm="8" v-else>
                              <GuestShippingAddressForm
                                @actionValid="checkGuestFormValid"
                                :hideEmail="hideEmailField"
                                @verifyAddress="verifyAddress"
                                ref="addressForm"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="8"
                              class="d-flex justify-end pa-0"
                              v-if="showNewBillingForm"
                            >
                              <v-btn
                                class="bg-primary ma-3 text-uppercase"
                                @click="cancelBillingAddress"
                                >Cancel</v-btn
                              >
                              <v-btn
                                class="bg-success ma-3 text-uppercase"
                                @click="newBillingAddress"
                                >Update</v-btn
                              >
                            </v-col>
                          </v-row>
                          <v-row class="pl-3">
                            <v-col
                              cols="12"
                              v-if="
                                giftCardCreditBalance >
                                cartProductTotalAmt.subtotal +
                                  cartProductTotalAmt.baseShippingInclTax
                              "
                            >
                              <p>
                                Gift Card amount applied to order:${{
                                  cartProductTotalAmt.subtotal +
                                  cartProductTotalAmt.baseShippingInclTax
                                }}
                              </p>
                            </v-col>
                            <v-col cols="12" align="right">
                              <v-btn
                                class="bg-primary mx-3 text-uppercase"
                                @click="placeOrderFree()"
                                tile
                                depressed
                                >Place Order</v-btn
                              >
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" class="py-0 my-4">
                      <v-expansion-panels
                        class="active-panel-edited1"
                        v-model="panelCode"
                        :model-value="panelCode"
                      >
                        <v-expansion-panel
                          elevation="0"
                          class="transparent-background"
                        >
                          <v-expansion-panel-title
                            color=""
                            active
                            class="px-0 pl-2 body"
                            >Apply Discount Code</v-expansion-panel-title
                          >

                          <v-expansion-panel-text>
                            <v-row class="mt-3" v-if="userAuth">
                              <v-col
                                cols="12"
                                align="center"
                                v-if="inProgressDiscountCode"
                              >
                                <v-progress-circular
                                  :size="60"
                                  color="primary"
                                  indeterminate
                                ></v-progress-circular>
                              </v-col>
                              <v-col cols="12" class="pb-0 mb-0" v-else>
                                <v-form
                                  ref="discountCodeMultipleForm"
                                  v-model="validDiscountCode"
                                  @submit.prevent="applyDiscountCodeMultiple"
                                >
                                  <v-row>
                                    <v-col cols="4" class="pb-0 mb-0">
                                      <v-text-field
                                        type="text"
                                        density="compact"
                                        placeholder="Enter Discount Code"
                                        v-model="customerDiscountTextCode"
                                        variant="outlined"
                                        class="text-black"
                                        @blur.prevent="resetCustomerValidation"
                                        :rules="requiredRules"
                                        color="primary"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="pb-0 mb-0">
                                      <v-hover v-slot="{ isHovering, props }">
                                        <v-btn
                                          class="text-uppercase fontsize-14 cart-products-button"
                                          type="submit"
                                          size="large"
                                          density="comfortable"
                                          depressed
                                          :rounded="0"
                                          :variant="
                                            isHovering ? 'outlined' : ''
                                          "
                                          v-bind="props"
                                          :class="
                                            isHovering
                                              ? 'text-primary bg-white'
                                              : 'text-white bg-primary'
                                          "
                                          >APPLY</v-btn
                                        >
                                      </v-hover>
                                    </v-col>
                                  </v-row>

                                  <v-row
                                    v-for="(
                                      item, index
                                    ) in customerDiscountCodeList"
                                    :key="index"
                                  >
                                    <v-col cols="4" class="pb-0 mb-0">
                                      <h3>{{ item }}</h3>
                                    </v-col>
                                    <v-col cols="4" class="pb-0 mb-0">
                                      <v-hover
                                        v-slot:default="{ isHovering, props }"
                                      >
                                        <v-btn
                                          class="text-uppercase checkoutpage-button-height cart-products-button"
                                          @click.prevent="
                                            removeDiscountMultipleCode(item)
                                          "
                                          size="large"
                                          density="compact"
                                          :variant="
                                            isHovering ? 'outlined' : ''
                                          "
                                          v-bind="props"
                                          :class="
                                            isHovering
                                              ? 'bgWhiteColor'
                                              : 'bgRedColor'
                                          "
                                          depressed
                                          rounded="0"
                                          >CANCEL</v-btn
                                        >
                                      </v-hover>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-col>
                            </v-row>
                            <v-row class="mt-3" v-else>
                              <v-col
                                cols="12"
                                align="center"
                                v-if="inProgressDiscountGuestCode"
                              >
                                <v-progress-circular
                                  :size="60"
                                  color="primary"
                                  indeterminate
                                ></v-progress-circular>
                              </v-col>
                              <v-col cols="12" class="pb-0 mb-0" v-else>
                                <v-form
                                  ref="discountCodeForm"
                                  v-model="validDiscountGuestCode"
                                  @submit.prevent="applyDiscountGuestCode"
                                >
                                  <v-row>
                                    <v-col cols="4" class="pb-0 mb-0">
                                      <v-text-field
                                        type="text"
                                        density="compact"
                                        placeholder="Enter Discount Code"
                                        v-model="discountTextCode"
                                        variant="outlined"
                                        class="text-black"
                                        @blur.prevent="resetGuestValidation"
                                        :rules="requiredRules"
                                        color="primary"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="pb-0 mb-0">
                                      <v-hover v-slot="{ isHovering, props }">
                                        <v-btn
                                          v-if="!discountGuestCode"
                                          class="text-uppercase fontsize-14 checkoutpage-button-height cart-products-button"
                                          type="submit"
                                          size="large"
                                          density="comfortable"
                                          depressed
                                          :rounded="0"
                                          :variant="
                                            isHovering ? 'outlined' : ''
                                          "
                                          v-bind="props"
                                          :class="
                                            isHovering
                                              ? 'text-primary bg-white'
                                              : 'text-white bg-primary'
                                          "
                                          >APPLY</v-btn
                                        >
                                      </v-hover>
                                      <v-hover
                                        v-slot:default="{ isHovering, props }"
                                      >
                                        <v-btn
                                          v-if="discountGuestCode"
                                          class="text-uppercase checkoutpage-button-height cart-products-button"
                                          @click.prevent="
                                            removeDiscountGuestCode
                                          "
                                          size="large"
                                          density="compact"
                                          :variant="
                                            isHovering ? 'outlined' : ''
                                          "
                                          v-bind="props"
                                          :class="
                                            isHovering
                                              ? 'bgWhiteColor'
                                              : 'bgRedColor'
                                          "
                                          depressed
                                          rounded="0"
                                          >CANCEL</v-btn
                                        >
                                      </v-hover>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-text>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                    <div v-if="!giftCardProduct">
                      <v-row
                        class="pl-4 mb-10"
                        v-show="
                          giftCardCreditBalance != 0 &&
                          shippingInformations.totals.grandTotal != 0 &&
                          checkStoreCredit(
                            shippingInformations.totals.totalSegments
                          ) &&
                          this.userAuth &&
                          showStoreCredit
                        "
                      >
                        <hr />
                        <v-col cols="12">
                          <p class="mb-5">Store Credit</p>
                          <p>
                            ${{ giftCardCreditBalance }} Store Credit available
                          </p>
                        </v-col>
                        <v-col cols="12" align="right">
                          <v-btn
                            class="primary mx-3 text-uppercase"
                            @click="useStoreCredit()"
                            tile
                            size="default"
                            color="primary"
                            rounded="0"
                            depressed
                            >Use Store Credit</v-btn
                          >
                        </v-col>
                      </v-row>
                    </div>
                    <div
                      v-if="
                        giftCardProduct && cartProductTotalAmt.totalSegments
                      "
                    >
                      <v-row
                        class="pl-4 mb-10"
                        v-if="
                          giftCardCreditBalance != 0 &&
                          checkStoreCredit(cartProductTotalAmt.totalSegments) &&
                          this.userAuth &&
                          showStoreCredit &&
                          cartProductTotalAmt.grandTotal !== 0
                        "
                      >
                        <hr />
                        <v-col cols="12">
                          <p class="mb-5">Store Credit</p>
                          <p>
                            ${{ giftCardCreditBalance }} Store Credit available
                          </p>
                        </v-col>
                        <v-col cols="12" align="right">
                          <v-btn
                            class="primary mx-3 text-uppercase"
                            @click="useStoreCredit()"
                            tile
                            depressed
                            >Use Store Credit</v-btn
                          >
                        </v-col>
                      </v-row>
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="5" md="4" class="pl-3 d-none d-sm-block">
                  <p class="h3 mb-2">Order Summary</p>
                  <hr />
                  <v-expansion-panels class="checkpout-expansion-panel">
                    <v-expansion-panel elevation="0">
                      <v-expansion-panel-title class="pr-4"
                        >{{ cartProductsItem.length }} Items In
                        cart</v-expansion-panel-title
                      >
                      <v-expansion-panel-text class="checkout-expansion">
                        <div class="mb-5 checkout-expansion-maxheight">
                          <CartProductList
                            :checkOut="chekoutFlag"
                            :cartItems="cartProductsItem"
                          />
                        </div>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <hr />
                  <div v-if="giftCardProduct">
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="my-3 d-flex justify-space-between"
                      >
                        <h4>Cart Subtotal</h4>
                        <span
                          >$
                          {{
                            priceFormatter(
                              this.cartProductTotalAmt.baseSubtotal
                            )
                          }}</span
                        >
                      </v-col>
                      <v-col cols="12" class="d-flex justify-space-between">
                        <h3>Estimated Total</h3>
                        <span
                          >$
                          {{
                            priceFormatter(this.cartProductTotalAmt.grandTotal)
                          }}</span
                        >
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                    <div>
                      <v-row no-gutters v-if="inProgressDiscountCode">
                        <v-progress-circular
                          :size="40"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                      </v-row>
                      <v-row no-gutters v-else>
                        <v-col
                          cols="12"
                          class="my-3 d-flex justify-space-between"
                        >
                          <h4>Cart Subtotal</h4>
                          <span
                            >$
                            {{
                              priceFormatter(
                                shippingInformations.totals.subtotal
                              )
                            }}</span
                          >
                        </v-col>
                        <v-col
                          cols="12"
                          class="my-3 d-flex justify-space-between"
                          v-if="
                            shippingInformations.totals &&
                            shippingInformations.totals.totalSegments &&
                            shippingInformations.totals.totalSegments.length &&
                            shippingInformations.totals.totalSegments.find(
                              (x) => x.code == 'discount'
                            )
                          "
                        >
                          <span
                            >{{
                              shippingInformations.totals.totalSegments.find(
                                (x) => x.code == "discount"
                              )?.title
                            }}
                          </span>
                          <span
                            >-${{
                              priceFormatter(
                                shippingInformations.totals.baseDiscountAmount
                                  .toString()
                                  .substring(1)
                              )
                            }}</span
                          >
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between">
                          <span>Shipping</span>
                          <span
                            >$
                            {{
                              priceFormatter(
                                shippingInformations.totals.shippingAmount
                              )
                            }}</span
                          >
                        </v-col>
                        <v-col
                          cols="12"
                          class="d-flex justify-space-between"
                          v-for="(types, index) in shippingInformations.totals
                            .totalSegments"
                          :key="index"
                        >
                          <span v-if="types.code === 'shipping'">{{
                            types.title
                          }}</span>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between">
                          <span>Estimated Tax</span>
                          <span
                            >$
                            {{
                              priceFormatter(
                                shippingInformations.totals.taxAmount
                              )
                            }}</span
                          >
                        </v-col>
                        <v-col
                          cols="12"
                          class="d-flex justify-space-between"
                          v-if="
                            checkStoreCreditWithCode(
                              shippingInformations.totals.totalSegments
                            )
                          "
                        >
                          <span>{{ giftCardCreditBalance }} Store Credit</span>
                          <span>-${{ giftCardCreditBalance }}</span>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between">
                          <h3>Estimated Total</h3>
                          <span
                            >$
                            {{
                              priceFormatter(
                                shippingInformations.totals.baseGrandTotal
                              )
                            }}</span
                          >
                        </v-col>
                      </v-row>
                      <hr />
                      <v-row v-if="rewardsPoints">
                        <v-col cols="12" class="my-3">
                          You will earn
                          <span class="text-primary text-underline"
                            >{{ rewardsPoints }}
                          </span>
                          Reward Points with this Purchase.
                        </v-col>
                      </v-row>
                      <v-row v-if="rewardsPoints" no-gutters>
                        <v-col cols="12">
                          <hr />
                        </v-col>
                      </v-row>
                      <v-row class="mt-4">
                        <v-col class="d-flex justify-space-between pb-0">
                          <span class="text-h4">Ship To:</span>
                          <v-btn
                            icon
                            variant="plain"
                            @click="goToShippingTab()"
                          >
                            <v-icon :icon="mdiPencil" />
                          </v-btn>
                        </v-col>
                        <v-col cols="12">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="
                            shippingInformations &&
                            shippingInformations.extensionAttributes
                          "
                        >
                          <p class="mb-2 body">
                            {{
                              shippingInformations.extensionAttributes
                                .originalAddress.firstname
                            }}
                            {{
                              shippingInformations.extensionAttributes
                                .originalAddress.lastname
                            }}
                          </p>
                          <p class="mb-2 body">
                            {{
                              shippingInformations.extensionAttributes.originalAddress.street.toString()
                            }}
                          </p>
                          <p class="mb-2 body">
                            {{
                              shippingInformations.extensionAttributes
                                .originalAddress.city
                            }},
                            <span
                              v-if="
                                shippingInformations.extensionAttributes
                                  .originalAddress.region
                              "
                              >{{
                                shippingInformations.extensionAttributes
                                  .originalAddress.region
                              }}</span
                            >
                            {{
                              shippingInformations.extensionAttributes
                                .originalAddress.postcode
                            }}
                          </p>
                          <p class="mb-2 body">
                            <span
                              v-if="
                                shippingInformations.extensionAttributes
                                  .originalAddress.countryId
                              "
                              >{{
                                shippingInformations.extensionAttributes
                                  .originalAddress.countryId
                              }}</span
                            >
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="my-4">
                        <v-col class="d-flex justify-space-between pb-0">
                          <span class="text-h4">Shipping Method:</span>
                          <v-btn
                            icon
                            variant="plain"
                            @click="goToShippingTab()"
                          >
                            <v-icon :icon="mdiPencil" />
                          </v-btn>
                        </v-col>
                        <v-col cols="12">
                          <v-divider></v-divider>
                        </v-col>
                        <div
                          v-for="(types, index) in shippingInformations.totals
                            .totalSegments"
                          :key="index"
                        >
                          <v-col
                            cols="12"
                            class="pb-0"
                            v-if="types.code === 'shipping'"
                            >{{ types.title }}</v-col
                          >
                        </div>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters v-else>
                <v-container fill-height fluid>
                  <v-row align="center" justify="center">
                    <v-progress-circular
                      :size="60"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </v-container>
              </v-row>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
      <v-col v-else>
        <v-alert
          class="alert-box-outline"
          type="error"
          variant="tonal"
          density="compact"
          >You have no items in your shopping cart.</v-alert
        >
        <p>
          Click
          <span
            class="mr-1 text-decoration-none fontcolor-black-light fontsize-16 guest-shipping-address-login"
          >
            <span @click="goToShopping()" class="cursor-pointer"
              >here</span
            > </span
          >to continue shopping.
        </p>
      </v-col>
      <v-dialog v-model="showAddressDialog" persistent flat :width="600">
        <v-card flat>
          <v-card-title class="d-flex justify-space-between">
            <p class="h3 mb-0">Shipping Address</p>
            <v-btn @click="cancelUpdateAddress()" variant="plain">
              <v-icon :icon="mdiWindowClose" />
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-3 overflow-x-hidden">
            <v-row no-gutters>
              <v-col cols="12">
                <ShippingAddressFormComponent
                  @actionValid="checkFormValid"
                  :hideAndShowFields="hideAndShowFields"
                  @verifyAddress="verifyAddress"
                  ref="dialogShippingAddressForm"
                />
              </v-col>
              <v-col cols="12" class="py-0">
                <v-checkbox
                  label="Save in Address Book"
                  v-model="saveInAddressBook"
                  class="mt-0"
                  dense
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn
              class="primary ma-2"
              color="primary"
              variant="flat"
              rounded="0"
              @click="cancelUpdateAddress()"
              >Cancel</v-btn
            >
            <v-btn
              class="ma-2"
              color="success"
              variant="flat"
              @click="saveUserAddress()"
              rounded="0"
              >Save Address</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
    </v-container>
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import { mdiWindowClose, mdiPencil, mdiAlert } from "@mdi/js";
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
import CartProductList from "@/components/crane/cart-product/CartProductList.vue";
import ShippingAddressFormComponent from "@/components/me/address/ShippingAddressFormComponent.vue";
import GuestShippingAddressForm from "@/components/me/address/GuestShippingAddressForm.vue";
import BillingAddressComponent from "@/components/me/address/BillingAddressComponent.vue";
import VerifyAddressComponent from "@/components/me/address/VerifyAddressComponent.vue";
import CardPayment from "@/components/crane/payment/CardPayment.vue";
import { createHelpers } from "vuex-map-fields";
import { getCookieByName } from "@/services/auth";
import * as easings from "vuetify/lib/services/goto/easing-patterns";

const crGuestQuoteId = import.meta.env.VITE_GUEST_QUOTE_ID;
const reCaptchaSitekey2 = import.meta.env.VITE_CAPTCHA_SITE_KEY_2;
const reCaptchaSitekey3 = import.meta.env.VITE_CAPTCHA_SITE_KEY_3;

import {
  googleAanalyticsPurchase,
  purchaseIncident,
  googleAanalyticsCheckout,
  priceFormatter,
  fetchStates,
  googleAanalyticsCheckoutAddShippingInfo,
  googleAanalyticsCheckoutAddPaymentInfo,
  googleAanalyticsPromoCode,
} from "@/services/util.service";

import { doVerifyRecaptcha } from "@/services/recaptcha.service";

const { mapFields } = createHelpers({
  getterType: "accountOne/getField",
  mutationType: "accountOne/updateField",
});
export default {
  name: "CheckoutPage",
  data() {
    return {
      mdiWindowClose,
      mdiPencil,
      mdiAlert,
      easing: "easeInOutCubic",
      easings: Object.keys(easings),
      priceFormatter: priceFormatter,
      email: "",
      panelCode: 0,
      lazy: false,
      giftCardProduct: false,
      hideEmailField: false,
      hideEmailFieldGiftCardProduct: true,
      nextButtonFlag: false,
      giftCardProductTitle: "Credit Card",
      giftCardProductCode: "authnetcim",
      giftCardFreeProductTitle: "No Payment Information Required",
      validLogin: false,
      passwordFields: false,
      showAlert: false,
      valid: false,
      chekoutFlag: false,
      tabs: "",
      showReviewTab: false,
      showShippingTab: false,
      geoCodedAddress: "",
      geoCodedAddressFlag: false,
      validAddresses: "",
      addressInProgress: false,
      sameAddress: true,
      paymentMethod: "",
      paymentMethodFree: true,
      freePaymentMethod: "free",
      cartItemPanel: [0],
      saveInAddressBook: false,
      showAddressDialog: false,
      userNewAddress: false,
      regionName: "",
      instance: null,
      selectedAddressId: null,
      estimatedShipping: "",
      selectedBillingAddress: null,
      billingAddressList: [],
      showNewBillingForm: false,
      previousAddress: "",
      readOnlyCheckBox: false,
      editBillingButton: false,
      saveInBillingAddressBook: false,
      localBillingAddress: false,

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      textRules: [(v) => !!v || "This field is required"],
      snackbar: {
        show: false,
        message: null,
        color: null,
        timeout: 0,
      },
      showStoreCredit: true,
      isPageLoader: false,
      validEmail: false,
      emailId: "",
      discountTextCode: "",
      customerDiscountTextCode: "",
      validDiscountCode: true,
      validDiscountGuestCode: true,
      requiredRules: [(v) => !!v || "This field is required"],
      recaptchaToken: null,
      recaptchaScore: null,
      showNext: true,
    };
  },
  components: {
    ShippingAddressFormComponent,
    BillingAddressComponent,
    CartProductList,
    VerifyAddressComponent,
    GuestShippingAddressForm,
    CardPayment,
    SnackbarComponent,
  },
  metaInfo() {
    return {
      titleTemplate: "Checkout",
      title: "Checkout",
      keywords: "Magento, Varien, E-commerce",
      viewport: "width=device-width, initial-scale=1",
      robots: "INDEX,FOLLOW",
      "format-detection": "telephone=no",

      link: [
        {
          rel: "canonical",
          href: `${
            this.canonical ? window.location.href.split("?")[0] : baseUrl
          }`,
        },
      ],
    };
  },
  watch: {
    estimatedShippingMethods() {
      this.setEstimatedShippingData();
    },

    userLocalAddress() {
      this.selectedAddressId = "";
    },
    addressesList() {
      if (this.addressesList.length > 0) {
        if (!this.userLocalAddress)
          this.selectedAddressId = this.addressesList[0].id;
        if (this.selectedAddressId != 0)
          this.$store.dispatch("accountOne/setAddress", this.addressesList[0]);
      } else {
        this.$store.dispatch("accountOne/setAddress", null);
      }
    },
    discountGuestCode() {
      this.discountTextCode = this.discountGuestCode;
    },
    resetGuestValidation() {
      if (!this.validDiscountGuestCode && this.$refs.discountCodeForm) {
        this.$refs.discountCodeForm.reset();
      }
    },
    resetCustomerValidation() {
      if (
        !this.customerDiscountTextCode &&
        this.$refs.discountCodeMultipleForm
      ) {
        this.$refs.validDiscountCode.reset();
      }
    },
    cartProductsItem() {
      this.stockFlagHideShow();
      if (this.checkProductType() == "giftcard") {
        this.showReviewTab = true;
        this.showShippingTab = false;
        this.tabs = "reviewTab";
        this.valid = true;
        this.giftCardProduct = true;
      } else {
        this.valid = false;
        this.tabs = "shippingTab";
        this.showReviewTab = true;
        this.showShippingTab = true;
        this.giftCardProduct = false;
      }
    },
    giftCardProduct() {
      if (this.giftCardProduct && this.userAuth) {
        this.billingAddressList = this.$store.state.accountOne.addresses;
        this.selectedBillingAddress = this.billingAddressList[0];
        if (this.billingAddressList.length == 0) this.showNewBillingForm = true;
      }
    },
  },
  computed: {
    canonical: function () {
      return this.$route.meta.canonical;
    },
    giftCardCreditBalance: function () {
      return this.$store.state.storeCredit.giftCardCreditBalance;
    },
    storeCreditInProgress: function () {
      return this.$store.state.storeCredit.storeCreditInProgress;
    },
    hideAndShowFields: function () {
      return true;
    },
    paymentInProgress: function () {
      if (this.userAuth) return this.$store.state.accountOne.paymentInProgress;
      else return this.$store.state.guestInfo.paymentInProgress;
    },
    isMobileDevice() {
      return this.$vuetify.display.xsOnly;
    },
    cartProductTotalAmt() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.productTotalAmt;
      } else {
        return this.$store.state.cartProduct.productTotalAmt;
      }
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function () {
      return getCookieByName(crGuestQuoteId);
    },
    fetchSeletedAddress: function () {
      return this.$store.state.accountOne.seletedAddress;
    },
    basicInfo: function () {
      if (this.userAuth) {
        return this.$store.state.accountOne.one;
      } else {
        return this.$store.state.guestInfo.one;
      }
    },
    addressContainPOBox: function () {
      if (this.userAuth) {
        return this.$store.state.accountOne.addressContainPOBox;
      } else {
        return this.$store.state.guestInfo.addressContainPOBox;
      }
    },
    addressesList: function () {
      return this.$store.state.accountOne.addresses;
    },
    address: function () {
      if (this.userAuth) {
        return this.$store.state.accountOne.address;
      } else {
        return this.$store.state.guestInfo.address;
      }
    },
    addressLength: function () {
      if (this.basicInfo.addresses) return this.basicInfo.addresses.length;
      return -1;
    },
    addressFields: function () {
      if (this.userAuth) {
        return this.$store.state.accountOne.address;
      } else {
        return this.$store.state.guestInfo.address;
      }
    },
    ...mapFields(["one", "allCountries", "countryAllStates"]),

    estimatedShippingMethods() {
      if (this.userAuth) {
        return this.$store.state.accountOne.estimatedShipping;
      } else {
        return this.$store.state.guestInfo.estimatedShipping;
      }
    },
    inProgress() {
      if (this.userAuth) {
        return this.$store.state.accountOne.inProgress;
      } else {
        return this.$store.state.guestInfo.inProgress;
      }
    },
    shippingInformations() {
      if (this.userAuth) {
        return this.$store.state.accountOne.shippingInformation;
      } else {
        return this.$store.state.guestInfo.shippingInformation;
      }
    },
    cartProductsItem() {
      let cartList = [];
      if (this.userAuth) {
        cartList = this.$store.state.userCartProduct.cartProductList;
        if (cartList && cartList.length) {
          this.checkoutProductReward(cartList);
          googleAanalyticsCheckout("begin_checkout", { items: cartList });
        }

        return cartList;
      } else {
        cartList = this.$store.state.cartProduct.cartProductList;
        if (cartList && cartList.length) {
          this.checkoutProductReward(cartList);
          googleAanalyticsCheckout("begin_checkout", { items: cartList });
        }

        return cartList;
      }
    },

    userLocalAddress() {
      return this.$store.state.accountOne.userLocalAddress;
    },
    userLocalAddressFlag() {
      return this.$store.state.accountOne.userLocalAddressFlag;
    },
    userSelectedAddressIndex() {
      return this.$store.state.accountOne.userSelectedAddressIndex;
    },
    userLocalBillAddress() {
      return this.$store.state.accountOne.userLocalBillAddress;
    },
    lastAddedAddressBookIndex() {
      return this.$store.state.accountOne.lastAddedAddressBookIndex;
    },
    inProgressDiscountGuestCode() {
      return this.$store.state.cartProduct.fetchInProgress;
    },
    inProgressDiscountCode() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.fetchInProgress;
      }
    },
    discountGuestCode() {
      return this.$store.state.cartProduct.discountCode;
    },
    customerDiscountCodeList() {
      return this.$store.state.userCartProduct.discountCodeList;
    },

    cartProductsProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductsProgress;
      } else {
        return this.$store.state.cartProduct.cartProductsProgress;
      }
    },
    shippingInformationsInProgress: function () {
      if (this.userAuth) {
        return this.$store.state.accountOne.shippingInformationsInProgress;
      } else {
        return this.$store.state.guestInfo.shippingInformationsInProgress;
      }
    },
    countryAndStatesList() {
      return this.$store.state.accountOne.allCountries;
    },
    authnetcimPayment() {
      if (
        this.shippingInformations &&
        this.shippingInformations.paymentMethods
      ) {
        let paymentMethods = this.shippingInformations.paymentMethods;
        this.paymentMethod = paymentMethods.filter(
          (method) => method.code === "authnetcim"
        )[0].code;
        return paymentMethods.filter(
          (method) => method.code === "authnetcim"
        )[0];
      }
    },
    freePayment() {
      let paymentMethods = this.shippingInformations.paymentMethods;
      return paymentMethods.filter((method) => method.code === "free")[0];
    },
    rewardsPoints: function () {
      return this.$store.state.productOne.rewardsPoints;
    },
  },
  methods: {
    checkoutProductReward(cartList) {
      try {
        let cartListNew = [];
        for (let i = 0; i < cartList.length; i++) {
          if (cartList[i].productType != "giftcard") {
            cartListNew.push({
              price: cartList[i].price,
              product_id: cartList[i].sku,
              category: cartList[i].productType,
              quantity: cartList[i].qty,
            });
          }
        }
        if (cartListNew.length) {
          this.$store.dispatch("productOne/fetchRewardsPoints", cartListNew);
        }
      } catch (error) {
        console.log(error);
      }
    },
    scrollToTop() {
      const data = this.$refs.divOne;
      if (data) {
        this.$smoothScroll({
          scrollTo: data.$el.scrollTop,
          duration: 300,
          easingFunction: "easeInOutCubic",
          updateHistory: false,
        });
      }
    },
    goToShippingTab() {
      this.valid = false;
      this.tabs = "shippingTab";
    },
    checkProductType() {
      let array = this.cartProductsItem.filter((obj) => {
        return obj.productType == "giftcard";
      });
      if (this.cartProductsItem.length === array.length) {
        return "giftcard";
      }
      return "other";
    },
    sendEmailId() {
      this.$emit("actionValid", { valid: this.valid, email: this.email });
    },
    checkEmail() {
      this.email = this.$refs.emailForm ? this.emailId : this.email;
      this.$store
        .dispatch("guestInfo/checkEmailAvailable", this.email)
        .then((data) => {
          if (data.exist == false) {
            this.passwordFields = true;
          } else {
            this.passwordFields = false;
          }
        });
    },
    stockFlagHideShow() {
      if (this.cartProductsItem) {
        let errorCount = 0;
        let i;
        for (i = 0; i < this.cartProductsItem.length; i++) {
          if (
            (!this.cartProductsItem[i].stockDetails.isInStock ||
              this.cartProductsItem[i].stockDetails.qty <
                this.cartProductsItem[i].qty) &&
            this.cartProductsItem[i].stockDetails.manageStock &&
            this.cartProductsItem[i].stockDetails.backorders == 0
          ) {
            errorCount++;
          }
        }
        if (errorCount) {
          this.$router.push({
            name: "CartProducts",
          });
        }
      }
    },
    useStoreCredit() {
      try {
        this.$store.dispatch("storeCredit/applyStoreCredit").then(() => {
          if (this.userAuth) {
            this.$store.dispatch(
              "accountOne/setShippingInformation",
              this.estimatedShipping
            );
            if (this.giftCardProduct) {
              this.$store.dispatch("userCartProduct/productTotalAmt");
            }
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    },
    resetValidation() {
      if (!this.validDiscountCode) {
        this.$refs.discountCodeForm.reset();
      }
    },
    goToShopping() {
      this.$router.push({
        name: "HomePage",
      });
    },
    showTab(tab) {
      this.tabs = tab;
    },
    changedToShippTab() {
      this.valid = false;
      if (
        localStorage.getItem("cr_" + this.basicInfo.id + "_userNewShippAddress")
      ) {
        this.$store.dispatch(
          "accountOne/setLocalShippAddress",
          JSON.parse(
            localStorage.getItem(
              "cr_" + this.basicInfo.id + "_userNewShippAddress"
            )
          )
        );
      }
    },
    openDialogForEditAddress() {
      this.geoCodedAddress = "";
      this.addressInProgress = true;
      this.showAddressDialog = true;
      this.previousAddress = this.address;
      this.$store.dispatch("accountOne/setAddress", null);
      if (
        this.$refs.dialogShippingAddressForm &&
        this.cartProductsItem.items !== ""
      )
        this.$refs.dialogShippingAddressForm.$refs.shippingAddressform.resetValidation();
    },
    editLocalAddress(address, flag) {
      this.showAddressDialog = true;
      this.previousAddress = address;
      if (flag) this.$store.dispatch("accountOne/setAddress", address);
      else this.$store.dispatch("accountOne/setLocalShippAddress", address);
    },
    setDiscountTextGuestCode() {
      this.discountTextCode = this.$store.state.cartProduct.discountCode;
    },
    async applyDiscountGuestCode() {
      try {
        let validate = await this.$refs.discountCodeForm.validate();
        if (validate && validate.valid) {
          this.$store
            .dispatch("cartProduct/applyDiscountCode", {
              discountTextCode: this.discountTextCode,
              guestQuoteId: this.guestQuoteId,
            })
            .then(() => {
              googleAanalyticsPromoCode("promo_code", this.discountTextCode);
              this.snackbar = {
                message: "Your coupon was successfully applied",
                color: "success",
                show: true,
                timeout: 2000,
              };
              this.$store.dispatch("guestInfo/setShippingInformation", {
                method: this.estimatedShipping,
                email: this.email,
              });
            })
            .catch((error) => {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 2000,
              };
            });
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async removeDiscountGuestCode() {
      try {
        let validate = await this.$refs.discountCodeForm.validate();
        if (validate && validate.valid) {
          this.$store
            .dispatch("cartProduct/removeDiscountCode", {
              discountTextCode: this.discountTextCode,
              guestQuoteId: this.guestQuoteId,
            })
            .then(() => {
              this.snackbar = {
                message: "Your coupon was successfully removed",
                color: "success",
                show: true,
                timeout: 2000,
              };
              this.$store.dispatch("guestInfo/setShippingInformation", {
                method: this.estimatedShipping,
                email: this.email,
              });
            })
            .catch((error) => {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 2000,
              };
            });
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async applyDiscountCodeMultiple() {
      let validate = await this.$refs.discountCodeMultipleForm.validate();
      if (validate && validate.valid) {
        this.$store
          .dispatch(
            "userCartProduct/applyDiscountCode",
            this.customerDiscountTextCode
          )
          .then(() => {
            googleAanalyticsPromoCode(
              "promo_code",
              this.customerDiscountTextCode
            );
            this.snackbar = {
              message: "Your coupon was successfully applied",
              color: "success",
              show: true,
              timeout: 2000,
            };
            this.customerDiscountTextCode = "";
            this.$store.dispatch(
              "accountOne/setShippingInformation",
              this.estimatedShipping
            );
          })
          .catch((error) => {
            this.snackbar = {
              message: error.message,
              color: "error",
              show: true,
              timeout: 2000,
            };
          });
      }
    },
    removeDiscountMultipleCode(item) {
      if (this.userAuth) {
        this.$store
          .dispatch("userCartProduct/removeDiscountCode", item)
          .then(() => {
            this.snackbar = {
              message: "Your coupon was successfully removed",
              color: "success",
              show: true,
              timeout: 2000,
            };
            this.$store.dispatch(
              "accountOne/setShippingInformation",
              this.estimatedShipping
            );
          })
          .catch((error) => {
            this.snackbar = {
              message: error,
              color: "error",
              show: true,
              timeout: 2000,
            };
          });
      }
    },
    continueToPaypal() {},
    resetFields() {
      if (!this.userAuth) {
        this.hideEmailField = true;
      } else {
        this.billingAddressList = [];
        this.billingAddressList = this.addressesList.filter((obj) => {
          return obj;
        });
        let newAddr = {
          text: "New Address",
          value: "New Address",
        };
        this.billingAddressList.push(newAddr);
        if (this.userSelectedAddressIndex != -1)
          this.selectedBillingAddress =
            this.billingAddressList[this.userSelectedAddressIndex];
        else this.selectedBillingAddress = this.billingAddressList[0];
        this.previousAddress = this.address;
        if (this.addressLength == 0) this.showNewBillingForm = true;
        else this.showNewBillingForm = false;
        this.billBookDesignChanges();
      }
    },
    editBillBook() {
      this.sameAddress = false;
      this.billBookDesignChanges();
    },
    billBookDesignChanges() {
      let billBookFlag = localStorage.getItem(
        "cr_" + this.basicInfo.id + "_billBookAddressFlag"
      );
      if (billBookFlag != "true") {
        if (
          localStorage.getItem(
            "cr_" + this.basicInfo.id + "_userNewBillAddress"
          )
        ) {
          let newAddress = JSON.parse(
            localStorage.getItem(
              "cr_" + this.basicInfo.id + "_userNewBillAddress"
            )
          );
          this.$store.dispatch("accountOne/setLocalBillAddress", newAddress);
          this.previousAddress = newAddress;
          this.selectedBillingAddress =
            this.billingAddressList[this.billingAddressList.length - 1];
          this.showNewBillingForm = true;
        }
      }
    },
    cancelBillingAddress() {
      this.sameAddress = true;
      if (this.previousAddress.street)
        this.$store.dispatch("accountOne/setAddress", this.previousAddress);
      else
        this.$store.dispatch(
          "accountOne/setLocalShippAddress",
          this.previousAddress
        );

      this.editBillingButton = false;
      this.readOnlyCheckBox = false;
    },
    updateBillingAddress() {
      this.$store.dispatch(
        "accountOne/setAddress",
        this.selectedBillingAddress
      );
      this.sameAddress = true;
      this.editBillingButton = true;
      this.readOnlyCheckBox = false;
    },
    changeBillingAddress() {
      try {
        if (this.selectedBillingAddress.value) {
          this.showNewBillingForm = true;
          if (this.userLocalBillAddress) {
            this.$store.dispatch(
              "accountOne/setLocalBillAddress",
              this.userLocalBillAddress
            );
            this.previousAddress = this.address;
          } else {
            this.$store.dispatch("accountOne/setAddress", null);
          }
          this.readOnlyCheckBox = true;
          localStorage.setItem(
            "cr_" + this.basicInfo.id + "_billBookAddressFlag",
            false
          );
        } else {
          this.showNewBillingForm = false;
          this.$store.dispatch(
            "accountOne/setAddress",
            this.selectedBillingAddress
          );
          this.readOnlyCheckBox = false;
          localStorage.setItem(
            "cr_" + this.basicInfo.id + "_billBookAddressFlag",
            true
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    newBillingAddress() {
      try {
        this.previousAddress = this.address;
        if (this.$refs.billingForm.$refs.shippingAddressform.validate()) {
          if (this.saveInBillingAddressBook) {
            this.$store.dispatch("accountOne/updateAddress");
            localStorage.setItem(
              "cr_" + this.basicInfo.id + "_billBookAddressFlag",
              true
            );
          } else {
            localStorage.setItem(
              "cr_" + this.basicInfo.id + "_billBookAddressFlag",
              false
            );
            this.$store.dispatch(
              "accountOne/setLocalBillAddress",
              this.previousAddress
            );
          }
          this.sameAddress = true;
          this.readOnlyCheckBox = false;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    checkFormValid(flag) {
      if (flag) return (this.nextButtonFlag = true);
    },
    checkGuestFormValid(flag) {
      if (flag)
        return (
          (this.nextButtonFlag = true) &&
          flag.email &&
          (this.email = flag.email)
        );
    },

    shipHereAddress(address, index) {
      try {
        if (index != -1) {
          this.selectedAddressId = address.id;
          this.$store.dispatch(
            "accountOne/setAddress",
            this.addressesList[index]
          );
        } else {
          this.selectedAddressId = "";
          this.$store.dispatch("accountOne/setLocalShippAddress", address);
        }
        this.verifyAddress();
        this.$store.dispatch("accountOne/setSelectedShippAddressIndex", index);
      } catch (error) {
        console.log("error", error);
      }
    },
    async verifyAddress() {
      let vm = this;
      try {
        if (
          vm.address.regionId &&
          vm.address.city &&
          vm.address.countryId &&
          vm.address.streetNoOne &&
          vm.address.postcode
        ) {
          vm.geoCodedAddress = "";
          this.addressInProgress = true;
          if (vm.countryAndStatesList.length == 0) {
            await vm.$store.dispatch("accountOne/fetchCountries");
          }
          let stateNameList = fetchStates(
            vm.address.countryId,
            vm.countryAndStatesList
          );
          let stateName = stateNameList.filter((obj) => {
            if (obj.value == vm.address.regionId) return obj;
          });
          stateName = stateName ? stateName[0].label : "";

          let response = await vm.$store.dispatch(
            "accountOne/validateAddressCheckout",
            { address: vm.addressFields, stateName: stateName }
          );
          if (typeof response == "object") {
            if (response[0].error_message) {
              vm.geoCodedAddress = response[0].error_message;
              vm.geoCodedAddressFlag = false;
              vm.validAddresses = "";
            } else {
              vm.geoCodedAddress = "Address is geocoded.";
              vm.geoCodedAddressFlag = true;
              vm.validAddresses = response[0];
            }
          }
          if (vm.userAuth) {
            await vm.$store.dispatch("accountOne/setEstimatedShipping");
          } else if (vm.guestQuoteId) {
            await vm.$store.dispatch(
              "guestInfo/setEstimatedShipping",
              vm.email
            );
          }
          this.addressInProgress = false;
        }
      } catch (error) {
        this.addressInProgress = false;
        vm.snackbar = {
          message: error,
          color: "error",
          show: true,
          timeout: 3000,
        };
      }
    },
    regionDetails(regionName) {
      if (regionName[0].value) {
        this.regionName = regionName[0].label;
      }
    },
    async verifyRecaptcha(token) {
      const secretKey = "6LclOrAqAAAAALnf9Kzy374eE1jozp5TPL3foZ7q"; // Replace with your secret key
      try {
        const response = await fetch(
          "https://www.google.com/recaptcha/api/siteverify",
          {
            body: JSON.stringify({
              secret: secretKey,
              response: token,
            }),
            mode: "cors",
            method: "POST",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        if (response.data.success) {
          // Return the score
          return response.data.score; // This is a score between 0 and 1
        } else {
          throw new Error("reCAPTCHA verification failed");
        }
      } catch (error) {
        console.error("Error verifying reCAPTCHA:", error);
        throw error;
      }
    },
    onSubmit() {
      // First, get the reCAPTCHA token and score
      // this.executeRecaptcha('checkout')
      //   .then((token) => {
      //     // You can send the token to your backend for further verification
      //     console.log('reCAPTCHA Token:', token);
      //      this.showInteractiveCaptcha();
      //     // If score is below 0.5, show interactive CAPTCHA
      //     if (this.recaptchaScore < 0.5) {
      //     //  this.showInteractiveCaptcha();
      //     } else {
      //       this.proceedWithCheckout();
      //     }
      //   })
      //   .catch((error) => {
      //     console.error('reCAPTCHA Error:', error);
      //   });
    },

    // Function to execute reCAPTCHA v3 and retrieve the token and score
    executeRecaptcha(action) {
      let self = this;
      return new Promise((resolve, reject) => {
        if (window.grecaptcha) {
          grecaptcha.ready(function () {
            grecaptcha
              .execute(reCaptchaSitekey2, { action: "submit" })
              .then(async (token) => {
                // this.recaptchaScore = await self.verifyRecaptcha(token); // Get score (0.0 - 1.0)

                resolve(token);
              })
              .catch((error) => {
                reject(error);
              });
          });
        } else {
          reject("reCAPTCHA not loaded");
        }
      });
    },

    // Show the reCAPTCHA v2 (interactive) if the score is too low
    showInteractiveCaptcha() {
      // Show the reCAPTCHA v2 widget (you will need to set it up in your frontend)
      const widgetId = window.grecaptcha.render("recaptcha-container", {
        sitekey: reCaptchaSitekey2,
        size: "invisible",
        callback: this.onCaptchaResolved,
      });
    },

    // After CAPTCHA v2 is solved, proceed with checkout
    onCaptchaResolved(response) {
      console.log("reCAPTCHA v2 resolved:", response);
      this.proceedWithCheckout();
    },

    // Proceed with checkout after successful verification
    proceedWithCheckout() {
      // Send the form data along with reCAPTCHA token to the backend for final processing
      console.log("Proceeding with checkout...");
    },

    next: async function () {
      try {
        if (this.userAuth) {
          if (this.addressesList.length == 0) {
            let phoneNumber = this.$refs.userAddressForm.$refs.phoneNumber
              ? this.$refs.userAddressForm.$refs.phoneNumber.phoneObject.isValid
              : true;
            if (!phoneNumber) {
              this.$refs.userAddressForm.$refs.phoneNumber
                ? this.$refs.userAddressForm.validateNumber()
                : "";
            }
            let validateshippingAddressform =
              await this.$refs.userAddressForm.$refs.shippingAddressform.validate();
            if (
              validateshippingAddressform &&
              validateshippingAddressform.valid &&
              phoneNumber
            ) {
              if (this.estimatedShippingMethods.length > 0) {
                this.valid = true;
                this.loadRecaptcha();
              } else {
                this.snackbar = {
                  message: "Please correct your address.",
                  color: "error",
                  show: true,
                  timeout: 3000,
                };
              }
            }
          } else {
            this.valid = true;
            this.loadRecaptcha();
          }
        } else {
          let phoneNumber = this.$refs.guestAddressForm.$refs.phoneNumber
            ? this.$refs.guestAddressForm.$refs.phoneNumber.phoneObject.isValid
            : true;
          if (!phoneNumber) {
            this.$refs.guestAddressForm.$refs.phoneNumber
              ? this.$refs.guestAddressForm.validateNumber()
              : "";
          }
          let validateShippingAddressForm =
            await this.$refs.guestAddressForm.$refs.guestShippingAddressForm.validate();
          let validateLoginForm =
            await this.$refs.guestAddressForm.$refs.loginForm.validate();
          if (
            validateShippingAddressForm &&
            validateShippingAddressForm.valid &&
            validateLoginForm &&
            validateLoginForm.valid &&
            phoneNumber
          ) {
            if (this.estimatedShippingMethods.length > 0) {
              this.valid = true;
              this.loadRecaptcha();
            } else {
              this.snackbar = {
                message: "Please correct your address.",
                color: "error",
                show: true,
                timeout: 3000,
              };
            }
          } else {
            this.snackbar = {
              message: "Please fill out all required fields",
              color: "error",
              show: true,
              timeout: 3000,
            };
            window.scrollTo(0, 0);
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async saveUserAddress() {
      try {
        let phoneNumber = this.$refs.dialogShippingAddressForm.$refs.phoneNumber
          ? this.$refs.dialogShippingAddressForm.$refs.phoneNumber.phoneObject
              .isValid
          : true;
        if (!phoneNumber) {
          this.$refs.dialogShippingAddressForm.$refs.phoneNumber
            ? this.$refs.dialogShippingAddressForm.validateNumber()
            : "";
        }
        let validateShippingAddressform =
          await this.$refs.dialogShippingAddressForm.$refs.shippingAddressform.validate();
        if (
          validateShippingAddressform &&
          validateShippingAddressform.valid &&
          phoneNumber
        ) {
          this.valid = true;
          if (this.userAuth) {
            if (this.saveInAddressBook) {
              this.$store.dispatch("accountOne/updateAddress");
              this.showAddressDialog = false;
              localStorage.setItem(
                "cr_" + this.basicInfo.id + "_userNewShippAddress",
                ""
              );
              this.$store.dispatch("accountOne/setLocalShippAddress", "");
              this.$store.dispatch(
                "accountOne/setSelectedShippAddressIndex",
                this.addressesList.length - 1
              );
              this.$store.dispatch(
                "accountOne/lastAddedAddressBookIndex",
                this.addressesList.length - 1
              );
            } else {
              this.$store.dispatch("accountOne/setShippingAddress");
              this.$store.dispatch(
                "accountOne/setSelectedShippAddressIndex",
                -1
              );
              this.$store.dispatch("accountOne/lastAddedAddressBookIndex", -1);
              this.showAddressDialog = false;
            }

            this.$store.dispatch("accountOne/setLocalShippAddressFlag", true);
          }
        }
        this.addressInProgress = false;
      } catch (error) {
        console.log("error", error);
      }
    },
    cancelUpdateAddress() {
      this.showAddressDialog = false;
      this.addressInProgress = false;
      if (this.userLocalAddress) {
        this.$store.dispatch(
          "accountOne/setLocalShippAddress",
          this.previousAddress
        );
      } else {
        this.$store.dispatch("accountOne/setAddress", this.previousAddress);
      }
    },
    onGatewayLoad(instance) {
      this.instance = instance;
    },
    onPaymentSuccess(payload) {
      console.log(payload);
    },
    onPaymentError(error) {
      console.log(error);
    },
    onGatewayLoadFail(instance) {
      console.log("load failed", instance);
    },
    async placeOrderFree() {
      try {
        this.isPageLoader = true;
        if (this.userAuth) {
          try {
            let res = await this.$store.dispatch("accountOne/setOrder", {
              method: this.freePaymentMethod,
            });
            await this.$store.dispatch("accountOne/setPaymentInProgress", true);
            if (res) {
              try {
                let resp = await this.$store.dispatch(
                  "meProductOne/fetchOrderProduct",
                  res
                );
                if (resp && resp.items) {
                  googleAanalyticsCheckoutAddPaymentInfo(
                    "add_payment_info",
                    resp
                  );
                  googleAanalyticsPurchase(resp, resp.incrementId);
                  purchaseIncident(resp, resp.incrementId);
                  let shippingAddress = [];
                  let ccid = "";
                  let webOrderNumber = resp.incrementId;
                  let magentoOrderNumber = res;
                  for (let i = 0; resp.items.length > i; i++) {
                    let obj = resp.items[i];
                    if (obj.extensionAttributes) {
                      ccid = obj.extensionAttributes.ccid;
                      if (ccid) {
                        shippingAddress =
                          resp.extensionAttributes.shippingAssignments[0]
                            .shipping.address;
                        try {
                          let response = await this.$store.dispatch(
                            "accountOne/setCCOrder",
                            {
                              orderNumber: ccid,
                              shippingAddress: shippingAddress,
                              webOrderNumber: webOrderNumber,
                              estimatedShipping: this.estimatedShipping,
                            }
                          );
                        } catch (error) {
                          console.log(error);
                        }
                      }

                      await this.placeCraneOrder(
                        resp,
                        obj,
                        webOrderNumber,
                        magentoOrderNumber
                      );
                    }
                  }
                }
              } catch (error) {
                await this.$store.dispatch(
                  "accountOne/setPaymentInProgress",
                  false
                );
                this.isPageLoader = false;
                this.snackbar = {
                  message: error,
                  color: "error",
                  show: true,
                  timeout: 5000,
                };
              }
            }
            await this.$store.dispatch(
              "accountOne/setPaymentInProgress",
              false
            );
            this.$router.push({
              name: "OrderSuccess",
              params: { orderNumber: res },
            });
          } catch (error) {
            this.isPageLoader = false;
            this.snackbar = {
              message: error.message,
              color: "error",
              show: true,
              timeout: 5000,
            };
          }
        } else {
          try {
            let res = await this.$store.dispatch("guestInfo/setOrder", {
              userEmail: this.email,
              paymentMethod: {
                method: this.freePaymentMethod,
              },
            });
            await this.$store.dispatch("guestInfo/setPaymentInProgress", true);
            if (res) {
              try {
                let resp = await this.$store.dispatch(
                  "meProductOne/fetchOrderProduct",
                  res
                );
                if (resp && resp.items) {
                  googleAanalyticsCheckoutAddPaymentInfo(
                    "add_payment_info",
                    resp
                  );
                  googleAanalyticsPurchase(resp, resp.incrementId);
                  purchaseIncident(resp, resp.incrementId);
                  let shippingAddress = [];
                  let ccid = "";
                  let webOrderNumber = resp.incrementId;
                  let magentoOrderNumber = res;
                  for (let i = 0; resp.items.length > i; i++) {
                    let obj = resp.items[i];
                    if (obj.extensionAttributes) {
                      ccid = obj.extensionAttributes.ccid;
                      if (ccid) {
                        shippingAddress =
                          resp.extensionAttributes.shippingAssignments[0]
                            .shipping.address;
                        try {
                          let response = await this.$store.dispatch(
                            "accountOne/setCCOrder",
                            {
                              orderNumber: ccid,
                              shippingAddress: shippingAddress,
                              webOrderNumber: webOrderNumber,
                              estimatedShipping: this.estimatedShipping,
                            }
                          );
                        } catch (error) {
                          console.log(error);
                        }
                      }

                      await this.placeCraneOrder(
                        resp,
                        obj,
                        webOrderNumber,
                        magentoOrderNumber
                      );
                    }
                  }
                }
              } catch (error) {
                await this.$store.dispatch(
                  "accountOne/setPaymentInProgress",
                  false
                );
                this.isPageLoader = false;
                this.snackbar = {
                  message: error,
                  color: "error",
                  show: true,
                  timeout: 5000,
                };
              }
            }
            await this.$store.dispatch("guestInfo/setPaymentInProgress", false);
            this.$router.push({
              name: "OrderSuccess",
              params: { orderNumber: res },
            });
          } catch (error) {
            this.isPageLoader = false;
            this.snackbar = {
              message: error.message,
              color: "error",
              show: true,
              timeout: 5000,
            };
          }
        }
      } catch (error) {}
    },
    async submitCardPayment(payment) {
      try {
        let vm = this;
        this.isPageLoader = true;
        if (this.userAuth) {
          if (vm.$refs.billingForm) {
            let formValid = vm.$refs.billingForm
              ? vm.$refs.billingForm.$refs.shippingAddressform.validate()
              : true;
            if (!formValid) {
              this.snackbar = {
                message: "Please enter all required fields",
                color: "error",
                show: true,
                timeout: 5000,
              };
              return;
            }
          }
          try {
            let res = await this.$store.dispatch("accountOne/setOrder", {
              method: vm.paymentMethod,
              additional_data: {
                acceptjs_key: payment.dataDescriptor,
                acceptjs_value: payment.dataValue,
              },
            });
            await this.$store.dispatch("accountOne/setPaymentInProgress", true);
            if (res) {
              try {
                let resp = await this.$store.dispatch(
                  "meProductOne/fetchOrderProduct",
                  res
                );
                if (resp && resp.items) {
                  googleAanalyticsCheckoutAddPaymentInfo(
                    "add_payment_info",
                    resp
                  );
                  googleAanalyticsPurchase(resp, resp.incrementId);
                  purchaseIncident(resp, resp.incrementId);
                  let shippingAddress = [];
                  let ccid = "";
                  let webOrderNumber = resp.incrementId;
                  let magentoOrderNumber = res;
                  for (let i = 0; resp.items.length > i; i++) {
                    let obj = resp.items[i];
                    if (obj.extensionAttributes) {
                      ccid = obj.extensionAttributes.ccid;
                      if (ccid) {
                        shippingAddress =
                          resp.extensionAttributes.shippingAssignments[0]
                            .shipping.address;
                        try {
                          let response = await this.$store.dispatch(
                            "accountOne/setCCOrder",
                            {
                              orderNumber: ccid,
                              shippingAddress: shippingAddress,
                              webOrderNumber: webOrderNumber,
                              estimatedShipping: this.estimatedShipping,
                            }
                          );
                        } catch (error) {
                          console.log(error);
                        }
                      }

                      await this.placeCraneOrder(
                        resp,
                        obj,
                        webOrderNumber,
                        magentoOrderNumber
                      );
                    }
                  }
                }
              } catch (error) {
                await this.$store.dispatch(
                  "accountOne/setPaymentInProgress",
                  false
                );
                this.isPageLoader = false;
                this.snackbar = {
                  message: error,
                  color: "error",
                  show: true,
                  timeout: 5000,
                };
              }
            }
            await this.$store.dispatch(
              "accountOne/setPaymentInProgress",
              false
            );
            this.$router.push({
              name: "OrderSuccess",
              params: { orderNumber: res },
            });
          } catch (error) {
            this.isPageLoader = false;
            this.snackbar = {
              message: error.message,
              color: "error",
              show: true,
              timeout: 5000,
            };
            this.$store.dispatch("layout/setPaymentCount", 1);
          }
        } else {
          if (vm.$refs.emailForm) {
            vm.email = vm.emailId;
            let valid = vm.$refs.emailForm.validate();
            let formValid = vm.$refs.addressFormGuest
              ? vm.$refs.addressFormGuest.$refs.guestShippingAddressForm.validate()
              : true;
            if (!valid || !formValid) {
              this.snackbar = {
                message: "Please enter all required fields",
                color: "error",
                show: true,
                timeout: 5000,
              };
              return;
            }
          }
          try {
            let res = await this.$store.dispatch("guestInfo/setOrder", {
              userEmail: vm.email,
              paymentMethod: {
                method: vm.paymentMethod,
                additional_data: {
                  acceptjs_key: payment.dataDescriptor,
                  acceptjs_value: payment.dataValue,
                },
              },
            });
            await this.$store.dispatch("guestInfo/setPaymentInProgress", true);
            if (res) {
              try {
                let resp = await this.$store.dispatch(
                  "meProductOne/fetchOrderProduct",
                  res
                );
                if (resp && resp.items) {
                  googleAanalyticsCheckoutAddPaymentInfo(
                    "add_payment_info",
                    resp
                  );
                  googleAanalyticsPurchase(resp, resp.incrementId);
                  purchaseIncident(resp, resp.incrementId);
                  let shippingAddress = [];
                  let ccid = "";
                  let webOrderNumber = resp.incrementId;
                  let magentoOrderNumber = res;
                  for (let i = 0; resp.items.length > i; i++) {
                    let obj = resp.items[i];
                    if (obj.extensionAttributes) {
                      ccid = obj.extensionAttributes.ccid;
                      if (ccid) {
                        shippingAddress =
                          resp.extensionAttributes.shippingAssignments[0]
                            .shipping.address;
                        try {
                          let response = await this.$store.dispatch(
                            "accountOne/setCCOrder",
                            {
                              orderNumber: ccid,
                              shippingAddress: shippingAddress,
                              webOrderNumber: webOrderNumber,
                              estimatedShipping: this.estimatedShipping,
                            }
                          );
                        } catch (error) {
                          console.log(error);
                        }
                      }

                      await this.placeCraneOrder(
                        resp,
                        obj,
                        webOrderNumber,
                        magentoOrderNumber
                      );
                    }
                  }
                }
              } catch (error) {
                await this.$store.dispatch(
                  "guestInfo/setPaymentInProgress",
                  false
                );
                this.isPageLoader = false;
                this.snackbar = {
                  message: error,
                  color: "error",
                  show: true,
                  timeout: 5000,
                };
              }
            }
            await this.$store.dispatch("guestInfo/setPaymentInProgress", false);
            this.$router.push({
              name: "OrderSuccess",
              params: { orderNumber: res },
            });
          } catch (error) {
            this.$store.dispatch("layout/setPaymentCount", 1);
            this.isPageLoader = false;
            this.snackbar = {
              message: error.message,
              color: "error",
              show: true,
              timeout: 5000,
            };
          }
        }
      } catch (error) {}
    },
    clearLocalStorageVars() {
      localStorage.removeItem(
        "cr_" + this.basicInfo.id + "_userFirstShippAddress"
      );
      localStorage.removeItem(
        "cr_" + this.basicInfo.id + "_userNewShippAddressFlag"
      );
      localStorage.removeItem(
        "cr_" + this.basicInfo.id + "_userNewShippAddress"
      );
      localStorage.removeItem(
        "cr_" + this.basicInfo.id + "_userSelectedShippAddressIndex"
      );
      localStorage.removeItem(
        "cr_" + this.basicInfo.id + "_billBookAddressFlag"
      );
      localStorage.removeItem(
        "cr_" + this.basicInfo.id + "_userNewBillAddress"
      );
      localStorage.removeItem(
        "cr_" + this.basicInfo.id + "_lastAddedAddressBookIndex"
      );
    },
    localStorageInitialOperation() {
      if (this.basicInfo.id) {
        if (this.addressesList.length == 0) {
          if (
            localStorage.getItem(
              "cr_" + this.basicInfo.id + "_userFirstShippAddress"
            )
          ) {
            let userFirstAddress = JSON.parse(
              localStorage.getItem(
                "cr_" + this.basicInfo.id + "_userFirstShippAddress"
              )
            );
            this.$store.dispatch(
              "accountOne/setLocalShippAddress",
              userFirstAddress
            );
          }
        } else if (this.addressesList.length > 0) {
          let flag = localStorage.getItem(
            "cr_" + this.basicInfo.id + "_userNewShippAddressFlag"
          );
          if (flag != null)
            this.$store.dispatch("accountOne/setLocalShippAddressFlag", flag);

          if (
            localStorage.getItem(
              "cr_" + this.basicInfo.id + "_userNewShippAddress"
            )
          ) {
            let tempLocalAddress = JSON.parse(
              localStorage.getItem(
                "cr_" + this.basicInfo.id + "_userNewShippAddress"
              )
            );
            this.$store.dispatch(
              "accountOne/setLocalShippAddress",
              tempLocalAddress
            );
          }

          if (
            localStorage.getItem(
              "cr_" + this.basicInfo.id + "_userSelectedShippAddressIndex"
            )
          ) {
            let index = localStorage.getItem(
              "cr_" + this.basicInfo.id + "_userSelectedShippAddressIndex"
            );
            if (index < this.addressesList.length)
              this.$store.dispatch(
                "accountOne/setSelectedShippAddressIndex",
                index
              );
            else
              this.$store.dispatch(
                "accountOne/setSelectedShippAddressIndex",
                0
              );
            //set user address to  state.address
            if (index == -1)
              this.$store.dispatch(
                "accountOne/setLocalShippAddress",
                JSON.parse(
                  localStorage.getItem(
                    "cr_" + this.basicInfo.id + "_userNewShippAddress"
                  )
                )
              );
            else
              this.$store.dispatch(
                "accountOne/setAddress",
                this.addressesList[index]
              );
          } else {
            this.$store.dispatch("accountOne/setSelectedShippAddressIndex", 0);
          }
          if (
            localStorage.getItem(
              "cr_" + this.basicInfo.id + "_lastAddedAddressBookIndex"
            )
          ) {
            let index = localStorage.getItem(
              "cr_" + this.basicInfo.id + "_lastAddedAddressBookIndex"
            );
            this.$store.dispatch("accountOne/lastAddedAddressBookIndex", index);
          } else
            this.$store.dispatch("accountOne/lastAddedAddressBookIndex", -1);
        }
      }
    },

    async placeCraneOrder(resp, obj, webOrderNumber, magentoOrderNumber) {
      try {
        let shipArray = [];
        let shippingAddress = [];
        shippingAddress =
          resp.extensionAttributes.shippingAssignments[0].shipping.address;

        let shp = {};
        shp.RefId = `${webOrderNumber}`;
        shp.Quantity = obj.qtyOrdered;
        shp.ShipToName =
          shippingAddress.firstname + " " + shippingAddress.lastname;
        shp.ShipToAddress1 = shippingAddress.street[0];
        shp.ShipToAddress2 = shippingAddress.street[1];
        shp.ShipToAddress3 = null;
        shp.ShipToCity = shippingAddress.city;
        shp.ShipToState = shippingAddress.regionCode;
        shp.ShipToPostalCode = shippingAddress.postcode;
        shp.ShipToCountry = shippingAddress.countryId;
        shp.ShipToPhoneNumber = shippingAddress.telephone;
        shp.ShipMethod =
          resp.extensionAttributes.shippingAssignments[0].shipping.method;
        shipArray.push(shp);

        /* obj productOption builder session */
        let builderSessionOne = null;
        if (
          obj &&
          obj.extensionAttributes &&
          obj.extensionAttributes.customAttributes.length > 0
        ) {
          builderSessionOne =
            await obj.extensionAttributes.customAttributes.filter((obj) => {
              let att = JSON.parse(obj);
              if (att["title"] && att["title"] === "BuilderSessionId") {
                return att;
              }
            })[0];
        }

        let builderOne = null;
        if (
          obj &&
          obj.productOption &&
          obj.productOption.extensionAttributes &&
          obj.productOption.extensionAttributes.customOptions.length > 0 &&
          builderSessionOne
        ) {
          builderSessionOne = JSON.parse(builderSessionOne);
          let customOptionsList =
            obj.productOption.extensionAttributes.customOptions;
          builderOne = customOptionsList.find(
            (x) => x.optionId == builderSessionOne.option_id
          );
        }
        console.log("builderOne", builderOne);

        if (builderOne) {
          let sessionOneDetails = await this.$store.dispatch(
            "sessionOne/fetchOne",
            { sessionId: builderOne.optionValue }
          );

          if (
            sessionOneDetails &&
            sessionOneDetails.response &&
            sessionOneDetails.response[0]
          ) {
            let order = {
              m2_order_id: `${magentoOrderNumber}`,
              m2_order_item_id: `${obj.itemId}`,
              m2_order_po_id: `${webOrderNumber}`,
              ship_array: shipArray,
            };

            await this.$store.dispatch("sessionOne/updateSessionOne", {
              sessionId: builderOne.optionValue,
              property: {
                order_data: {
                  ...sessionOneDetails.response[0].orderData,
                  ...order,
                },
                order_state: "ordered",
              },
            });
          }
        }
      } catch (error) {
        console.log("error in builder 2 order place", error);
      }
    },
    getEstimatedShippingData() {
      if (this.estimatedShippingMethods) {
        const estimatedData = this.estimatedShippingMethods;
        for (let i = 0; i <= estimatedData.length; i++) {
          return estimatedData[0];
        }
      }
    },
    checkStoreCredit(totalSegments) {
      let newArray = totalSegments.filter((a) => {
        if (a.code == "customerbalance") {
          return a;
        }
      });

      if (newArray && newArray.length > 0 && newArray[0].value === 0) {
        return true;
      } else {
        return false;
      }
    },
    checkStoreCreditWithCode(totalSegments) {
      let newArray = totalSegments.filter((a) => {
        if (a.code == "customerbalance") {
          return a;
        }
      });

      if (newArray && newArray.length > 0 && newArray[0].value === 0) {
        return false;
      } else {
        return true;
      }
    },
    setEstimatedShippingData() {
      const data = this.getEstimatedShippingData();
      if (data === undefined) {
        this.estimatedShipping = "";
      } else {
        this.estimatedShipping = data;
      }
    },

    async goToReviewTab() {
      try {
        this.showNext = true;
        if (this.userAuth) {
          if (this.addressesList.length == 0) {
            let phoneNumber = this.$refs.userAddressForm.$refs.phoneNumber
              ? this.$refs.userAddressForm.$refs.phoneNumber.phoneObject.isValid
              : true;
            if (!phoneNumber) {
              this.$refs.userAddressForm.$refs.phoneNumber
                ? this.$refs.userAddressForm.validateNumber()
                : "";
            }
            let validateshippingAddressform =
              await this.$refs.userAddressForm.$refs.shippingAddressform.validate();
            if (
              validateshippingAddressform &&
              validateshippingAddressform.valid &&
              phoneNumber
            ) {
              if (this.estimatedShippingMethods.length > 0) {
                this.$store.dispatch(
                  "accountOne/setLocalShippAddress",
                  this.address
                );
                localStorage.setItem(
                  "cr_" + this.basicInfo.id + "_userFirstShippAddress",
                  JSON.stringify(this.address)
                );
                this.$store.dispatch(
                  "accountOne/setShippingInformation",
                  this.estimatedShipping
                );

                googleAanalyticsCheckoutAddShippingInfo(
                  "add_shipping_info",
                  this.cartProductsItem,
                  this.estimatedShipping
                );
                this.valid = true;
                this.tabs = "reviewTab";
              } else {
                this.snackbar = {
                  message: "Please correct your address.",
                  color: "error",
                  show: true,
                  timeout: 3000,
                };
              }
            }
          } else {
            this.$store.dispatch(
              "accountOne/setShippingInformation",
              this.estimatedShipping
            );

            googleAanalyticsCheckoutAddShippingInfo(
              "add_shipping_info",
              this.cartProductsItem,
              this.estimatedShipping
            );
            this.valid = true;
            this.tabs = "reviewTab";
          }
          this.$store.dispatch("accountOne/resetLocalShippAddressVar", null);
        } else {
          let phoneNumber = this.$refs.guestAddressForm.$refs.phoneNumber
            ? this.$refs.guestAddressForm.$refs.phoneNumber.phoneObject.isValid
            : true;
          if (!phoneNumber) {
            this.$refs.guestAddressForm.$refs.phoneNumber
              ? this.$refs.guestAddressForm.validateNumber()
              : "";
          }
          let validateShippingAddressForm =
            await this.$refs.guestAddressForm.$refs.guestShippingAddressForm.validate();
          let validateLoginForm =
            await this.$refs.guestAddressForm.$refs.loginForm.validate();
          if (
            validateShippingAddressForm &&
            validateShippingAddressForm.valid &&
            validateLoginForm &&
            validateLoginForm.valid &&
            phoneNumber
          ) {
            if (this.estimatedShippingMethods.length > 0) {
              googleAanalyticsCheckoutAddShippingInfo(
                "add_shipping_info",
                this.cartProductsItem,
                this.estimatedShipping
              );

              this.$store.dispatch("guestInfo/setShippingInformation", {
                method: this.estimatedShipping,
                email: this.email,
              });
              this.valid = true;
              this.tabs = "reviewTab";
            } else {
              this.snackbar = {
                message: "Please correct your address.",
                color: "error",
                show: true,
                timeout: 3000,
              };
            }
          } else {
            this.snackbar = {
              message: "Please fill out all required fields",
              color: "error",
              show: true,
              timeout: 3000,
            };
            window.scrollTo(0, 0);
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async loadRecaptcha() {
      let token = await grecaptcha.execute(reCaptchaSitekey3);
      let verifyReCaptcha = await doVerifyRecaptcha({ token: token });
      console.log("verifyReCaptcha", verifyReCaptcha);
      if (verifyReCaptcha.score > 0.5) {
        this.goToReviewTab();
      } else {
        grecaptcha.render("crane-recaptcha", {
          sitekey: reCaptchaSitekey2,
          callback: this.goToReviewTab,
        });
        this.showNext = false;
      }
    },
  },

  async created() {
    if (this.userAuth) {
      await this.$store.dispatch("accountOne/basicInfo");

      this.$store.dispatch("userCartProduct/fetchCartCouponList");
      if (this.basicInfo) {
        this.$store
          .dispatch("storeCredit/fetchGiftCardCreditBalance", this.basicInfo.id)
          .catch(() => {
            this.showStoreCredit = false;
          });
      }
      this.localStorageInitialOperation();

      this.verifyAddress();
    } else {
      if (this.guestQuoteId) {
        this.$store.dispatch("guestInfo/basicInfo");
        this.$store.dispatch("cartProduct/fetchCartCoupon", this.guestQuoteId);
        this.setDiscountTextGuestCode();
        this.$store.dispatch("guestInfo/resetEstimatedShipping");
      }
    }

    this.stockFlagHideShow();

    if (this.cartProductsItem) {
      if (this.checkProductType() == "giftcard") {
        this.showReviewTab = true;
        this.showShippingTab = false;
        this.showExpansion = false;
        this.tabs = "reviewTab";
        this.valid = true;
        this.giftCardProduct = true;
      } else {
        this.valid = false;
        this.tabs = "shippingTab";
        this.showReviewTab = true;
        this.showShippingTab = true;
        this.showExpansion = true;
        this.giftCardProduct = false;
      }
    }
  },
  mounted() {
    this.scrollToTop();
    try {
      let jsElm1 = document.createElement("script");
      jsElm1.type = "text/javascript";
      jsElm1.src = "https://www.google.com/recaptcha/api.js?render=explicit";
      document.body.appendChild(jsElm1);
      let recaptchaInterval = null;
      recaptchaInterval = setInterval(() => {
        let recaptchaBadge =
          document.getElementsByClassName("grecaptcha-badge");
        if (
          recaptchaBadge &&
          recaptchaBadge.length &&
          recaptchaBadge[0].style
        ) {
          recaptchaBadge[0].style.display = "block";
          clearInterval(recaptchaInterval);
        }
      }, 100);
    } catch (error) {
      console.error("recaptcha setting error", error);
    }
  },
  beforeUnmount() {
    try {
      let recaptchaBadge = document.getElementsByClassName("grecaptcha-badge");
      if (recaptchaBadge &&
          recaptchaBadge.length &&
          recaptchaBadge[0].style) {
        recaptchaBadge[0].style.display = "none";
      }
    } catch (error) {
      console.error("recaptcha hide error", error);
    }
  },
};
</script>
<style>
.checkout-tabs {
  margin: auto !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.checkout-expansion .v-expansion-panel-title__overlay {
  background-color: #fff;
}
.myDivider {
  width: 100%;
  height: 1px;
  background-color: #2850da;
  margin-top: 15px;
}
.myShipping,
.v-tab.v-tab {
  --v-btn-height: var(--v-tabs-height);
  min-width: 50%;
}
.active-panel-edited1 .v-expansion-panel-title__overlay {
  background-color: transparent !important;
}
</style>
<style src="./checkout_page.scss" lang="scss" />