<template>
  <div>
    <component :is="myComponent"/>
    <FooterComponent />
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import StoreLocatoreComponent from "@/components/crane/store-locatore/StoreLocatoreComponent.vue";
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
export default {
  name: "StoreLocatore",
  components: {
    StoreLocatoreComponent,
    FooterComponent,
  },
  computed: {
    canonical: function () {
      return this.$route.meta.canonical;
    },
  },
  data: () => ({
    googleApisUrl: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDmHp0PPuKHD1KGHCDmLq1qQKr_03XKEHo&libraries=places",
    myComponent: null,
  }),
  metaInfo() {
    return {
      link: [
        {
          rel: "canonical",
          href: `${
            this.canonical ? window.location.href.split("?")[0] : baseUrl
          }`,
        },
      ],
    };
  },
 

  created(){
    this.loadJsScript();
  },

  methods: {

    loadJsScript() {
      
      let script = document.createElement("script");
      script.src = this.googleApisUrl;
      script.onload = ()=> {
        
        console.log("GoogleApisUrl is loaded", this.src);
     
          this.myComponent= StoreLocatoreComponent;
          console.log("Checking",this.myComponent)
      };
      document.body.appendChild(script); 
    },   
  },
};
</script>