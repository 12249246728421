<template>
  <div class="d-flex flex-column landing-page-height">
    <LandingComponent/>
    <FooterComponent class="mt-auto"/>
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import LandingComponent from "@/components/crane/landing/LandingComponent.vue";
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
export default {
  name: "LandingPage",
  data: () => ({ 
  }),
   components: {
    LandingComponent,
    FooterComponent
  },
  computed:{
     canonical: function() {
      return this.$route.meta.canonical;
    },
     landingPageContent: function() {
     return this.$store.state.layout.pageContent;
     },
      pageId: function(){
      return this.$route.meta.pid
     }
  },
  methods:{
    pageData() {
      if (this.landingPageContent.length) {
       let data = this.landingPageContent.filter(element=>{
      return element.id === this.pageId});
             if(data.length > 0){
               return data[0]
             }

      }
  },
  },
  metaInfo() {
        return {
          titleTemplate: `${this.landingPageContent && this.pageData() && this.pageData().title != undefined ?this.pageData().title:''}`,
            title: `${this.landingPageContent && this.pageData() && this.pageData().title != undefined ?this.pageData().title:''}`,
            description:`${this.landingPageContent && this.pageData() && this.pageData().metaDescription != undefined?this.pageData().metaDescription:''}`,
           
             link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ] 
        }
  },
 
};
</script>
<style src="./landing_page.scss" lang="scss" scoped />