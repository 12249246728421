<template>
  <div>
    <SearchProductsComponent @criteriaChanged="searchProducts"  :criteria="criteria" :searchPageContent="searchPageContent"  :breadcrumbData="searchKeyword" :categoryPageContent="categoryContent" />
    <SeoFooterContent />
    <FooterComponent />
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import SearchProductsComponent from "@/components/crane/search/SearchProducts.vue";
import SeoFooterContent from "@/components/layout/footer/SeoFooterContent.vue";
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
 
import { mapState } from "vuex";
export default {
  name: "SearchPage",
  components: {
    SearchProductsComponent,
    FooterComponent,
    SeoFooterContent
   
  },
  data: () => ({
     categoryContent:false,
     searchPageContent:true,
     searchKeyword:'keyword',
     criteria: [
      { label: "PRODUCT NAME", value: "name" },
      { label: "PRICE:LOW-HIGH", value: "price" },
      { label: "COLOR", value: "color" },
      { label: "RELEVANCE", value: "relevance" },
    ],
   }),
  computed: {
    ...mapState({
      searchTerms: (state) => state.catalogBrowser.searchTerms,
      enabledFilters: (state) => state.catalogBrowser.enabledFilters,
    }),
    canonical: function () {
      return this.$route.meta.canonical;
    },
  },
  metaInfo() {
    return {
      link: [
        {
          rel: "canonical",
          href: `${
            this.canonical ? window.location.href.split("?")[0] : baseUrl
          }`,
        },
      ],
    };
  },
  methods: {
    searchProducts() {
         this.$store.dispatch("catalogBrowser/search", {
          fullCatalog: window.craneCatalog,
          keywords: this.searchTerms.keywords,
          filters: this.searchTerms.filters,
          pageNumber:this.searchTerms.pageNumber,
          pageSize:this.searchTerms.pageSize,
          sortBy: this.searchTerms.sortBy
     });
      this.pushPrams({
      pageNumber: this.searchTerms.pageNumber,
      pageSize: this.searchTerms.pageSize,
      sortBy:this.searchTerms.sortBy,
      q: this.searchTerms.keywords,
      filter:btoa(JSON.stringify(this.searchTerms.filters)),
    });

    },
    pushPrams(pushObj) {
      this.$router
        .push({
          query: {
            pageNumber: pushObj.pageNumber,
            pageSize: pushObj.pageSize,
            sortBy: pushObj.sortBy,
            q: pushObj.q,
            filter: pushObj.filter,
          },
        })
        .catch((error) => {
          error;
        });
    },
  },
  created() {
    var pageNumber = this.searchTerms.pageNumber;
    if (this.$route.query.pageNumber !== undefined) {
      pageNumber = Number(this.$route.query.pageNumber);
    }
    var pageSize = this.searchTerms.pageSize;
    if (this.$route.query.pageSize !== undefined) {
      pageSize = Number(this.$route.query.pageSize);
    }
    var sortBy = this.searchTerms.sortBy;
    if (this.$route.query.sortBy !== undefined) {
       sortBy = this.$route.query.sortBy;
    }
    var keywords = this.searchTerms.keywords;
    if (this.$route.query.q !== undefined) {
      keywords = this.$route.query.q;
    }

    var filters = this.searchTerms.filters;
    if (this.$route.query.filter !== undefined) {
      filters = JSON.parse(atob(this.$route.query.filter));
    }
    this.pushPrams({
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortBy:sortBy,
      q: keywords,
      filter:this.$route.query.filter,
    });
    this.$store.dispatch("catalogBrowser/search", {
      fullCatalog: window.craneCatalog,
      keywords,
      filters,
      pageNumber,
      pageSize,
      sortBy,
    });
  },
};
</script>