<template>
  <div>
    <FaqComponent />
    <FooterComponent />
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import FaqComponent from "@/components/crane/faq/FaqComponent.vue";
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
export default {
  name: "FaqPage",
  components: {
    FaqComponent,
    FooterComponent
  },
  data: () => ({
  }),
  computed:{
  canonical: function() {
      return this.$route.meta.canonical;
    }, 
  },
   metaInfo() {
        return {
           
            link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ] 
        }
  },
};
</script>
 