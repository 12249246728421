<template>
  <div
    class="productpage-backgorund d-flex flex-column productpage-height"
    ref="divOne"
  >
    <div>
      <businessCardDetailComponent />
    </div>
  </div>
</template>
<script>

const baseUrl = import.meta.env.VITE_BASE_URL;
import businessCardDetailComponent from "@/components/crane/product-detail/businessCardDetailComponent.vue";

export default {
  name: "business-card",
  components: {
    businessCardDetailComponent,
  },
  data: () => ({}),

  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  created() {},
};
</script>
<style src="./product_page.scss" lang="scss" scoped />