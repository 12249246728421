<template>
  <div class="height-100" v-resize="resize">
      <v-row
        align="center"
        class="pa-0 ma-0 height-100"
      >
        <v-col
          class="white-text regular-font px-sm-8 d-flex flex-column align-center h-100 px-7 height-100"
          cols="12"
          sm="6"
          md="6"
          lg="6"
        >
          <v-col class="primary py-6 ma-4 h-100 d-flex flex-column">
            <v-col class="d-flex align-start px-6">
              <div class="maintenance-max-width-500">
            <p class="h2 ls-n38 text-uppercase pb-6">
              We are taking a moment to reflect.
            </p>
            <p class="h3 ls-n28 pb-6">
              Our site is down as we perform maintenance, and craft some upgrades
            </p>
            <p class="h3 ls-n28">
              Please check back later today, as we look forward to seeing you again.
            </p>
              </div>
            </v-col>
            <v-col class="d-flex align-end px-6">
            <p class="mb-0 ls-n28 h3"><i>The Team At Crane</i></p>
            </v-col>
          </v-col>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" class="py-sm-0 px-0 px-8">
          <div class="chanel-background d-flex flex-column align-center height-100 justify-center" :style="`height:`+imageHeight+`px`">
            <p class="maintenance-max-width-500 text-uppercase h2 ls-n38 primary--text text-center px-sm-0 px-md-0 px-12">“Fashion changes, but style endures.”</p>
            <p class="maintenance-max-width-500 text-uppercase h4 ls-n28 primary--text text-center">Coco Chanel</p>
          </div>
        </v-col>
      </v-row>
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
export default {
  name: "Maintenance",
  components: { },
  data:()=>({
        imageHeight:'',
  }),
  computed: {
    isDesktopDevice: function() {
      return this.$vuetify.display.smAndUp ? true : false;
    },
     canonical: function() {
      return this.$route.meta.canonical;
    },
       maintenanceModeFlag: function() {
      return this.$route.params.disableId
    }
  },
   metaInfo() {
        return {
             link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ]
        }
  },
  methods: {
    resize() {
             this.rightSideHeight();
    },
     rightSideHeight() {
     this.imageHeight = window.innerHeight-80;

    },
  },

  created() {
  if (this.maintenanceModeFlag)
    {
      localStorage.setItem('craneMaintenanceModeFlag' , this.maintenanceModeFlag)
    }
  }
};
</script>
<style src="./maintenance.scss" lang="scss" />