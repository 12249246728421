<template>
  <div>
    
     <div v-if="pageContent.length">
      <div v-html="getPageContent()"></div>
    </div>
    <PostScriptBlog />
    <FooterComponent /> 
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import PostScriptBlog from "@/components/crane/home/PostScriptBlog.vue";
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
import { addPictureTagInHtmlCode } from "@/services/util.service";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;

export default {
  name: "PrintingProcesses",
  components: {
    PostScriptBlog,
    FooterComponent,
  },
  computed: {
    pageContent: function () {
      return this.$store.state.layout.pageContent;
    },
    etiquetteGuideContent: function () {
      return this.$store.state.block.one;
    },
    canonical: function () {
      return this.$route.meta.canonical;
    },
  },
  data: () => ({
    
    blockId: "175",
    variants: [1366,768,360],
    mediaImageUrl
  }),
  metaInfo() {
    return {
      link: [
        {
          rel: "canonical",
          href: `${
            this.canonical ? window.location.href.split("?")[0] : baseUrl
          }`,
        },
      ],
    };
  },
  methods: {
    getPageContent() {
      if (this.pageContent.length) {
        let data = this.pageContent.filter((element) => {
          return element.identifier === "printing-processes-page";
        });
        if (data.length > 0) {
          let path = data[0].content;
          var div = document.createElement("div");
          div.innerHTML = path;
          let imgSrcUrls = div.getElementsByTagName("img");
          for (let i = 0; i < imgSrcUrls.length; i++) {
            imgSrcUrls[i].parentElement.innerHTML = addPictureTagInHtmlCode({currentImageTag: imgSrcUrls[i], variants: this.variants, mediaImageUrl })
          }
          return div.innerHTML;
        }
      }
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PATH_URL;
    },
    routeToPage(pageLink) {
    
      window.open(pageLink, "_blank");
    
    },
    initialLink() {
      return import.meta.env.VITE_HOME_PAGE_URL;
    },
    goToPage(pageLink) {
      window.location.replace(this.initialLink() + pageLink);
      
    },
  },
 
};
</script>
<style src="./printing_processes.scss" lang="scss"/> 