export const productDetails = {
   "id": "14615",
   "name": "Azure Beaded Border Holiday Photo Mount Card ",
   "sku": "KP90047V",
   "price": "32.000000",
   "attributeSetId": "4",
   "status": "1",
   "visibility": "4",
   "typeId": "simple",
   "createdAt": "2020-05-27 14:20:05",
   "updatedAt": "2020-12-14 13:43:17",
   "weight": "0.680000",
   "uom": "box",
   "unitDescription": "10 cards / 10 lined envelopes",
   "description": "Tones of blue flanked with shimmering silver foil on pearl white paper makes our Azure Beaded Border Photo Card a distinctive choice for thoughtful correspondence. Our photo mount cards are pre-taped to accommodate a 4 x 6 inch photo, however the tape is repositionable for adjusting the placement as needed. The best part? Your loved ones can save the print. They'll be able to frame and treasure your family keepsake long after the holiday season has ended. ",
   "mediaGalleryEntries": [
      {
         "id": "23215",
         "mediaType": "image",
         "label": "Azure Beaded Border Holiday Photo Mount Card",
         "position": "1",
         "disabled": "0",
         "types": [
            "image",
            "small_image",
            "thumbnail"
         ],
         "file": "/k/p/kp90047v-1.png"
      },
      {
         "id": "23218",
         "mediaType": "image",
         "label": null,
         "position": "2",
         "disabled": "0",
         "types": [

         ],
         "file": "/k/p/kp90047v-2.png"
      }
   ],
   "extensionAttribute": {
      "websiteIds": [
         "1",
         "2"
      ],
      "catagoryLinks": [
         {
            "postion": "34",
            "catagoryId": "79",
            "categoryName": "Boxed Holiday Cards"
         },
         {
            "postion": "7",
            "catagoryId": "409",
            "categoryName": "Holiday Photo Cards"
         }
      ]
   },
   "customAttribute": [
      {
         "attributeCode": "brand",
         "value": "6986",
         "label": "Crane"
      },
      {
         "attributeCode": "image",
         "value": "/k/p/kp90047v-1.png",
         "label": "/k/p/kp90047v-1.png"
      },
      {
         "attributeCode": "url_key",
         "value": "azure-beaded-border-holiday-photo-mount-card-kp90047v",
         "label": "azure-beaded-border-holiday-photo-mount-card-kp90047v"
      },
      {
         "attributeCode": "page_layout",
         "value": "product-full-width",
         "label": "Product -- Full Width"
      },
      {
         "attributeCode": "gift_message_available",
         "value": "2",
         "label": "Use config"
      },
      {
         "attributeCode": "product_badge",
         "value": "0",
         "label": "None"
      },
      {
         "attributeCode": "small_image",
         "value": "/k/p/kp90047v-1.png",
         "label": "/k/p/kp90047v-1.png"
      },
      {
         "attributeCode": "meta_title",
         "value": "Azure Beaded Border Holiday Photo Mount Card | Crane Stationery",
         "label": "Azure Beaded Border Holiday Photo Mount Card | Crane Stationery"
      },
      {
         "attributeCode": "options_container",
         "value": "container2",
         "label": "Block after Info Column"
      },
      {
         "attributeCode": "gift_wrapping_available",
         "value": "2",
         "label": "Use config"
      },
      {
         "attributeCode": "description",
         "value": "Tones of blue flanked with shimmering silver foil on pearl white paper makes our Azure Beaded Border Photo Card a distinctive choice for thoughtful correspondence. Our photo mount cards are pre-taped to accommodate a 4 x 6 inch photo, however the tape is repositionable for adjusting the placement as needed. The best part? Your loved ones can save the print. They'll be able to frame and treasure your family keepsake long after the holiday season has ended. ",
         "label": "Tones of blue flanked with shimmering silver foil on pearl white paper makes our Azure Beaded Border Photo Card a distinctive choice for thoughtful correspondence. Our photo mount cards are pre-taped to accommodate a 4 x 6 inch photo, however the tape is repositionable for adjusting the placement as needed. The best part? Your loved ones can save the print. They'll be able to frame and treasure your family keepsake long after the holiday season has ended. "
      },
      {
         "attributeCode": "product_dimensions",
         "value": "5 1/2 x 7 3/8",
         "label": "5 1/2 x 7 3/8"
      },
      {
         "attributeCode": "thumbnail",
         "value": "/k/p/kp90047v-1.png",
         "label": "/k/p/kp90047v-1.png"
      },
      {
         "attributeCode": "printing_process",
         "value": "879",
         "label": "Engraved"
      },
      {
         "attributeCode": "swatch_image",
         "value": "no_selection",
         "label": "no_selection"
      },
      {
         "attributeCode": "meta_description",
         "value": "Spread some extra joy this season with the Azure Beaded Border Holiday Photo Mount Card by Crane. Share the love with quality-made holiday stationery.",
         "label": "Spread some extra joy this season with the Azure Beaded Border Holiday Photo Mount Card by Crane. Share the love with quality-made holiday stationery."
      },
      {
         "attributeCode": "cost",
         "value": "16.000000",
         "label": "16.000000"
      },
      {
         "attributeCode": "paper_color",
         "value": "536",
         "label": "Pearl White"
      },
      {
         "attributeCode": "paper_color_parent",
         "value": "63",
         "label": "White"
      },
      {
         "attributeCode": "uom",
         "value": "254",
         "label": "box"
      },
      {
         "attributeCode": "product_def_type",
         "value": "1404",
         "label": "Folded Card"
      },
      {
         "attributeCode": "unit_description",
         "value": "10 cards / 10 lined envelopes",
         "label": "10 cards / 10 lined envelopes"
      },
      {
         "attributeCode": "cotton_paper",
         "value": "892",
         "label": "100% Cotton Paper"
      },
      {
         "attributeCode": "msrp_display_actual_price_type",
         "value": "0",
         "label": "Use config"
      },
      {
         "attributeCode": "related_product_block",
         "value": "766",
         "label": "Craft_Printing_Engraving"
      },
      {
         "attributeCode": "made_usa",
         "value": "893",
         "label": "Made in U.S.A."
      },
      {
         "attributeCode": "related_product_block_2",
         "value": "676",
         "label": "Tips_Holiday_Photo_Mount_Cards"
      },
      {
         "attributeCode": "paper_weight",
         "value": "887",
         "label": "Medium weight paper"
      },
      {
         "attributeCode": "liner",
         "value": "Envelope Lining: Navy",
         "label": "Envelope Lining: Navy"
      },
      {
         "attributeCode": "required_options",
         "value": "0",
         "label": "0"
      },
      {
         "attributeCode": "has_options",
         "value": "0",
         "label": "0"
      },
      {
         "attributeCode": "image_label",
         "value": "Azure Beaded Border Holiday Photo Mount Card",
         "label": "Azure Beaded Border Holiday Photo Mount Card"
      },
      {
         "attributeCode": "small_image_label",
         "value": "Azure Beaded Border Holiday Photo Mount Card",
         "label": "Azure Beaded Border Holiday Photo Mount Card"
      },
      {
         "attributeCode": "thumbnail_label",
         "value": "Azure Beaded Border Holiday Photo Mount Card",
         "label": "Azure Beaded Border Holiday Photo Mount Card"
      },
      {
         "attributeCode": "ba_min_sales_qty",
         "value": "1",
         "label": "1"
      },
      {
         "attributeCode": "ba_qty_increments",
         "value": "1",
         "label": "1"
      },
      {
         "attributeCode": "tax_class_id",
         "value": "2",
         "label": "Taxable Goods"
      },
      {
         "attributeCode": "color",
         "value": "190",
         "label": "Navy"
      },
      {
         "attributeCode": "color_parent",
         "value": "31",
         "label": "Blue"
      },
      {
         "attributeCode": "fulfillment_type",
         "value": "25",
         "label": "JDE"
      },
      {
         "attributeCode": "category_ids",
         "value": [
            "79",
            "409"
         ],
         "label": [
            "79",
            "409"
         ]
      },
      {
         "attributeCode": "manufacturer",
         "value": "418",
         "label": "Crane Stationery"
      },
      {
         "attributeCode": "items_per_box",
         "value": "10",
         "label": "10"
      },
      {
         "attributeCode": "orientation",
         "value": "491",
         "label": "Portrait"
      },
      {
         "attributeCode": "country_of_manufacture",
         "value": "US",
         "label": "United States"
      },
      {
         "attributeCode": "is_returnable",
         "value": "2",
         "label": false
      },
      {
         "attributeCode": "photo_card",
         "value": "746",
         "label": "Yes"
      },
      {
         "attributeCode": "badge",
         "value": "13",
         "label": "None"
      },
      {
         "attributeCode": "personalized",
         "value": "749",
         "label": "No"
      },
      {
         "attributeCode": "badge_enabled",
         "value": "1",
         "label": false
      },
      {
         "attributeCode": "upc",
         "value": "031619697060",
         "label": "031619697060"
      },
      {
         "attributeCode": "is_personalize",
         "value": "0",
         "label": false
      },
      {
         "attributeCode": "builder_version",
         "value": "1.0",
         "label": "1.0"
      },
      {
         "attributeCode": "processing_time",
         "value": "1029",
         "label": "Processing time: 1-2 business days"
      },
      {
         "attributeCode": "iframe_height",
         "value": "0",
         "label": "0"
      }
   ],
   "relatedLinksProduct": [

   ],
   "upsellLinksProduct": [

   ],
   "crossSellLinksProduct": [

   ],
   "configurableProductOptions": [
      [

      ]
   ],
   "configurableProductLinks": [
      null
   ],
   "configurableProductLinksDetails": [

   ],
   "stockDetails": {
      "itemId": "23504",
      "productId": 14615,
      "stockId": 1,
      "qty": 100,
      "isInStock": true,
      "isQtyDecimal": false,
      "minQty": 0,
      "minSaleQty": 1,
      "manageStock": 1,
      "backorders": 1
   }
}

export const addToCartItems = [{
   item_id: 645208,
   name: "Engraved Magnolia Blossom Note",
   price: 32,
   product_type: "simple",
   qty: 10,
   quote_id: "374008",
   sku: "CF12003"
}]

export const cartPurchased = {
   base_currency_code: "USD",
   base_discount_amount: 0,
   base_discount_invoiced: 0,
   base_grand_total: 67.95,
   base_discount_tax_compensation_amount: 0,
   base_discount_tax_compensation_invoiced: 0,
   base_shipping_amount: 7.95,
   base_shipping_discount_amount: 0,
   base_shipping_discount_tax_compensation_amnt: 0,
   base_shipping_incl_tax: 7.95,
   base_shipping_invoiced: 7.95,
   base_shipping_tax_amount: 0,
   base_subtotal: 60,
   base_subtotal_incl_tax: 60,
   base_subtotal_invoiced: 60,
   base_tax_amount: 0,
   base_tax_invoiced: 0,
   base_total_due: 0,
   base_total_invoiced: 67.95,
   base_total_invoiced_cost: 30,
   base_total_paid: 67.95,
   base_to_global_rate: 1,
   base_to_order_rate: 1,
   billing_address_id: 1654,
   created_at: "2021-01-18 10:46:52",
   customer_email: "ganesh@gmail.com",
   customer_firstname: "ganesh",
   customer_group_id: 1,
   customer_id: 118436,
   customer_is_guest: 0,
   customer_lastname: "takate",
   customer_note_notify: 1,
   discount_amount: 0,
   discount_invoiced: 0,
   email_sent: 1,
   entity_id: 856,
   global_currency_code: "USD",
   grand_total: 67.95,
   discount_tax_compensation_amount: 0,
   discount_tax_compensation_invoiced: 0,
   increment_id: "123456789",
   is_virtual: 0,
   order_currency_code: "USD",
   protect_code: "c149756f33a5d32f3f24d620717c2a76",
   quote_id: 373918,
   remote_ip: "157.33.91.100",
   shipping_amount: 7.95,
   shipping_description: "UPS - Ground Shipping Table",
   shipping_discount_amount: 0,
   shipping_discount_tax_compensation_amount: 0,
   shipping_incl_tax: 7.95,
   shipping_invoiced: 7.95,
   shipping_tax_amount: 0,
   state: "processing",
   status: "processing",
   store_currency_code: "USD",
   store_id: 1,
   store_name: "Main Website\nMain Website Store\nDefault Store View",
   store_to_base_rate: 0,
   store_to_order_rate: 0,
   subtotal: 60,
   subtotal_incl_tax: 60,
   subtotal_invoiced: 60,
   tax_amount: 0,
   tax_invoiced: 0,
   total_due: 0,
   total_invoiced: 67.95,
   total_item_count: 1,
   total_paid: 67.95,
   total_qty_ordered: 3,
   updated_at: "2021-01-18 10:46:55",
   weight: 1.5,
   x_forwarded_for: "157.33.91.100, 157.33.91.100, 10",
   items: [
      {
         amount_refunded: 0,
         base_amount_refunded: 0,
         base_cost: 10,
         base_discount_amount: 0,
         base_discount_invoiced: 0,
         base_discount_tax_compensation_amount: 0,
         base_discount_tax_compensation_invoiced: 0,
         base_original_price: 20,
         base_price: 20,
         base_price_incl_tax: 20,
         base_row_invoiced: 60,
         base_row_total: 60,
         base_row_total_incl_tax: 60,
         base_tax_amount: 0,
         base_tax_invoiced: 0,
         created_at: "2021-01-18 10:46:52",
         discount_amount: 0,
         discount_invoiced: 0,
         discount_percent: 0,
         free_shipping: 0,
         discount_tax_compensation_amount: 0,
         discount_tax_compensation_invoiced: 0,
         is_qty_decimal: 0,
         is_virtual: 0,
         item_id: 1297,
         name: "Gold Bordered Card",
         no_discount: 0,
         order_id: 856,
         original_price: 20,
         price: 20,
         price_incl_tax: 20,
         product_id: 14213,
         product_type: "simple",
         qty_canceled: 0,
         qty_invoiced: 3,
         qty_ordered: 3,
         qty_refunded: 0,
         qty_returned: 0,
         qty_shipped: 0,
         quote_item_id: 645121,
         row_invoiced: 60,
         row_total: 60,
         row_total_incl_tax: 60,
         row_weight: 1.5,
         sku: "CC32007",
         store_id: 1,
         tax_amount: 0,
         tax_invoiced: 0,
         tax_percent: 0,
         updated_at: "2021-01-18 10:46:52",
         weee_tax_applied: "[]",
         weight: 0.5,
         extension_attributes: {
            custom_attributes: [
               { attribute_code: "brand", value: "6986", label: "Crane" }
            ],
            category_links: [
               {
                  postion: "117",
                  category_id: "14",
                  category_name: "Notes & Cards"
               },
               {
                  postion: "32",
                  category_id: "15",
                  category_name: "All Boxed Stationery"
               },
               {
                  postion: "25",
                  category_id: "29",
                  category_name: "What's New"
               },
               {
                  postion: "27",
                  category_id: "467",
                  category_name: "What's Trending"
               }
            ]
         }
      }
   ],
   billing_address: {
      address_type: "billing",
      city: "new york",
      country_id: "US",
      email: "ganesh@gmail.com",
      entity_id: 1654,
      firstname: "ganesh",
      lastname: "takate",
      parent_id: 856,
      postcode: "15455",
      region: "Florida",
      region_code: "FL",
      region_id: 18,
      street: [
         "new york"
      ],
      telephone: "(256) 314-0796"
   },
   payment: {
      account_status: null,
      additional_information: [
         "Credit Card",
         "M",
         "M",
         "M",
         "02PYK6",
         "1000",
         "Approved",
         "xxxx-1111",
         "Visa"
      ],
      amount_authorized: 67.95,
      amount_ordered: 67.95,
      amount_paid: 67.95,
      base_amount_authorized: 67.95,
      base_amount_ordered: 67.95,
      base_amount_paid: 67.95,
      base_amount_paid_online: 67.95,
      base_shipping_amount: 7.95,
      base_shipping_captured: 7.95,
      cc_exp_month: "02",
      cc_exp_year: "2025",
      cc_last4: "1111",
      cc_trans_id: "apz8tyhx",
      cc_type: "VI",
      entity_id: 853,
      last_trans_id: "apz8tyhx",
      method: "braintree",
      parent_id: 856,
      shipping_amount: 7.95,
      shipping_captured: 7.95,
      extension_attributes: {
         vault_payment_token: {
            entity_id: 556,
            customer_id: 118436,
            public_hash: "7fdcd759145c45c2eb5f536d4aa75584ce037718096660791385db0c80b7629d",
            payment_method_code: "braintree",
            type: "card",
            created_at: "2021-01-18 10:46:52",
            expires_at: "2025-03-01 00:00:00",
            gateway_token: "6dfz64w",
            token_details: { type: "VI", maskedCC: "1111", expirationDate: "02/2025" },
            is_active: true,
            is_visible: false
         }
      }
   },
   status_histories: [
      {
         comment: "Captured amount of $67.95 online. Transaction ID: apz8tyhx",
         created_at: "2021-01-18 10:46:52",
         entity_id: 5494,
         entity_name: "invoice",
         is_customer_notified: null,
         is_visible_on_front: 0,
         parent_id: 856,
         status: "processing"
      }
   ],
   extension_attributes: {
      shipping_assignments: [
         {
            shipping: {
               address: {
                  address_type: "shipping",
                  city: "new york",
                  country_id: "US",
                  email: "ganesh@gmail.com",
                  entity_id: 1651,
                  firstname: "ganesh",
                  lastname: "takate",
                  parent_id: 856,
                  postcode: "15455",
                  region: "Florida",
                  region_code: "FL",
                  region_id: 18,
                  street: [
                     "new york"
                  ],
                  telephone: "(256) 314-0796"
               },
               method: "tablerate_bestway",
               total: {
                  base_shipping_amount: 7.95,
                  base_shipping_discount_amount: 0,
                  base_shipping_discount_tax_compensation_amnt: 0,
                  base_shipping_incl_tax: 7.95,
                  base_shipping_invoiced: 7.95,
                  base_shipping_tax_amount: 0,
                  shipping_amount: 7.95,
                  shipping_discount_amount: 0,
                  shipping_discount_tax_compensation_amount: 0,
                  shipping_incl_tax: 7.95,
                  shipping_invoiced: 7.95,
                  shipping_tax_amount: 0
               }
            },
            items: [
               {
                  amount_refunded: 0,
                  base_amount_refunded: 0,
                  base_cost: 10,
                  base_discount_amount: 0,
                  base_discount_invoiced: 0,
                  base_discount_tax_compensation_amount: 0,
                  base_discount_tax_compensation_invoiced: 0,
                  base_original_price: 20,
                  base_price: 20,
                  base_price_incl_tax: 20,
                  base_row_invoiced: 60,
                  base_row_total: 60,
                  base_row_total_incl_tax: 60,
                  base_tax_amount: 0,
                  base_tax_invoiced: 0,
                  created_at: "2021-01-18 10:46:52",
                  discount_amount: 0,
                  discount_invoiced: 0,
                  discount_percent: 0,
                  free_shipping: 0,
                  discount_tax_compensation_amount: 0,
                  discount_tax_compensation_invoiced: 0,
                  is_qty_decimal: 0,
                  is_virtual: 0,
                  item_id: 1297,
                  name: "Gold Bordered Card",
                  no_discount: 0,
                  order_id: 856,
                  original_price: 20,
                  price: 20,
                  price_incl_tax: 20,
                  product_id: 14213,
                  product_type: "simple",
                  qty_canceled: 0,
                  qty_invoiced: 3,
                  qty_ordered: 3,
                  qty_refunded: 0,
                  qty_returned: 0,
                  qty_shipped: 0,
                  quote_item_id: 645121,
                  row_invoiced: 60,
                  row_total: 60,
                  row_total_incl_tax: 60,
                  row_weight: 1.5,
                  sku: "CC32007",
                  store_id: 1,
                  tax_amount: 0,
                  tax_invoiced: 0,
                  tax_percent: 0,
                  updated_at: "2021-01-18 10:46:52",
                  weee_tax_applied: "[]",
                  weight: 0.5
               }
            ]
         }
      ],
      payment_additional_info: [
         {
            key: "method_title",
            value: "Credit Card"
         },
         {
            key: "avsPostalCodeResponseCode",
            value: "M"
         },
         {
            key: "avsStreetAddressResponseCode",
            value: "M"
         },
         {
            key: "cvvResponseCode",
            value: "M"
         },
         {
            key: "processorAuthorizationCode",
            value: "02PYK6"
         },
         {
            key: "processorResponseCode",
            value: "1000"
         },
         {
            key: "processorResponseText",
            value: "Approved"
         },
         {
            key: "cc_number",
            value: "xxxx-1111"
         },
         {
            key: "cc_type",
            value: "Visa"
         }
      ],
      gift_cards: [

      ],
      base_gift_cards_amount: 0,
      gift_cards_amount: 0,
      applied_taxes: [

      ],
      item_applied_taxes: [

      ],
      gw_base_price: "0.0000",
      gw_price: "0.0000",
      gw_items_base_price: "0.0000",
      gw_items_price: "0.0000",
      gw_card_base_price: "0.0000",
      gw_card_price: "0.0000"
   }
}

export const beginCheckoutParams = {
   baseRowTotal: "540.0000",
   customAttributes: {
      baMinSalesQty: "1",
      baQtyIncrements: "1",
      badge: "13",
      badgeEnabled: "1",
      borderColor: "78",
      brand: "6986",
      builderVersion: "1.0",
      categoryIds: ["14", "15", "29", "467"],
      color: "173",
      colorParent: "35",
      cost: "10.000000",
      cottonPaper: "892",
      countryOfManufacture: "US",
      description:
         "Reflecting our classic craftsmanship at its finest, each bordered card is made with precision and pride. Gold ink provides a striking pop of color.",
      designStyle: "7097",
      fulfillmentType: "25",
      giftMessageAvailable: "2",
      giftWrappingAvailable: "2",
      hasOptions: "0",
      iframeHeight: "0",
      image: "/e/v/everydayboxed-borderedcards-goldpearlwhite-main.jpg",
      imageLabel: "Gold Bordered Pearl White Card",
      isPersonalize: "0",
      isReturnable: "2",
      liner: "Envelope Lining: Gold Lustre",
      madeUsa: "893",
      manufacturer: "418",
      metaDescription:
         "Be inspired to write your family and friends with our Gold Bordered Correspondence Card. Shop more bordered notes and cards at Crane.",
      metaTitle: "Gold Bordered Correspondence Card | Crane Stationery",
      msrpDisplayActualPriceType: "0",
      optionsContainer: "container2",
      orientation: "492",
      pageLayout: "product-full-width",
      paperColor: "536",
      paperColorParent: "63",
      paperWeight: "887",
      personalized: "749",
      photoCard: "747",
      printingProcess: "875",
      processingTime: "1029",
      productBadge: "0",
      productDefType: "1407",
      productDimensions: "4 1/4 x 6 3/8",
      relatedProductBlock: "761",
      relatedProductBlock2: "691",
      requiredOptions: "0",
      smallImage: "/e/v/everydayboxed-borderedcards-goldpearlwhite-main.jpg",
      smallImageLabel: "Gold Bordered Pearl White Card",
      swatchImage: "no_selection",
      taxClassId: "2",
      thumbnail: "/e/v/everydayboxed-borderedcards-goldpearlwhite-main.jpg",
      thumbnailLabel: "Gold Bordered Pearl White Card",
      unitDescription: "10 cards / 10 lined envelopes",
      uom: "254",
      upc: "031619696506",
      urlKey: "gold-bordered-correspondence-card-cc32007",
   },
   itemId: "644916",
   name: "Gold Bordered Card",
   price: "20.0000",
   productType: "simple",
   qty: 27,
   quoteId: "373764",
   rowTotal: "540.0000",
   sku: "CC32007",
   stockDetails: {
      backorders: 1,
      isInStock: true,
      isQtyDecimal: false,
      itemId: "23057",
      manageStock: 1,
      minQty: 0,
      minSaleQty: 1,
      productId: 14213,
      qty: 62,
      qtyIncrements: 1,
      stockId: 1,
   },
}