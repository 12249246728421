<template>
  <div> 
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
export default {
  name: "CCError",
  components: {
  },
  data: () => ({
  }),
  computed:{
     userAuth: function() {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function() {
      return this.$store.state.guestInfo.guestQuoteId;
    },
     canonical: function() {
      return this.$route.meta.canonical;
    }, 
  },
   metaInfo() {
        return {
            link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ] 
        }
  },
   mounted() {
      if (this.userAuth) {
    if (
      this.$route.query.RetailerID &&
      this.$route.query.ItemNumber &&
      this.$route.query.Quantity &&
      this.$route.query.CCID
    ) {
     
    }
   }
   else
   {
     if (
      this.$route.query.RetailerID &&
      this.$route.query.ItemNumber &&
      this.$route.query.Quantity &&
      this.$route.query.CCID
    ) {
      
    }
   }
   }
   ,
  created(){

  }
};
</script>
