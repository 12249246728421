<template>
  <div>
    <v-row>
      <v-col cols="12" class="text-center" v-if="fetchInProgress">
        <v-progress-circular :size="40" color="primary" indeterminate></v-progress-circular>
      </v-col>
      <v-col cols="12" v-else-if="geoCodedAddress">
        <div class="error-box">
          <v-alert
            class="alert-box-outline body"
            type="success"
            density="compact"
            text
            dense
            v-if="geoCodedAddressFlag"
          >{{geoCodedAddress}}</v-alert>
          <v-alert
            class="alert-box-outline grey lighten-4 body" density="compact"
            dense
            v-if="!geoCodedAddressFlag"
          >{{geoCodedAddress}}</v-alert>
        </div>
        <div class="success-box py-3">
         <p class="body pb-3">
            If the address below is correct then you don’t need to do anything. To change your address,
            <v-hover v-slot:default="{ isHovering, props }">
              <router-link
                to="#"
                class="text-decoration-none"
                target="_self"
                :class="isHovering ? 'primary--text':'black--text'"
                  v-bind="props"
              >Click here</router-link>
            </v-hover>
          </p>
              <v-radio-group mandatory v-model="userSelectedAddress" hide-details v-if="suggestedAddress" color="primary">
                <v-row no-gutters>
                  <v-col cols="12" md="6" class="pr-md-3 cursor-pointer" @click="setSelectAddress(suggestedAddress,'suggestedAddress')">
                    <v-alert class="alert-box-outline grey lighten-4" density="compact">
                    <v-radio
                      value="suggestedAddress"
                      label="Suggested Address"
                      class="black--text body-font"
                    >
                    </v-radio>
                      <div class="mb-2 ml-8 body-font">
                        <p class="mb-0">{{suggestedAddress.firstname}} {{suggestedAddress.lastname}}</p>
                        <p class="mb-0">{{suggestedAddress.street[0]}} {{suggestedAddress.street[1]}}</p>
                        <p class="mb-0">                 
                        {{suggestedAddress.city}}, {{suggestedAddress.region}} {{suggestedAddress.postcode}}
                        </p>
                      </div>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="6" @click="setSelectAddress(originalAddress,'originalAddress')" class="cursor-pointer">
                    <v-alert class="alert-box-outline grey lighten-4" density="compact">
                    <v-radio
                      value="originalAddress"
                      class="black--text body-font"
                      label="Original Address"
                    >
                    </v-radio>
                      <div class="mb-2 ml-8 body-font">
                        <p class="mb-0">{{originalAddress.firstname}} {{originalAddress.lastname}}</p>
                        <p class="mb-0">{{originalAddress.street[0]}} {{originalAddress.street[1]}}</p>
                        <p class="mb-0">                 
                        {{originalAddress.city}}, <span v-if="stateName.length" class="body">{{stateName[0].label}} - </span> {{originalAddress.postcode}}
                        </p>
                      </div>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-radio-group>
            <v-row no-gutters v-else>
              <v-col>
                <v-alert class="alert-box-outline grey lighten-4" density="compact">
                <p class="mb-2 body">{{verifyAddress.firstname}} {{verifyAddress.lastname}}</p>
                <p class="mb-2 body">{{verifyAddress.streetNoOne}}</p>
                <p class="mb-2 body">{{verifyAddress.streetNoTwo}}</p>
                <p class="mb-2 body">
                  {{verifyAddress.city}},
                  <span v-if="stateName.length" class="body">{{stateName[0].label}} - </span>
                  {{verifyAddress.postcode}}
                </p>
                 </v-alert>
              </v-col>
            </v-row>
        </div>
      </v-col>
      <v-col cols="12" class="py-0" v-else>
        <v-alert type="error" class="my-2" text>No Address is available</v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<script> 
import { fetchStates } from "@/services/util.service";
export default {
  name: "VerifyAddressComponent",
  props: ["geoCodedAddress","geoCodedAddressFlag","validAddresses"],
  data: () => ({
    stateName: [],
    userSelectedAddress: "",
  }),
  components: {},
  computed: {
    userAuth: function() {
      return this.$store.state.auth.userAuth;
    },
    suggestedAddress: function(){
      if(this.validAddresses&&this.validAddresses.valid_address)
        return this.validAddresses.valid_address;
      return "";
    },
    originalAddress: function(){
      if(this.validAddresses&&this.validAddresses.original_address)
        return this.validAddresses.original_address;
      return "";
    },
    verifyAddress: function() {
      if (this.userAuth) {
        return this.$store.state.accountOne.address;
      } else {
        return this.$store.state.guestInfo.address;
      }
    },
    fetchInProgress() {
      if (this.userAuth) {
        return this.$store.state.accountOne.inProgress;
      } else {
        return this.$store.state.guestInfo.fetchInProgress;
      }
    },
    countryAndStatesList() {
      return this.$store.state.accountOne.allCountries;
    },
    allCountries: function() {
      return this.$store.state.accountOne.allCountries.map(countries => {
        return {
          value: countries.id,
          label: countries.fullNameEnglish
        };
      });
    },
    userSelectedState: function() {
      if (this.userAuth) {
        return this.$store.state.accountOne.address.regionId;
      } else {
        return this.$store.state.guestInfo.address.regionId;
      }
    },
  },
  watch: {
    userSelectedState: function() {
      this.stateNameList = fetchStates(this.verifyAddress.countryId,this.countryAndStatesList);
      this.stateName = this.stateNameList.filter(obj => {
          if (obj.value == this.userSelectedState) return obj;
        });
    },
  },
  methods:{
    async setSelectAddress(address, selectedAddress){
      this.userSelectedAddress = selectedAddress;
      if(this.userAuth){
        await this.$store.dispatch(
          "accountOne/setUserAddress",
          address
        );
     
      }else{
        this.$store.dispatch(
          "guestInfo/setUserAddress",
          address
        );
      }
    }
  },
  created() {
    if(this.userSelectedState){
        this.stateNameList = fetchStates(this.verifyAddress.countryId,this.countryAndStatesList);
        this.stateName = this.stateNameList.filter(obj => {
            if (obj.value == this.userSelectedState) return obj;
          });
      }
    if(this.suggestedAddress){
      this.setSelectAddress(this.suggestedAddress, 'suggestedAddress');
    }
  }
};
</script>