<template>
  <div class="white d-flex flex-column page-height">
    <v-container class="mt-12 mb-auto">
      <h1 class="my-6 font-weight-medium">Oops!</h1>
      <h3
        class="font-weight-light"
      >Sorry, we can't find that page. It might be an old link or maybe it moved</h3>
    </v-container>
    <FooterComponent />
  </div>
</template>
<script>



const baseUrl = import.meta.env.VITE_BASE_URL;
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
export default {
  name: "RouteNotFound",
   data() {
    return {
    };
  },
  components: {
    FooterComponent
  },
  watch: {},
  computed: {
     canonical: function() {
      return this.$route.meta.canonical;
    },
  },
   metaInfo() {
        return {
             link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ]
        }
  },
 
};
</script>