<template>
  <div>
    <v-col class="pl-sm-10 pt-0">
      <form id="paymentForm" method="POST">
        <input type="hidden" name="dataValue" id="dataValue" />
        <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
        <button
          type="button"
          class="AcceptUI"
          id="payButton"
          data-billingAddressOptions='{"show":false, "required":false}'
          :data-apiLoginID="apiLoginID"
          :data-clientKey="clientKey"
          data-acceptUIFormBtnTxt="Submit"
          data-acceptUIFormHeaderTxt="Card Information"
          data-paymentOptions='{"showCreditCard": true, "showBankAccount": false,"showPaypal": true}'
          data-responseHandler="responseHandler"
        >
          <v-btn type="button" color="primary" class="mt-5"> Pay </v-btn>
        </button>
      </form>
    </v-col>
    <v-dialog v-model="acceptjsError" persistent width="800px" height="auto">
      <v-card tile>
        <v-card-title>
          <v-row no-gutters>
            <h2 class="mx-2 my-4 fontcolor-black-light text-wrap">
              There was an error initiating your payment.
            </h2>
          </v-row>
        </v-card-title>
        <hr class="mx-6" />
        <v-card-text class="pb-1 pb-sm-3 px-4">
          <v-row no-gutters class="mx-0 align-center text-subtitle-1">
            <v-col cols="1" class="ma-0 pa-0  text-center">
              <v-icon color="error" size="x-large" :icon="mdiAlert"
            /></v-col>
            <v-col cols="11" class="pa-4 pa-sm-0">
              <div>
               We are sorry for the inconvenience. </div>
                <div>This is most of the time caused
                by your Browser cache holding an obsolete resource.</div>
                <div> Please clear
                your browser cache. Complete instructions can be found here:
                <a
                  target="_blank"
                  href="https://www.wikihow.com/Clear-Your-Browser's-Cache"
                  >https://www.wikihow.com/Clear-Your-Browser's-Cache</a
                >
               <div> After clearing the browser cache please reload this page and try
                again.</div>
              </div>
              </v-col
            >
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-5">
          <v-row no-gutters>
            <v-col cols="12">
              <v-hover v-slot="{ isHovering, props }">
                <v-btn
                  rounded="0"
                  class="text-uppercase float-right body-font ma-2 button-background"
                  :variant="!isHovering ? 'flat' : 'outlined'"
                  :color="hover ? 'white' : 'error'"
                  :class="hover ? 'error--text' : 'white--text'"
                  tile
                  size="large"
                  depressed
                  v-bind="props"
                  @click="closeErrorModal()"
                  >OK</v-btn
                >
              </v-hover>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
  </div>
</template>
<script>
const apiLoginID = import.meta.env.VITE_API_LOGIN_ID;
const clientKey = import.meta.env.VITE_CLIENT_KEY;
const acceptUIJsUrl = import.meta.env.VITE_ACCEPT_UI_JS_URL;
import { mdiAlert } from "@mdi/js";

import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "layout/getField",
  mutationType: "layout/updateField",
});

export default {
  name: "CardPayment",
  data() {
    return {
      mdiAlert,
      apiLoginID,
      clientKey,
      snackbar: {
        show: false,
        message: null,
        color: null,
        timeout: 0,
      },
      acceptjsError: false,
    };
  },
  components: { SnackbarComponent },
  computed: {
    ...mapFields(["paymentCount"]),
  },
  methods: {
    sendToDataValue(value) {
      this.$emit("submitCardPayment", value);
    },
    closeErrorModal() {
      this.acceptjsError = false;
    },
  },
  created() {
    this.paymentCount = 1;

    let jsElm = document.createElement("script");
    jsElm.type = "text/javascript";
    jsElm.src = acceptUIJsUrl;
    document.body.appendChild(jsElm);

    let vm = this;

    window.responseHandler = function (response) {
      if (vm.paymentCount == 1) {
        vm.paymentCount = vm.paymentCount + 1;
      } else {
        return;
      }
      if (response.messages.resultCode === "Error") {
        vm.paymentCount = 1;
        var i = 0;
        while (i < response.messages.message.length) {
          if (response.messages.message[i].code == "E_WC_03") {
            vm.acceptjsError = true;
          } else {
            vm.snackbar = {
              message: response.messages.message[i].text,
              color: "error",
              show: true,
              timeout: 5000,
            };
          }

          console.log(
            response.messages.message[i].code +
              ": " +
              response.messages.message[i].text
          );
          i = i + 1;
        }
      } else {
        vm.sendToDataValue(response.opaqueData);
      }
    };
  },
};
</script>
