<template>
  <div>
  </div>
</template>
<script> 
export default {
  name: "popupRedirect",
  components: {},
  data: () => ({
    urlPath: 'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=',
  }),
  computed: {
    param: function () {
      return this.$route.query;
    },
  },


  created() {
   
    let track = this.param.track;
    
    if (track)
      this.urlPath = this.urlPath + track;
 

    window.location.replace(this.urlPath);
    
  }
};
</script>
