<template>
  <div class="homepage-overflow-x-hidden white">
    
    <div class="homepage-overflow-x-hidden white" v-if="homePageContent.length">
      <div v-html="getHomePageContent()"></div>
    </div>

    <PostScriptBlog />
  </div>
</template>
<script>
import { goToCategoryPageRouting, addPictureTagInHtmlCode } from "@/services/util.service";
import PostScriptBlog from "@/components/crane/home/PostScriptBlog.vue";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;

export default {
  name: "HomeComponent",
  components: {
    PostScriptBlog,
  },
  computed: {
    homePageContent: function () {
      return this.$store.state.layout.pageContent;
    },
    imgHeadTextFirst: function () {
      return "Downton Abbey stationery".replace(/(\S+\s*){1,2}/g, "$&</br>");
    },
    imgHeadTextSecond: function () {
      return "Vera Wang Papers".replace(/(\S+\s*){1,2}/g, "$&</br>");
    },
    blockOne() {
      return this.$store.state.block.footerBlock;
    },
    callOutsBlock: function () {
      return this.$store.state.layout.cmsBlockContent;
    },
    categoryDetailsList: function () {
      return this.$store.state.headerCategoryList.categoryDetails;
    },
   
  },
  data: () => ({
    categoryId: 10,
    calloutsblockId: 9,
    pageUrlKey: "home-page-content",
    variants: [1366,768,360],
    mediaImageUrl
  }),
  methods: {
    getHomePageContent() {
      if (this.homePageContent.length) {
        let data = this.homePageContent.filter((element) => {
          return element.identifier === "home-page-content";
        });
        if (data.length > 0) {
          let path = data[0].content;
          var div = document.createElement("div");
          div.innerHTML = path;
          let imgSrcUrls = div.getElementsByTagName("img");
          for (let i = 0; i < imgSrcUrls.length; i++) {
            imgSrcUrls[i].parentElement.innerHTML = addPictureTagInHtmlCode({currentImageTag: imgSrcUrls[i], variants: this.variants, mediaImageUrl })
          }
          return div.innerHTML;
        }
      }
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PATH_URL;
    },
    getBlockContent() {
      if (this.callOutsBlock.length) {
        let data = this.callOutsBlock.filter((element) => {
          return element.id === this.calloutsblockId;
        });
        let path = data[0].content;
        var div = document.createElement("div");
        div.innerHTML = path;
        let imgSrcUrls = div.getElementsByTagName("img");
        for (let i = 0; i < imgSrcUrls.length; i++) {
          let urlValue = imgSrcUrls[i].getAttribute("src");
          if (urlValue) {
            imgSrcUrls[i].setAttribute(
              "src",

              this.imageBasePathUrl() +
                "/media/" +
                imgSrcUrls[i].src
                  .replace(window.location.href, "")
                  .replace(window.location.origin, "")
                  .replace(/%22%7D%7D/g, "")
                  .replace(/%7B%7B/g, "")
                  .replace(/%20url=%22/g, "")
                  .replace("media", "")
            );
          }
        }
        return div.innerHTML;
      }
    },
    routeToPage(pageName) {
      if (pageName == "CategoryPage") {
        let vm = this;
        goToCategoryPageRouting(vm, { n: "Stationery", id: 10 });
      }
    },
    goToPageRoutePage(pageName) {
      this.$router
        .push({
          name: pageName,
        })
        .catch((error) => {
          error;
        });
    },
    initialLink() {
      return import.meta.env.VITE_HOME_PAGE_URL;
    },
   
    goToLink(pageLink) {
      window.location.replace(pageLink);

    },
  },
  mounted() {
    window.onload = function () {
      if (document.getElementById("imgTarget")) {
        var x = document.getElementById("imgTarget").offsetWidth;
        document.getElementById("imgTarget").style.height = x + "px";
      }
    };
    window.onresize = function () {
      if (document.getElementById("imgTarget")) {
        var x = document.getElementById("imgTarget").offsetWidth;
        document.getElementById("imgTarget").style.height = x + "px";
      }
    };
  },
};
</script>
<style  lang="scss">
#imgTarget {
  display: inline-block;
}
.home-page-hero-image {
  height: 62.5%;
  width: 100%;
}
.home-page-mobile-hero-image {
  width: 100%;
}
.home-page-top-margin-56 {
  margin-top: 56px;
}
.home-page-link-block {
  display: contents !important;
}
.home-page-hero-button {
  border: thin solid #fff !important;
  max-width: 212.56px;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #fff;
  opacity: 0.9;
  color: #404040;
  font-family: "Whyte-Book", sans-serif;
  letter-spacing: 1.42857px;
}
.home-page-hero-button:hover {
  border: thin solid #2c50da !important;
  max-width: 212.56px;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #2c50da;
  color: #ffffff;
  opacity: 0.9;
  font-family: "Whyte-Book", sans-serif;
  letter-spacing: 1.42857px;
}
.home-page-mobile-hero-text {
  color: #404040 !important;
}
.homepage-business-block-image {
  max-height: auto;
  min-height: 350px;
  width: 100%;
}
.homepage-featured-img-height-500 {
  height: auto;
  max-width: 100%;
}
.home-page-hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.homepage-max-width-600 {
  max-width: 600px;
}
.home-page-col-12 {
  width: 100%;
  display: flex;
  height: 100%;
}
.home-page-row {
  display: flex;
  height: 100%;
 
}
.homepage-font-color-FFC3D7 {
  color: #ffc3d7;
}
.homepage-background-color-eb7525 {
  background: #eb7525;
}
.homepage-background-color-f3f2f2 {
  background: #f3f2f2;
}
.home-page-col-6 {
  width: 50%;
  display: block;
 
}
.home-page-craft-col-6 {
  width: 50%;
  display: grid;

}
.home-page-explore-col-8 {
  width: 66.66%;
  display: grid;
}
.home-page-explore-col-4 {
  width: 33.33%;
  display: grid;
 
}
.home-page-craft-link {
  color: #2d5d84 !important;
  & .home-page-icon {
    background-image: url("@/assets/strong-blue-arrow.png");
    max-width: 22px;
    height: 14px;
    background-position: right center;
    background-size: contain;
  }
}
.home-page-craft-link:hover {
  color: #ccb7ff !important;
  & .home-page-icon {
    background-image: url("@/assets/hover-arrow.png");
    max-width: 22px;
    height: 14px;
    background-position: right center;
    background-size: contain;
  }
}
.home-page-white-link {
  color: #ffffff !important;
  & .home-page-icon {
    background-image: url("@/assets/white-arrow.png");
    max-width: 22px;
    height: 14px;
    background-position: right center;
    background-size: contain;
  }
}
.home-page-white-link:hover {
  color: #ccb7ff !important;
  & .home-page-icon {
    background-image: url("@/assets/hover-arrow.png");
    max-width: 22px;
    height: 14px;
    background-position: right center;
    background-size: contain;
  }
}
.home-page-link {
  color: #2c50da !important;
  & .home-page-icon {
    background-image: url("@/assets/blue-arrow.png");
    max-width: 22px;
    height: 13px;
    background-position: right center;
    background-size: contain;
  }
}
.home-page-link:hover {
  color: #ccb7ff !important;
  & .home-page-icon {
    background-image: url("@/assets/hover-arrow.png");
    max-width: 22px;
    height: 13px;
    background-position: right center;
    background-size: contain;
  }
}
.home-page-image {
  width: 100%;
  height: auto;
}
.homepage-image-card .v-ripple__container {
  opacity: 0 !important;
}

.homepage-image-card.v-card--link:focus:before {
  opacity: 0 !important;
}
.callouts-container {
  display: flex;
  justify-content: center;
}
.callout img {
  width: -webkit-fill-available;
  width: 100%;
  height: auto;
}

.col-right img {
  width: -webkit-fill-available;
  width: 100%;
  height: auto;
}
.col-left {
  width: 40%;
  display: block;
  padding-right: 10px;
}
.col-right {
  width: 60%;
  display: grid;
  gap: 10px;
  padding-left: 10px;
}
.homepage-background-color-strongblue {
  background: #2d5d84;
}
@media screen and (max-width: 600px) {
  .homepage-featured-img-height-500 {
    height: auto;
    width: 100%;
  }
  .home-page-col-12 {
    width: 100%;
    display: block;
    height: 100%;
  }
  .home-page-craft-col-6 {
    width: 100%;
    display: flex;
   
  }
  .home-page-explore-col-8 {
    width: 100%;
    display: block;
  }
  .home-page-explore-col-4 {
    width: 100%;
    display: block;
   
  }
  .home-page-col-6 {
    width: 100%;
    display: block;
  }
  .home-page-row {
    display: block;
    height: 100%;
    width: 100%;
  }
  .callouts-container {
    display: block;
    justify-content: center;
    margin: 0px;
  }
  .col-left {
    width: 100%;
    padding-right: 0px;
  }
  .col-right {
    width: 100%;
    padding-top: 30px;
    padding-left: 0px;
    gap: 30px;
  }
}
</style>
<style src="./HomeComponent.scss" lang="scss" scoped /> 