import { wrapRequest } from './nav';


const assetNodeslsUrl = import.meta.env.VITE_ASSETS_NODESLS_NEW_URL;

export const doVerifyRecaptcha = wrapRequest(({token}) => {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        },
    };

    return fetch(`${assetNodeslsUrl}/recaptcha-verify?token=`+token
    , requestOptions)
});

