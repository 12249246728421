<template>
  <div class="white">
    <v-container class="my-10" ref="divOne">
      <h1 class="font-weight-medium">Privacy & Security</h1>
      <h3 class="font-weight-medium my-6">Notice Of Updated Policy</h3>
      <p
        class="fontcolor-black-light"
      >We have updated our privacy policy, effective September 30, 2015, to provide more details about our information privacy practices. Specifically, the updates concern how your personal information may be shared if required by law, or if Crane & Co., Inc. sells all or part of its business, including Crane.com or Crane Stationery, as well as our policies regarding links to other websites, and use of our site by children and non-U.S. users.</p>
      <h3 class="font-weight-medium my-6">Privacy And Security</h3>
      <p
        class="my-6 fontcolor-black-light"
      >Thank you for visiting Crane.com. Your privacy is important to us and we are committed to the proper use and protection of your personal information. This policy explains our online information practices and the choices you can make about the way your information is collected and used on our site. It also demonstrates our firm commitment to security and privacy. The following discloses our information gathering and dissemination practices for Crane.com.</p>

      <h3 class="font-weight-medium my-6">The Information We Collect</h3>
      <p
        class="my-6 fontcolor-black-light"
      >Crane.com may collect personal information that you provide to us for the purposes of facilitating transactions, account registration and to support your customer relationship with Crane.com. This information may include name, payment information, billing and shipping addresses, email, phone number and various customer preferences. Personally identifiable information is collected when you place an order, set up an account or subscribe to the Crane.com email newsletter. We may also collect personal information about you from other third parties, such as demographic firms, to make our future marketing efforts more efficient. We will not trade, rent, or sell your personal information, without your prior consent, except as otherwise set forth in this policy.</p>

      <h3 class="font-weight-medium my-6">How We Use The Information</h3>
      <p
        class="my-6 fontcolor-black-light"
      >We use the personal information that we collect for internal purposes to conduct our business and to ensure you are provided with the highest level of service possible. We use the information to meet your requests and to tell you about other products and services offered by Crane.com. Service providers and third parties who perform services on our behalf, are contractually restricted from using your information in any manner other than in helping us provide you with the products available from Crane.com. If Crane & Co., Inc. sells all or part of its business, or makes a sale or transfer of assets, or is otherwise involved in a merger or business transfer, or in the unlikely event of bankruptcy, we may transfer your information to one or more third parties as part of that transaction. We may also disclose your personal information when you ask us to do so or when it is required by law. For example, we may disclose your personal information to law enforcement officers, courts, government agencies, other companies, lawyers or agents, pursuant to warrants, criminal or civil subpoenas or other government or court orders. We may also publicly display personal information that you post in online blogs, bulletin boards, chat rooms, news groups and other public forums.</p>

      <h3 class="font-weight-medium my-6">Cookies And Web Beacons</h3>
      <p
        class="my-6 fontcolor-black-light"
      >A cookie is a small data file generated by our website that is stored on your computer. It makes it easier for you to use our site by keeping track of your Shopper ID so that you can retrieve your shopping basket on a return visit. The use of cookies is a preference that can be enabled and disabled on your web browser. Cookies, by themselves, do not tell us your email address or other personally identifiable information unless you choose to provide this information to us by, for example, registering for an account on our site. We use cookies and web beacons to enhance your experience with our site. They distinguish you from other visitors, track your IP address and usage of our site, and identify the name of the website from which you linked to Crane.com. We also use cookies to understand site usage and to facilitate the ordering process for many of our products and services. We may use cookies to personalize your experience at Crane.com, such as recognizing you by name when you return, saving your password in password-protected areas and enabling you to use shopping carts on the site.</p>

      <h3 class="font-weight-medium my-6">Links To Other Sites</h3>
      <p
        class="my-6 fontcolor-black-light"
      >You may encounter links to third party websites when using this site. These links may have been placed on the site by Crane.com, by an advertiser, through the use of a third party application, or by some other manner. We make no guarantees regarding the content, reliability, correctness or safety of any third party website accessed through links appearing on this site. We do not control the content of these sites and do not supervise links posted by users, advertisers or third party applications. These sites may have different privacy policies or no privacy policy. If you access any of these links, you do so at your own risk.</p>
      <h3 class="font-weight-medium my-6">Email Newsletter And Promotional Emails</h3>
      <p
        class="my-6 fontcolor-black-light"
      >Transactional emails, those containing order confirmation information or shipping details which facilitate an agreed-upon transaction, will be sent at the completion of each purchase. You cannot opt out of receiving these emails. Due to their transactional nature, these emails are exempt from most provisions of the CAN-SPAM Act. One may unsubscribe from promotional emails at any time. Those with accounts on Crane.com may do so by editing account preferences to indicate that you do not wish to receive emails of this nature. Those who do not have accounts may do so by visiting the email subscription page and entering the email address which you wish to opt out of future mailings. Alternatively, there is an unsubscribe link at the bottom of every promotional email in accordance with the CAN-SPAM Act.</p>

      <h3 class="font-weight-medium my-6">Your Choices</h3>
      <p
        class="my-6 fontcolor-black-light"
      >You have the ability to set your information sharing preferences and you can change how we communicate with you. You can stop receiving promotional emails from us and you can update the contact information you provide. By using this site, you hereby agree to the terms and conditions of this privacy policy. If you do not agree to this policy, please notify us as described below, and do not continue to use this site. We reserve the right, at our discretion, to change, modify, add, or remove elements of this policy at any time. Please review this page periodically for any changes. Your continued use of this site following the posting of any changes to this policy shall mean that you have accepted any such changes. To let us know that you do not agree to this policy and wish to have your personal information purged from our systems, please notify us by calling 1.800.268.2281, emailing customerservice@crane.com, or writing to Crane & Co., Inc. Privacy, 1466 Curran Highway, North Adams, MA 01247.</p>

      <h3 class="font-weight-medium my-6">Our Commitment To Security</h3>
      <p
        class="my-6 fontcolor-black-light"
      >We maintain physical, electronic and procedural safeguards to protect the confidentiality and security of personal information transmitted to us through Crane.com. To guard your information, our website uses Secure Sockets Layer (SSL). SSL encrypts your credit card number, name and address so only Crane.com is able to decode your information. We also use the secure protocol HTTPs and we use GoDaddy for our certificates. However, we cannot guarantee that third parties will not overcome any security measures and gain access to your information for unauthorized purposes. By using this site, you accept the risk that compromise of and unauthorized access to your information is possible.</p>

      <h3 class="font-weight-medium my-6">Children Under Age 13</h3>
      <p
        class="my-6 fontcolor-black-light"
      >Crane.com is not intended for use by children under 13 years of age, and we do not intentionally or knowingly collect personal information from anyone under 13. No one under the age of 13 is allowed to transmit personal information via this site. Crane.com recommends that anyone between the ages of 13 and 18 get permission from his or her parents or guardians before transmitting any personal information to or through this site.</p>
      <h3 class="font-weight-medium my-6">Notice To Non-U.S. Users</h3>
      <p
        class="my-6 fontcolor-black-light"
      >If you are a non-U.S. visitor to our site, by visiting our site and providing us with data, you acknowledge and agree that your personal information is subject to the terms of this privacy policy. In addition, such data may be stored on servers located in the United States, which may have less stringent privacy practices than those your own resident jurisdiction. By providing us with your data, you consent to transfer of such data.</p>
      <h3 class="font-weight-medium my-6">Future Policy Changes</h3>
      <p
        class="my-6 fontcolor-black-light"
      >If we decide to change our privacy policy in whole or in part in the future, we will inform you by posting a notice on our website. Any changes to this policy will go into effect on the date posted in the notice.</p>
    </v-container>
    <FooterComponent />
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import * as easings from "vuetify/lib/services/goto/easing-patterns";
import FooterComponent from "@/components/layout/footer/FooterComponent.vue"; 
export default {
  name: "PrivacyPolicy",
  data() {
    return {
      easings: Object.keys(easings)
    };
  },
  components: {
    FooterComponent
  },
  watch: {},
  computed: {
    canonical: function() {
      return this.$route.meta.canonical;
    }
  },
   metaInfo() {
        return {
             link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ] 
        }
  },
  methods: {
      pageScrollUp() {
        const data=  this.$refs.divOne
        if(data){
          this.$smoothScroll({
          scrollTo: data.$el.scrollTop, 
          duration: 300, 
          easingFunction: "easeInOutCubic",
          updateHistory: false
        })
        }
    }
  },
  mounted() {
        this.pageScrollUp();
  },
  
};
</script>