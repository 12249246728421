<template>
  <v-container fill-height >
  <v-row align="center"
      justify="center">
      <v-col>
        <div data-ltk-prefcenter="CraneStationerycom"></div>
      </v-col>
  </v-row>
</v-container>
</template>
<script>
 
export default {
  name: "PreferenceCenterPage",
  data() {
    return {
     
    };
  },
   
  
};
</script>