<template>
  <div>
    <v-overlay v-model="AddingToBagProgress" :opacity="0.8" contained class="align-center justify-center">
      <v-container>
        <v-row>
          <v-col cols="12" class="d-flex justify-center align-center">
            <div class="h3 white--text mt-10">Adding To Bag...</div>
          </v-col>
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-progress-circular
              indeterminate
              size="80"
              color="white"
              align="center"
              justify="center"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
import {googleAanalytics} from "@/services/util.service";
export default {
  name: "CCAction",
  components: { SnackbarComponent },
  data: () => ({
    AddingToBagProgress: false,
    snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0
    }
  }),
  computed: {
     categoryListIdWise: function() {
      return this.$store.state.headerCategoryList.categoryListIdWise;
    },
    userAuth: function() {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function() {
      return this.$store.state.guestInfo.guestQuoteId;
    },
    canonical: function() {
      return this.$route.meta.canonical;
    }, 
  },
   metaInfo() {
        return {
            link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ] 
        }
  },
  methods: {
    async userCartAction(sku, quantity, ccid, itemId, cartAction) {
      let vm =this;
      try {
        if (cartAction === "add") {
        let res=  await this.$store.dispatch("userCartProduct/addToCart", {
            sku: sku,
            qty: quantity,
            ccid: ccid,
            price: null,
            personalizeDetails: null
          });
         if(res)
         {
         let productDetails = await this.$store.dispatch("productOne/fetchProductDetails", sku);
         if(productDetails)
         {
            googleAanalytics(vm,res,productDetails,quantity,'add_to_cart' ,'Ecommerce',vm.categoryListIdWise);
         }
         }
          this.snackbar = {
            message: "Product Added Successfully",
            color: "success",
            show: true,
            timeout: 2000
          };
        } else {
          await this.$store.dispatch("userCartProduct/cartProductList");
          await this.$store.dispatch("userCartProduct/updateToCart", {
            sku: sku,
            qty: quantity,
            ccid: ccid,
            itemId: itemId,
            price: null,
            personalizeDetails: null
          });
          this.snackbar = {
            message: "Product Updated Successfully",
            color: "success",
            show: true,
            timeout: 3000
          };
        }
        this.AddingToBagProgress = false;
        this.$store.dispatch("userCartProduct/cartProductList");
        this.$store.dispatch("userCartProduct/cartDrawer", false);

        setTimeout(() => {
          this.$router.push({
            name: "CartProducts"
          });
        }, 2000);
      } catch (error) {
        this.$store.dispatch("userCartProduct/cartProductList");
        this.snackbar = {
          message: error.message,
          color: "error",
          show: true,
          timeout: 3000
        };
        setTimeout(() => {
          this.$router.push({
            name: "CartProducts"
          });
        }, 2000);
      }
    },

    async guestCartAction(sku, quantity, ccid, itemId, cartAction) {
      let vm=this;
      try {
        if (cartAction === "add") {
         let res= await this.$store.dispatch("cartProduct/addToCart", {
            sku: sku,
            qty: quantity,
            ccid: ccid,
            price: null,
            personalizeDetails: null
          });
           if(res)
         {
         let productDetails = await this.$store.dispatch("productOne/fetchProductDetails", sku);
         if(productDetails)
         {
            googleAanalytics(vm,res,productDetails,quantity,'add_to_cart' ,'Ecommerce',vm.categoryListIdWise);
         }
         }
          this.snackbar = {
            message: "Product Added Successfully",
            color: "success",
            show: true,
            timeout: 2000
          };
        } else {
          await this.$store.dispatch("cartProduct/updateToCart", {
            sku: sku,
            qty: quantity,
            ccid: ccid,
            itemId: itemId,
            price: null,
            personalizeDetails: null
          });
          this.snackbar = {
            message: "Product Updated Successfully",
            color: "success",
            show: true,
            timeout: 3000
          };
        }
        this.AddingToBagProgress = false;
        this.$store.dispatch("userCartProduct/cartDrawer", false);

        setTimeout(() => {
          this.$router.push({
            name: "CartProducts"
          });
        }, 2000);
      } catch (error) {
        this.snackbar = {
          message: error.message,
          color: "error",
          show: true,
          timeout: 3000
        };
        setTimeout(() => {
          this.$router.push({
            name: "CartProducts"
          });
        }, 2000);
      }
    }
  },
  async mounted() {
    if (this.$route.params.mode == "4") {
      this.AddingToBagProgress = true;
      let { ccid, quantity, itemNumber, itemId } = this.$route.params;
      if (ccid && quantity && itemNumber) {
        if (this.userAuth) {
          if (itemId) {
            await this.userCartAction(
              itemNumber,
              quantity,
              ccid,
              itemId,
              "update"
            );
          } else {
            await this.userCartAction(itemNumber, quantity, ccid, null, "add");
          }
        } else {
          if (itemId) {
            await this.guestCartAction(
              itemNumber,
              quantity,
              ccid,
              itemId,
              "update"
            );
          } else {
            await this.guestCartAction(itemNumber, quantity, ccid, null, "add");
          }
        }
      } else {
        this.AddingToBagProgress = false;
        this.snackbar = {
          message: "Required parameters are missing",
          color: "error",
          show: true,
          timeout: 2000
        };
        setTimeout(() => {
          this.$router.push({
            name: "CartProducts"
          });
        }, 2000);
      }
    } else {
      this.AddingToBagProgress = false;
      this.snackbar = {
        message: "This feature is currently unavailable",
        color: "error",
        show: true,
        timeout: 2000
      };
      setTimeout(() => {
        this.$router.push({
          name: "CartProducts"
        });
      }, 2000);
    }
  },
  created() {
    if (this.$route.params.mode == "4") {
      this.AddingToBagProgress = true;
    }
  }
};
</script>
