<template>
  <div v-if="pageName() !== 'HomePage' && pageName() !== 'CategoryPage' && pageName() !== 'Accessibility' && !checkStaticPage()">
    <v-card
      class="d-none d-sm-flex flex-column flex-start hero-container-image-card hero-container-image"
      :image="heroData.image"
      min-height="650"
      tile
      flat
      @click="goToPage('holiday','holiday-cards','holiday-greeting-cards',412)"
    >
       
      <div class="mx-auto align-self-center mt-auto mb-auto">
        <v-card flat color="rgba(0,0,0,0.0)" class="text-center">
          <v-card-text class="whyteTmin10-font text-uppercase pa-0 text-white pt-6 h1 ls-n10">{{heroData.title}}</v-card-text>
          <v-card-text
            class="white--text text-center fontsize-16 pa-0 mt-md-2 mt-lg-2 body-font px-10 mx-auto herocontainer-max-width-635 ls-n10"
            v-html="heroData.content"
          ></v-card-text>
          
          <v-hover v-slot:default="{ hover }" v-if="heroData.buttonText">
            <v-btn
              large
              :color="hover ? 'primary' : 'herocontainer-light-white'"
              :class="hover?'white--text':'fontcolor-404040'"
              class="mt-12 body hero-button ls-n10"
              tile
              depressed
              @click.native="goToPage('holiday','holiday-cards','holiday-greeting-cards',412)"
            >{{heroData.buttonText}}</v-btn>
          </v-hover>
        
        </v-card>
      </div>
    
    </v-card>
    <v-card flat class="d-sm-none mx-auto text-center pb-12 hero-container-image-card" @click="goToPage('holiday','holiday-cards','holiday-greeting-cards',412)" tile>
      <v-img :position="pageName() === 'StoreLocatore'?'right':'inherit'" class="text-white text-uppercase align-start hero-container-mobile-image" :src="heroData.mobileViewImage">
       
      </v-img>
      <v-card-text class="mt-7 pt-0 pa-6">
        <v-card-text class="text-uppercase text-center px-0 pa-0 h2 ls-n10">{{heroData.title}}</v-card-text>
        <v-card-text class="pa-0 mt-3 px-5 fontsize-16 body-font ls-n10" v-html="heroData.content"></v-card-text>
        <v-card-text class="text-center pa-0 mt-6">
          <v-hover v-slot:default="{ hover }" v-if="heroData.buttonText">
            <router-link
              :class="hover ? 'contentColor--text' : 'primary--text'"
              to
              @click.native="goToPage('holiday','holiday-cards','holiday-greeting-cards',412)"
              class="ls-n009 text-decoration-none body-font text-uppercase fontsize-16 d-flex flex-row align-center justify-center"
            >
              {{heroData.buttonText}}
            
               <div class="homepage-route-icon ml-3 pl-12" :class="hover?'home-right-hover-icon': 'home-right-icon'" ></div> 
            </router-link>
          </v-hover>
        </v-card-text>
      </v-card-text>
    </v-card>
  </div>
  <div v-else-if="checkStaticPage() &&  pageBackgroundColor() && pageName() !== 'Accessibility'">
    <v-row align="center" :class="rightBackgroundColor">
      <v-col cols="12" sm="6" md="6" :class="rightBackgroundColor" >
        <v-container>
          <v-row
            align="center"
            class="my-12 py-12 px-6 herocontainer-min-height-650 justify-center flex-column ma-6 ma-md-12 pa-md-12"
            no-gutters
          >
            <v-col cols="12" v-show="heroData.header">
              <h1
                class="text-center d-none d-sm-block fontsize-28 text-uppercase body-font ls-n028 line-height-36 font-weight-regular"
                :class="heroData.headerDescription ? 'pb-10': ''"
              >{{heroData.header}}</h1>
              <h1
                class="text-center d-sm-none fontsize-28 text-uppercase body-font ls-n028 line-height-36 font-weight-regular"
                :class="heroData.headerDescription ? 'pb-10': ''"
              >{{heroData.header}}</h1>
            </v-col>
            <v-col cols="12" v-show="heroData.headerDescription">
              <div
                class="text-center fontsize-16 px-lg-12 body-font ls-n016 line-height-18pt"
                v-html="heroData.headerDescription"
              ></div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" sm="6" md="6" :class="leftBackgroundColor">
        <v-container class="">
          <v-row
            align="center"
            class="ma-6 my-12 py-12 ma-md-12 pa-md-12 px-6 herocontainer-min-height-650 justify-center flex-column"
            no-gutters
          >
            <v-col cols="12" v-show="heroData.contentHeader">
              <h1
                class="fontsize-28 pb-10 px-lg-12 d-none d-sm-block text-uppercase body-font line-height-36 ls-n028 font-weight-regular"
              >{{heroData.contentHeader}}</h1>
              <h1
                class="fontsize-28 pb-7 ls-n028 line-height-36 px-lg-12 d-block d-sm-none text-uppercase body-font ls-n01 font-weight-regular"
              >{{heroData.contentHeader}}</h1>
            </v-col>
            <v-col cols="12" v-show="heroData.contentDescription">
              <div
                class="px-lg-12 ls-n016 fontsize-16 line-height-18pt body-font"
                v-html="heroData.contentDescription"
              ></div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script> 
import { createHelpers } from "vuex-map-fields";
import {
  customAttributeValue,
  getCategoryOne,
  goToCategoryPageRouting
} from "@/services/util.service";

const { mapFields } = createHelpers({
  getterType: "layout/getField",
  mutationType: "layout/updateField"
});

export default {
  name: "HeroContainer",
  props: ["heroData"],
  components: {},

  data: () => ({
    offsetTop: 0,
    showMenu: false,
    staticPages: [
      "AboutUs",
      "EtiquetteGuide",
      "PrintingProcesses",
      "OurPapers",
      "ShippingAndDelivery"
    ],
    rightBackgroundColor: null,
    leftBackgroundColor: null
  }),
  computed: {
    ...mapFields(["user", "user.userVisits"]),

    inProgress: function() {
      return this.$store.state.layout.inProgress;
    },
    heroContent() {
      return this.$store.state.layout.heroContent;
    },
    heroBlock: function() {
      return this.$store.state.block.heroBlock;
    },
     categoryDetailsList: function() {
      return this.$store.state.headerCategoryList.categoryDetails;
    }
  },
  methods: {
    initialLink() {
      return import.meta.env.VITE_HOME_PAGE_URL;
    },
    async goToPage(main,sub,child,categoryId) {
       let categoryOne = await getCategoryOne(
        this.categoryDetailsList,
        categoryId
      );
        let vm = this;
       goToCategoryPageRouting(vm, categoryOne);
    
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PATH_URL;
    },
    getBlockId() {
      if (this.pageName() == "FaqPage") {
        return 11;
      }
    },
   

    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValue(customAttributes, attributeCode);
    },
    getImageCustomAttributeValue(customAttributes, attributeCode) {
      let imagePath = customAttributeValue(customAttributes, attributeCode);
   
      if (imagePath == "") {
        return undefined;
      } else if (imagePath.includes("/media/catalog/category/")) {
        return this.imageBasePathUrl() + imagePath;
      } else if (!imagePath.includes("/media/catalog/category/")) {
        return this.imageBasePathUrl() + "/media/catalog/category/" + imagePath;
      } else {
        return this.heroData.image;
      }
    },
    pageName() {
      return this.$route.name;
    },
    checkStaticPage() {
      let currentPage = this.$route.name;
      if (this.staticPages.indexOf(currentPage) == -1) return false;
      else return true;
    },
    pageBackgroundColor() {
      let currentPage = this.$route.name;
     if (currentPage == "ShippingAndDeliveryCms") {
        return (
          (this.rightBackgroundColor = "primary fontcolor-mauve") &&
          (this.leftBackgroundColor = "background-mauve primary--text")
        );
      }
    },
    routeToPage(pageName) {
      if (pageName == "CategoryPage") {
        let vm = this;
        goToCategoryPageRouting(vm,{n:"Stationery",id:10});
      }
    }
  },
  
};
</script>
<style  src="./HeroContainer.scss" lang="scss">
</style>