<template>
  <v-container>
    <v-overlay v-model="cartProductsProgress" :opacity="0.2" contained class="align-center justify-center">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-progress-circular
              indeterminate
              size="80"
              color="primary"
              align="center"
              justify="center"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
    <div v-show="!cartProductsProgress">
      <v-row v-show="cartProductsItem.length == 0" class="pt-3">
        <v-col>
          <v-alert class="alert-box-outline" type="error" variant="tonal"  density="compact"
            >You have no items in your shopping cart.</v-alert
          >
          <p class="pt-4">
            Click
            <span
              class="mr-1 text-decoration-none fontcolor-black-light fontsize-16 guest-shipping-address-login"
            >
              <span @click="goToShopping()" class="cursor-pointer"
                >here</span
              > </span
            >to continue shopping.
          </p>
        </v-col>
      </v-row>
      <div v-show="cartProductsItem.length != 0">
        <v-row
          class="mt-12"
          :class="!isDesktop ? 'd-flex flex-row' : 'd-flex flex-column-reverse'"
        >
          <v-col cols="12" sm="12" md="8" class="pt-0 mt-0">
            <v-divider class="hidden-md-and-up"></v-divider>
            <v-col cols="12" class="mt-3">
              <p
                class="h3 pl-4 body-font text-uppercase text-primary"
                v-if="!cartProductsProgress"
              >
                Your Cart ( {{ cartProductsItem.length }} )
              </p>
            </v-col>
            <v-divider class="hidden-md-and-up"></v-divider>
            <v-row>
              <v-col cols="12" sm="11" md="11" class="mt-10">
                <CartProductList
                  :checkOut="chekoutFlag"
                  :cartItems="cartProductsItem"
                  :cartProductsProgress="cartProductsProgress"
                  :cartProductPage="cartProductPage"
                  @actionClose="showSnackBarMessage"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="4" class="pb-0 mb-0 mt-2">
            <v-row>
              <v-col cols="12">
                <p class="pb-0 mb-0 h2 body-font text-uppercase text-primary">
                  Summary
                </p>
                <div class="myDivider"></div>

              </v-col>
            </v-row>
            <v-row v-if="!cartTotalAmtProgress && cartProductTotalAmt" no-gutters>
              <v-col cols="12" class="py-6">
                <p class="float-left pb-0 mb-0">Subtotal</p>
                <p class="float-right pb-0 mb-0">
                  ${{ priceFormatter(cartProductTotalAmt.baseSubtotal) }}
                </p>
              </v-col>
              <v-col cols="12" class="pb-1" v-if="cartProductTotalAmt && cartProductTotalAmt.totalSegments &&  cartProductTotalAmt.totalSegments.length  && cartProductTotalAmt.totalSegments.find((x) => x.code=='discount')" >
                <p class="float-left pb-0 mb-0">
                 {{( cartProductTotalAmt.totalSegments.find((x) => x.code=='discount'))?.title}}
                </p>
                <p
                  class="float-right pb-0 mb-0"
                  v-if="cartProductTotalAmt.baseDiscountAmount"
                >
                  -${{
                    priceFormatter(
                      cartProductTotalAmt.baseDiscountAmount
                        .toString()
                        .substring(1)
                    )
                  }}
                </p>

              </v-col>
              <v-col cols="12" class="pb-6">
                <p class="float-left pb-0 mb-0">Shipping</p>
                <p class="float-right pb-0 mb-0">
                  ${{ priceFormatter(cartProductTotalAmt.baseShippingAmount) }}
                </p>
              </v-col>
              <v-col cols="12" class="pb-6">
                <p class="float-left pb-0 mb-0">Estimated Tax</p>
                <p class="float-right pb-0 mb-0">
                  ${{ priceFormatter(cartProductTotalAmt.taxAmount) }}
                </p>
              </v-col>

              <v-col cols="12" class="pb-6">
                <p class="float-left pb-0 mb-0">Estimated Total</p>
                <p class="float-right pb-0 mb-0">
                  ${{ priceFormatter(cartProductTotalAmt.baseGrandTotal) }}
                </p>
              </v-col>

              <v-col
                v-if="stockFlag"
                cols="12"
                align="center"
                class="px-10 my-6"
              >
                <v-hover v-slot="{ isHovering, props }">
                  <v-btn
                    :rounded="0"
                    size="large"
                    block
                    class="d-sm-none d-md-block cart-products-button"
                    :variant="isHovering? 'outlined': ''"
                    v-bind="props"
                    :class="isHovering ? 'text-primary bg-white' : 'text-white bg-primary'"
                    dark
                    depressed
                    @click="goToCheckout()"
                    >GO TO CHECKOUT</v-btn
                  >
                </v-hover>
                <v-hover v-slot="{ isHovering, props }">
                  <v-btn
                    :rounded="0"
                    size="large"
                    class="d-none d-sm-flex d-md-none px-12 cart-products-button"
                    :variant="isHovering? 'outlined': ''"
                    v-bind="props"
                    :class="isHovering ? 'text-primary bg-white' : 'text-white bg-primary'"
                    dark
                    depressed
                    @click="goToCheckout()"
                    >GO TO CHECKOUT</v-btn
                  >
                </v-hover>
              </v-col>

            </v-row>
            <v-row v-else>
              <v-col cols="12" class="d-flex justify-center align-center">
                <v-progress-circular
                  indeterminate
                  size="80"
                  color="primary"
                  align="center"
                  justify="center"
                ></v-progress-circular>
              </v-col>
            </v-row>

          <div  v-if="rewardsPoints" class="myDivider"></div>
           <v-row v-if="rewardsPoints" no-gutters>
              <v-col cols="12" class="mt-3 mb-3  ">
                You will earn <span class="text-primary text-underline">{{ rewardsPoints }} Reward</span>  Points with this Purchase.
              </v-col>

           </v-row>

         <div class="myDivider"></div>
            <v-row  class="mt-3" v-if="userAuth">
              <v-col cols="12" align="center" v-if="inProgressDiscountCode">
                <v-progress-circular
                  :size="60"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
              <v-col cols="12" class="pb-0 mb-0" v-else>

                <p class="text-primary  mb-6">Apply Discount Code</p>

                <v-form
                  ref="discountCodeMultipleForm"
                  v-model="validDiscountCode"
                  @submit.prevent="applyDiscountCodeMultiple"
                >
                   <v-row>
                    <v-col cols="8" class="pb-0 mb-0">
                      <v-text-field
                        type="text"
                        density="compact"
                        placeholder="Enter Discount Code"
                        v-model="customerDiscountTextCode"
                        variant="outlined"
                        class="text-black"
                        @blur.prevent="resetCustomerValidation"
                        :rules="requiredRules"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pb-0 mb-0">
                      <v-hover v-slot="{ isHovering, props }">
                        <v-btn
                          class=" text-uppercase fontsize-14 checkoutpage-button-height cart-products-button"
                          type="submit"
                          size="large"
                          density="comfortable"
                          depressed
                          :rounded="0"
                          :variant="isHovering? 'outlined': ''"
                          v-bind="props"
                          :class="isHovering ? 'text-primary bg-white' : 'text-white bg-primary'"
                          >APPLY</v-btn
                        >
                      </v-hover>
                    </v-col>
                  </v-row>

                  <v-row  v-for="(item, index) in customerDiscountCodeList"
                    :key="index">
                    <v-col cols="8" class="pb-0 mb-0">
                      <h3> {{item}}</h3>
                    </v-col>
                    <v-col cols="4" class="pb-0 mb-0">

                      <v-hover v-slot:default="{ isHovering, props }">
                        <v-btn
                          class="text-uppercase checkoutpage-button-height cart-products-button"
                          @click.prevent="removeDiscountMultipleCode(item)"
                             size="large"
                          density="compact"
                          :variant="isHovering ? 'outlined': ''"
                          v-bind="props"
                          :class="isHovering ? 'bgWhiteColor' : 'bgRedColor'"
                          depressed
                          rounded="0"
                          >CANCEL</v-btn
                        >
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
            <v-row  class="mt-3"  v-else>
              <v-col cols="12" align="center" v-if="inProgressDiscountGuestCode">
                <v-progress-circular
                  :size="60"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
              <v-col cols="12" class="pb-0 mb-0" v-else>
                <p class="text-primary  mb-6">Apply Discount Code</p>
                <v-form
                  ref="discountCodeForm"
                  v-model="validDiscountGuestCode"
                  @submit.prevent="applyDiscountGuestCode"
                >
                  <v-row>
                    <v-col cols="8" class="pb-0 mb-0">
                      <v-text-field
                        type="text"
                        density="compact"
                        placeholder="Enter Discount Code"
                        v-model="discountTextCode"
                        variant="outlined"
                        class="text-black"
                        @blur.prevent="resetGuestValidation"
                        :rules="requiredRules"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pb-0 mb-0">
                      <v-hover v-slot="{ isHovering, props }">
                        <v-btn
                          v-if="!discountGuestCode"
                          class=" text-uppercase fontsize-14 checkoutpage-button-height cart-products-button"
                          type="submit"
                          size="large"
                          density="comfortable"
                          depressed
                          :rounded="0"
                          :variant="isHovering? 'outlined': ''"
                          v-bind="props"
                          :class="isHovering ? 'text-primary bg-white' : 'text-white bg-primary'"
                          >APPLY</v-btn
                        >
                      </v-hover>
                      <v-hover v-slot:default="{ isHovering, props }">
                        <v-btn
                          v-if="discountGuestCode"
                          class="text-uppercase checkoutpage-button-height cart-products-button"
                          @click.prevent="removeDiscountGuestCode"
                          size="large"
                          density="compact"
                          :variant="isHovering ? 'outlined': ''"
                          v-bind="props"
                          :class="isHovering ? 'bgWhiteColor' : 'bgRedColor'"
                          depressed
                          rounded="0"
                          >CANCEL</v-btn
                        >
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
  </v-container>
</template>
<script>

const baseUrl = import.meta.env.VITE_BASE_URL;
import CartProductList from "@/components/crane/cart-product/CartProductList.vue";
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
import { getCookieByName } from "@/services/auth";
import { priceFormatter ,googleAanalyticsPromoCode } from "@/services/util.service";

const crGuestQuoteId = import.meta.env.VITE_GUEST_QUOTE_ID;

export default {
  name: "CartProducts",
  data: () => ({
    priceFormatter: priceFormatter,
    stockFlag: true,
    cartProductPage: true,
    errorMessage: false,
    estimatedShipping: "",
    blue: "primary",
    white: "white",
    dialog: false,
    chekoutFlag: true,
    discountTextCode: "",
    customerDiscountTextCode:'',
    validDiscountCode: true,
    validDiscountGuestCode: true,
    lazy: false,
    requiredRules: [(v) => !!v || "This field is required"],
    snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0,
    },
  }),
  components: {
    CartProductList,
    SnackbarComponent,
  },
  computed: {
    canonical: function () {
      return this.$route.meta.canonical;
    },
    isDesktop: function () {
      return this.$vuetify.display.smAndDown ? true : false;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function () {
      return getCookieByName(crGuestQuoteId);
    },
    cartProductsItem() {
      let cartList = [];
      if (this.userAuth) {
        cartList = this.$store.state.userCartProduct.cartProductList;
        if(cartList && cartList.length)
        {
          this.checkoutProductReward(cartList);

        }
        return cartList;
      } else {
        cartList = this.$store.state.cartProduct.cartProductList;
        if(cartList && cartList.length)
        {
          this.checkoutProductReward(cartList);

        }
        return cartList;
      }
    },
    cartProductTotalAmt() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.productTotalAmt;
      } else {
        return this.$store.state.cartProduct.productTotalAmt;
      }
    },
    cartTotalAmtProgress() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartTotalAmtProgress;
      } else {
        return this.$store.state.cartProduct.cartTotalAmtProgress;
      }
    },
    inProgressDiscountGuestCode() {
        return this.$store.state.cartProduct.fetchInProgress;

    },
    inProgressDiscountCode() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.fetchInProgress;
      }
    },
    cartProductsProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductsProgress;
      } else {
        return this.$store.state.cartProduct.cartProductsProgress;
      }
    },
    discountGuestCode() {
        return this.$store.state.cartProduct.discountCode;
    },

    customerDiscountCodeList() {
       return this.$store.state.userCartProduct.discountCodeList;
    },
    rewardsPoints: function () {
      return this.$store.state.productOne.rewardsPoints;
    },
  },
  metaInfo() {
    return {
        titleTemplate: "Shopping Cart",
        title: "Shopping Cart",
       keywords: "Magento, Varien, E-commerce",
       viewport : "width=device-width, initial-scale=1",
        robots : "INDEX,FOLLOW",
       'format-detection': "telephone=no",

      link: [
        {
          rel: "canonical",
          href: `${
            this.canonical ? window.location.href.split("?")[0] : baseUrl
          }`,
        },
      ],
    };
  },

  watch: {
    discountGuestCode() {
      this.discountTextCode = this.discountGuestCode;
    },
    cartProductsItem() {
      this.stockFlagHideShow();
      this.calledCriteoViewBasket();
    },
  },
  methods: {
     checkoutProductReward(cartList) {

      try{
      let cartListNew=[];
      for(let i=0 ; i < cartList.length ;i++)
      {
        if(cartList[i].productType !='giftcard')
        {
           cartListNew.push({
            price: cartList[i].price,
            product_id: cartList[i].sku,
            category: cartList[i].productType,
            quantity: cartList[i].qty,
          });
        }

      }
      if(cartListNew.length)
      {
this.$store.dispatch("productOne/fetchRewardsPoints", cartListNew);
      }
      }
      catch(error)
      {
        console.log(error);
      }

    },
    calledCriteoViewBasket() {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/setCriteoViewBasket");
      } else {
        if (this.guestQuoteId) {
          this.$store.dispatch("cartProduct/setCriteoViewBasket");
        }
      }
    },
    removeMinus(val) {

      if (val) return val.toString().substring(1);
    },
    showSnackBarMessage(obj) {
      this.snackbar = {
        show: obj.show,
        message: obj.message,
        color: obj.color,
        timeout: obj.timeout,
      };
    },
    stockFlagHideShow() {
      if (this.cartProductsItem) {
        let errorCount = 0;
        let i;
        for (i = 0; i < this.cartProductsItem.length; i++) {
          if (
            (!this.cartProductsItem[i].stockDetails.isInStock ||
              this.cartProductsItem[i].stockDetails.qty <
                this.cartProductsItem[i].qty) &&
            this.cartProductsItem[i].stockDetails.manageStock &&
            this.cartProductsItem[i].stockDetails.backorders == 0
          ) {
            errorCount++;
          }
        }
        if (errorCount) {
          this.stockFlag = false;
        } else {
          this.stockFlag = true;
        }
      }
    },
    resetGuestValidation() {
      if (!this.validDiscountGuestCode && this.$refs.discountCodeForm) {
        this.$refs.discountCodeForm.reset();
      }
    },
    resetCustomerValidation() {
      if (!this.customerDiscountTextCode && this.$refs.discountCodeMultipleForm) {
        this.$refs.validDiscountCode.reset();
      }
    },
    goToShopping() {
      this.$router.push({
        name: "HomePage",
      });
    },
    payPalPayment() {},
    goToCheckout() {
      this.$router.push({
        name: "CheckoutPage",
        params: {},
      });
    },
    setDiscountTextGuestCode() {
        this.discountTextCode = this.$store.state.cartProduct.discountCode;
    },


    async applyDiscountGuestCode() {
      let validate= await this.$refs.discountCodeForm.validate()
      if (validate && validate.valid) {
          this.$store
            .dispatch("cartProduct/applyDiscountCode", {
              discountTextCode: this.discountTextCode,
              guestQuoteId: this.guestQuoteId,
            })
            .then(() => {
               googleAanalyticsPromoCode("promo_code", this.discountTextCode);
              this.snackbar = {
                message: "Your coupon was successfully applied",
                color: "success",
                show: true,
                timeout: 2000,
              };
              this.$store.dispatch(
                "cartProduct/productTotalAmt",
                this.guestQuoteId
              );
            })
            .catch((error) => {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 2000,
              };
            });

      }
    },
    removeDiscountGuestCode() {
      if (this.$refs.discountCodeForm.validate()) {
          this.$store
            .dispatch("cartProduct/removeDiscountCode", {
              discountTextCode: this.discountTextCode,
              guestQuoteId: this.guestQuoteId,
            })
            .then(() => {
              this.snackbar = {
                message: "Your coupon was successfully removed",
                color: "success",
                show: true,
                timeout: 2000,
              };
              this.$store.dispatch(
                "cartProduct/productTotalAmt",
                this.guestQuoteId
              );
            })
            .catch((error) => {
              this.snackbar = {
                message: error,
                color: "error",
                show: true,
                timeout: 2000,
              };
            });

      }
    },
     async applyDiscountCodeMultiple() {
      let validate= await this.$refs.discountCodeMultipleForm.validate()
      if (validate && validate.valid) {

          this.$store
            .dispatch(
              "userCartProduct/applyDiscountCode",
              this.customerDiscountTextCode
            )
            .then(() => {
               googleAanalyticsPromoCode("promo_code", this.customerDiscountTextCode);
              this.snackbar = {
                message: "Your coupon was successfully applied",
                color: "success",
                show: true,
                timeout: 2000,
              };
              this.customerDiscountTextCode='';
              this.$store.dispatch("userCartProduct/productTotalAmt");
            })
            .catch((error) => {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 2000,
              };
            });
      }
    },
    removeDiscountMultipleCode(item) {
        if (this.userAuth) {

          this.$store
            .dispatch(
              "userCartProduct/removeDiscountCode",
              item
            )
            .then(() => {
              this.snackbar = {
                message: "Your coupon was successfully removed",
                color: "success",
                show: true,
                timeout: 2000,
              };
              this.$store.dispatch("userCartProduct/productTotalAmt");
            })
            .catch((error) => {
              this.snackbar = {
                message: error,
                color: "error",
                show: true,
                timeout: 2000,
              };
            });
        }

    },

  },

  created() {
    if (this.userAuth) {
      this.$store.dispatch("userCartProduct/cartDrawer", false);
      this.$store.dispatch("userCartProduct/fetchCartCouponList");

      this.calledCriteoViewBasket();
    } else {
       this.$store.dispatch("cartProduct/cartDrawer", false);
      if (this.guestQuoteId) {
        this.$store.dispatch("cartProduct/fetchCartCoupon", this.guestQuoteId);
        this.setDiscountTextGuestCode();
        this.calledCriteoViewBasket();
      }
    }
    this.stockFlagHideShow();
  },
};
</script>
<style src="./cart_products.scss" lang="scss" scoped />