<template>
  <div class="white d-flex flex-column crane-concierge-page-height">
   <div v-if="pageContent.length">
      <div v-html="getPageContent()"></div>
    </div>
    <v-row class="d-flex align-end">
      <FooterComponent />
    </v-row>
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
import { addPictureTagInHtmlCode } from "@/services/util.service";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;
 
export default {
  name: "CraneConcierge",
  data() {
    return {};
  },
  components: {
    FooterComponent
  },
  watch: {},
  computed: {
     pageContent: function() {
      return this.$store.state.layout.pageContent;
    },
      canonical: function() {
      return this.$route.meta.canonical;
    }, 
  },
   metaInfo() {
        return {
           
            link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ] 
        }
  },
  methods: {
    getPageContent() {
      if (this.pageContent.length) {
        let data = this.pageContent.filter(element => {
          return element.identifier === "crane-concierge-page";
        });
        if (data.length > 0) {
          let path = data[0].content;
          var div = document.createElement("div");
          div.innerHTML = path;
          let imgSrcUrls = div.getElementsByTagName("img");
          for (let i = 0; i < imgSrcUrls.length; i++) {
            imgSrcUrls[i].parentElement.innerHTML = addPictureTagInHtmlCode({currentImageTag: imgSrcUrls[i], variants: this.variants, mediaImageUrl })
          }
          return div.innerHTML;
        }
      }
    },
     imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PATH_URL;
    },
  }, 
};
</script>
<style src="./crane_concierge.scss" lang="scss" scopped/>