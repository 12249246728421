<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <p class="mt-1 h3 regular-font">Crane Module</p>
        <hr class="mb-4" />
        <div>
          Home Page
          <span class="ml-4">
            <router-link class="text-decoration-none" to="/" target="_blank">/</router-link>
          </span>
        </div>
        <div class="mt-4">
          Category Page
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              :to="{name:'CategoryPage'}"
              target="_blank"
            >/category-page</router-link>
          </span>
        </div>
        <div class="mt-4">
          Product Details Page
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              :to="{name:'ProductPage',params:{sku:'24-MB01'}}"
              target="_blank"
            >/product-details</router-link>
          </span>
        </div>
        <div class="mt-4">
          Builder Designer Mode Page
          <span class="ml-4">
            <a href="https://personalize-daily.crane.com/personalize/designer">
              designer mode
            </a>
          </span>
        </div>
        <div class="mt-4">
          faq Page
          <span class="ml-4">
            <router-link class="text-decoration-none" :to="{name:'FaqPage'}" target="_blank">/faq-page</router-link>
          </span>
        </div>

        <div class="mt-4">
          Store Locator
          <span class="ml-4">
            <router-link class="text-decoration-none" :to="{name:'StoreLocatore'}" target="_blank">/store-locator</router-link>
          </span>
        </div>

        <div class="mt-4">
          Checkout Page
          <span class="ml-4">
            <router-link class="text-decoration-none" :to="{name:'CheckoutPage'}" target="_blank">/checkout</router-link>
          </span>
        </div>
         <div class="mt-4">
          About Us
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              :to="{name:'AboutUs'}"
              target="_blank"
            >/about-us</router-link>
          </span>
        </div>

        <div class="mt-4">
          Etiquette Guide
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              :to="{name:'EtiquetteGuide'}"
              target="_blank"
            >/etiquette-guide</router-link>
          </span>
        </div>

        <div class="mt-4">
          Printing Processes
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              :to="{name:'PrintingProcesses'}"
              target="_blank"
            >/printing-processes</router-link>
          </span>
        </div>

        <div class="mt-4">
          Our Papers
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              :to="{name:'OurPapers'}"
              target="_blank"
            >/our-papers</router-link>
          </span>
        </div>

        <div class="mt-4">
          Accessibility
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              :to="{name:'Accessibility'}"
              target="_blank"
            >/accessibility</router-link>
          </span>
        </div>

        <p class="mt-6 h3 regular-font">Gate Module</p>
        <hr class="mb-4" />
        <div class="mt-4">
          Sign In
          <span class="ml-4">
            <router-link class="text-decoration-none" to="/gate/sign-in" target="_blank">/sign-in</router-link>
          </span>
        </div>
        <div class="mt-4">
          Sign Up
          <span class="ml-4">
            <router-link class="text-decoration-none" to="/gate/sign-up" target="_blank">/sign-up</router-link>
          </span>
        </div>

        <div class="mt-4">
          Forgot Password
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              to="/gate/forgot-password"
              target="_blank"
            >/forgot-password</router-link>
          </span>
        </div>

        <div class="mt-4">
          Reset Password
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              to="/gate/reset-password/554/fegreger271817/"
              target="_blank"
            >/reset-password/:userId/:token</router-link>
          </span>
        </div>

        <p class="mt-6 h3 regular-font">Me Module</p>
        <hr class="mb-4" />

        <div class="mt-4">
          Account Dashboard Page
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              to="/me/account-dashboard"
              target="_blank"
            >/account-dashboard</router-link>
          </span>
        </div>

        <div class="mt-4">
          Edit Account Information Page
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              to="/me/edit-account-info/false"
              target="_blank"
            >/edit-account-info</router-link>
          </span>
        </div>

        <div class="mt-4">
          Store Credit Page
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              to="/me/store-credit-info"
              target="_blank"
            >/store-credit-info</router-link>
          </span>
        </div>

        <div class="mt-4">
          Gift card Page
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              to="/me/gift-card-page"
              target="_blank"
            >/gift-card-page</router-link>
          </span>
        </div>

        <div class="mt-4">
          My Orders Page
          <span class="ml-4">
            <router-link class="text-decoration-none" to="/me/my-orders" target="_blank">/my-orders</router-link>
          </span>
        </div>

        <div class="mt-4">
          Wishlist Page
          <span class="ml-4">
            <router-link class="text-decoration-none" to="/me/wishlist" target="_blank">/wishlist</router-link>
          </span>
        </div>

        <div class="mt-4">
          Address Book Page
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              to="/me/address-book/shipping"
              target="_blank"
            >/address-book</router-link>
          </span>
        </div>

         <div class="mt-4">
          News Letter Subscription Page
          <span class="ml-4">
            <router-link
              class="text-decoration-none"
              to="/me/news-letter-subscription"
              target="_blank"
            >/news-letter-subscription</router-link>
          </span>
        </div>
        
      </v-col>
    </v-row>
  </v-container>
</template>
<script> 
export default {
  name: "shortcutsPage",
};
</script>
