<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="8" md="7">
          <v-img v-bind:src="one.thumbnailUrl" @error="setAltImg()"></v-img>
        </v-col>
        <v-col cols="12" sm="4" md="5">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <h2>Dr.Rares Pop</h2>
                  <p class="mt-2">Azoth</p>
                  <p>CEO</p>
                  <div class="d-flex justify-space-between">
                    <p class="mt-2">
                      <v-icon :icon="mdiPhone"></v-icon>
                      Phone
                    </p>
                    <p class="me-2">123456789</p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p class="mt-2">
                      <v-icon :icon="mdiCakeVariant"></v-icon> Birthday
                    </p>
                    <p class="me-2">29-3-1977</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="9" md="10">
              <p class="regular-font mb-1 body-font">Add to contacts</p>
            </v-col>
            <v-col cols="3" md="2">
              <v-hover v-slot:default="{ isHovering, props }">
                <v-btn
                  v-bind="props"
                  :variant="inProgress || isHovering ? 'outlined' : 'flat'"
                  :color="isHovering ? 'white' : 'primary'"
                  :class="isHovering ? 'text-primary' : 'text-white'"
                  class="text-uppercase ls-0 body rounded-0"
                  tile
                  @click="downloadVCF()"
                >
                  <v-icon :icon="mdiContacts"></v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>

          <v-row>
            <v-col cols="9" md="10">
              <p class="regular-font mb-1 body-font">
                Did you like this? Order yours at crane
              </p>
            </v-col>
            <v-col cols="3" md="2">
              <v-hover v-slot:default="{ isHovering, props }">
                <v-btn
                  v-bind="props"
                  :variant="inProgress || isHovering ? 'outlined' : 'flat'"
                  :color="isHovering ? 'white' : 'primary'"
                  :class="isHovering ? 'text-primary' : 'text-white'"
                  class="text-uppercase ls-0 body rounded-0"
                  tile
                  @click="redirectTOPDPPage()"
                >
                  <v-icon :icon="mdiCart"></v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import vCardFactory from "vcards-js";
import { mdiPhone, mdiCakeVariant, mdiContacts, mdiCart } from "@mdi/js";

const vCard = vCardFactory();
export default {
  name: "businessCardDetailComponent",
  components: {},
  data: () => ({
    mdiPhone,
    mdiCakeVariant,
    mdiContacts,
    mdiCart,
  }),

  computed: {
    builderSessionId() {
      return this.$route.params.builderSessionId;
    },
    one: function () {
      return this.$store.state.sessionItemOne.one;
    },
    sessionOne: function () {
      return this.$store.state.sessionOne.one;
    },
    productDetails: function () {
      return this.$store.state.productOne.productDetails;
    },
  },
  created() {
    this.$store.dispatch("sessionOne/fetchOne", {
      sessionId: this.builderSessionId,
    }),
      this.$store.dispatch("sessionItemOne/fetchOne", {
        sessionId: this.builderSessionId,
      });
  },
  methods: {
    downloadVCF() {
      vCard.firstName = "Dr.Rares";
      vCard.middleName = "";
      vCard.lastName = "POP";
      vCard.workPhone = "123456789";
      vCard.birthday = new Date(1977, 1, 29);

      let vcardString = vCard.getFormattedString();

      var blob = new Blob([vcardString], {
        type: "text/vcard",
      });
      var url = URL.createObjectURL(blob);

      const newLink = document.createElement("a");
      newLink.download = "crane_contact" + ".vcf";
      newLink.textContent = "crane_contact";
      newLink.href = url;

      newLink.click();
    },
    async redirectTOPDPPage() {
      if (
        this.sessionOne &&
        this.sessionOne[0] &&
        this.sessionOne[0].productData
      ) {
        let sku = this.sessionOne[0].productData.sku;
        let productName = this.sessionOne[0].productData.productName;
        this.$router.push({
          name: productName,
        });
         
      }
    },
  },
};
</script>
