<template>
  <div>
    <v-card min-height="650" class="d-flex align-center justify-center" flat>
      <v-card-text class="pb-0 mb-0 px-0 text-center">
        <v-hover v-slot:default="{ hover }">
          <v-btn           
            large
            :outlined="hover"
            :color="hover ? 'white' : 'primary'"
            :class="hover ? 'primary--text' : 'white--text'"
            class="text-uppercase ls-0 body productpage-button"
            tile
            depressed
            @click="checkAllEvents()"
          >
            <span>Purchase</span>
          </v-btn>
        </v-hover>
      
      </v-card-text>
    </v-card> 
    <FooterComponent />
  </div>
</template>
<script> 
const baseUrl = import.meta.env.VITE_BASE_URL; 
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
import { goToCategoryPage, googleAanalyticsEventPDP, googleAanalyticsPurchase, googleAanalyticsCheckout, googleAanalytics, snakeToCamel } from "@/services/util.service";
import { productDetails, cartPurchased, addToCartItems, beginCheckoutParams } from "@/services/staticProductInfo";

export default {
  name: "playground",
  components: { 
    FooterComponent,
  },
  computed: {
    canonical: function () {
      return this.$route.meta.canonical;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
  },
  data: () => ({
    checkoutOptionParams: {
      checkout_step: 1,
      checkout_option: "shipping method",
    },
  }),
  metaInfo() {
    return {
      link: [
        {
          rel: "canonical",
          href: `${
            this.canonical ? window.location.href.split("?")[0] : baseUrl
          }`,
        },
      ],
    };
  },
  methods: {
    routeToPage(pageName) {
      if (pageName == "CategoryPage") {
        let vm = this;
        goToCategoryPage(vm, "stationery", null, null, 10);
      }
    },
    initialLink() {
      return import.meta.env.VITE_HOME_PAGE_URL;
    },
    goToPage(pageLink) {
      window.location.replace(this.initialLink() + pageLink);
    },
    async checkAllEvents() {
     
      await googleAanalyticsPurchase(snakeToCamel(cartPurchased), new Date().getTime());
   
    },
  },
  
};
</script>
<style src="./our_papers.scss" lang="scss" scoped />