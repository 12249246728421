<template>
  <v-container ref="divOne"> 
    <v-row class="my-12" justify="center" v-if="inProgress">
      <v-progress-circular
        :size="60"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <div :class="this.$vuetify.display.xs ? 'mt-n12' : ''" v-else>
      <v-row :class="this.$vuetify.display.xs ? 'px-3' : ''">
        <v-col cols="12" v-if="errorAlert" class="d-flex justify-center">
          <v-alert type="error" width="300" text v-model="errorAlert"
            >Please enter location</v-alert
          >
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn
            block
            size="large"
            rounded="0"
            tile
            color="primary"
            class="px-2"
            @click="getCurrentLocation()"
            max-height="40"
          >
            <span v-if="this.$vuetify.display.mdAndDown">
              <span class="caption">USE CURRENT LOCATION</span>
            
              <v-icon :icon="mdiCrosshairsGps" right size="18"/>
            </span>
            <span v-else>
              USE CURRENT LOCATION
              <!-- <v-icon right>mdi-crosshairs-gps</v-icon> -->
              <v-icon :icon="mdiCrosshairsGps" right/>
            </span>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4">
          
          <vue-google-autocomplete  id="auto-complete" ref="currentPlace"    classname="address-text-control" :types="codeType" placeholder="Enter a location" v-on:placechanged="setPlace" >
          </vue-google-autocomplete>
        
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            variant="outlined"
            density="compact"
             v-model="radius"
             @update:modelValue="setRadius()"
            class="outline-color-black"
            :items="radiusOptions"
            placeholder="Select radius"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row
        :class="
          this.$vuetify.display.xs
            ? 'd-flex flex-column-reverse mb-6'
            : ''
        "
      >
        <v-col class="my-12" align="center" v-if="fetchInProgress">
          <v-progress-circular
            :size="60"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
        <v-col cols="12" sm="4" class="storelocator-retailer-list mt-4" v-else>
          <v-col align="center" v-if="!currentPlace">
            <h2 class="font-weight-thin">Please select location</h2>
          </v-col>
          <v-col align="center" v-else-if="markersList.length == ''">
            <h2 class="font-weight-thin">No store at this place</h2>
          </v-col>

          <div
            :key="index"
            v-for="(storeLocator, index) in markersList"
            class
            v-else
          >
            <v-list dense class="py-1">
              <v-list-item>
                <p class="h4 mb-0">
                  <b>{{ storeLocator.name }}</b>
                </p>
              </v-list-item>
              <v-list-item class="subtitle-2">{{
                storeLocator.address
              }}</v-list-item>
              <v-list-item class="subtitle-2"
                >{{ storeLocator.city }}, {{ storeLocator.state }}
                {{ storeLocator.zip }}</v-list-item
              >
              <v-list-item class="subtitle-2 d-flex justify-space-between">
                <a
                  :href="'tel:' + storeLocator.phoneNumber"
                  class="black--text px-0"
                  >{{ storeLocator.phoneNumber }}</a
                >
                <a
                  target="_blank"
                  href="#"
                  @click.prevent="
                    getDirectionLink(
                      storeLocator.latitude,
                      storeLocator.longitude
                    )
                  "
                  class="black--text"
                  >Directions</a
                >
              </v-list-item>

              <v-divider></v-divider>
            </v-list>
          </div>
        </v-col>
        <v-col cols="12" sm="8" ref="map">
           <GoogleMap  api-key="AIzaSyDmHp0PPuKHD1KGHCDmLq1qQKr_03XKEHo" :center="center" :zoom="12"  ref="mapRef" style="width:100%;  height: 400px;">
       <Marker
       
        :key="index"
        v-for="(m, index) in markers"
        :options="{ position: {lat: m.latitude, lng: m.longitude } }"                     
      >
      <InfoWindow        
        :options="{...infoOptions, position: {lat: m.latitude, lng: m.longitude } }"
      
      >
      <div class="card">
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <h4 class="is-4">{{m.name}}</h4>
                </div>
              </div>
              <div no-gutters class="row">
              <div class="col-md-6">
              <div class="content">
                {{m.address}}<br>
                {{m.city}} , {{m.state}}  {{m.zip}}
              </div>
              </div>
              <div class="col-md-6">
              <div class="content">
              <ul>
                  <li style="text-decoration:underline;"><a class="black--text"
                  href="tel://{{
                    m.phoneNumber
                  }}">{{m.phoneNumber}}</a></li>
                  <br>
                  <li style="text-decoration:underline;"><a
                target="_blank"
                  href="#"
                  @click.prevent="
                    getDirectionLink(
                      m.latitude,
                      m.longitude
                    )
                  "
                class="black--text"
              >Direction</a></li>
                  </ul>
              </div>
              </div>  
              </div>
            </div>
          </div>
      </InfoWindow>
    </Marker>

     
    </GoogleMap>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { mdiCrosshairsGps } from '@mdi/js'

import * as easings from "vuetify/lib/services/goto/easing-patterns";
 
 import { GoogleMap, Marker,InfoWindow } from "vue3-google-map";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "StoreLocatoreComponent", 
      components: { GoogleMap, Marker ,InfoWindow,VueGoogleAutocomplete},

  data: () => ({
    mdiCrosshairsGps,
    easings: Object.keys(easings),
    places: [],
    codeType:'',
    address: "",
    radiusOptions: [10, 25, 50, 100, 250],
    radius: 10,
    currentPlace: "",
    livePositionAddress: null,
    directionUrl: "https://www.google.com/maps/dir/?api=1&origin=",
    currentLocation: null,
    errorAlert: false,
     center: { lat: 40.6971477, lng: -74.2605575 },
      directionUrl:
      "https://www.google.com/maps/dir/?api=1&origin=",
      map: null,
      directions: "Directions",
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null, 
      infoOptions: {
        pixelOffset: {
          width: 0,
          height:0
        }
      }
  }),
  computed: {
   // google:gmapApi,
    storesLocatorList() {
      return this.$store.state.storeLocatorList.storeLocationData;
    },
    markersList() {
      return this.$store.state.storeLocatorList.storeSerchFilterplaces;
    },
    inProgress: function () {
      return this.$store.state.storeLocatorList.inProgress;
    },
    fetchInProgress: function () {
      return this.$store.state.storeLocatorList.fetchInProgress;
    },
     markers() {
      return this.$store.state.storeLocatorList.storeSerchFilterplaces;
    }
  },
  methods: {

      toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = { lat: marker.latitude, lng: marker.longitude };
      this.infoContent = this.getInfoWindowContent(marker); 
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } 
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    getInfoWindowContent: function(marker) {
      return `<div class="card">
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <h4 class="is-4">${marker.name}</h5>
                </div>
              </div>
              <div no-gutters class="row">
              <div class="col-md-6">
              <div class="content">
                ${marker.address}<br>
                ${marker.city} , ${marker.state}  ${marker.zip}
              </div>
              </div>
              <div class="col-md-6">
              <div class="content">
              <ul>
                  <li style="text-decoration:underline;"><a class="black--text"
                  href="tel://${
                    marker.phoneNumber
                  }">${marker.phoneNumber}</a></li>
                  </br>
                  <li style="text-decoration:underline;"><a
                target="_blank"
                 href="${this.getDirectionLink(
                   marker.latitude,
                   marker.longitude
                 )}"
                class="black--text"
              >Direction</a></li>
                  </ul>
              </div>
              </div>  
              </div>
            </div>
          </div>`;
    },
    getDirectionLink(lat, lng) { 
      let url=`${this.directionUrl}${this.livePositionAddress}+&destination=${lat},${lng}`;

      return url;
    },
    geoLocate() {
     
      return new Promise(resolve => {
        navigator.geolocation.getCurrentPosition(position => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          const geocoder = new window.google.maps.Geocoder();
       
          geocoder.geocode({ location: this.center }, function(
            results,
            status
          ) {
            if (status === "OK") {
              const firstResult = results[0];
              if (firstResult) {
             
                resolve({
                  address: firstResult.formatted_address,
                  fullAddress: firstResult
                });
              }
            }
          });
        });
      });
    },
    setLocationOnMap(place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
  
      return this.center;
    },
    setLocationOnMapWithRadius: function(currentPlace, radius) {
      this.center = {
        lat: currentPlace.geometry.location.lat(),
        lng: currentPlace.geometry.location.lng(),
        radius: radius
      };
      this.$store.dispatch("storeLocatorList/fetchStoreByPlaceWithRadius", {
        location: Object.assign({}, currentPlace, this.center),
        radius: radius
      });
    },

     async setPlace(place, placeResultData) {
      
      let newPlacesList = this.storesLocatorList;
      this.currentPlace = placeResultData;
      this.address = placeResultData.formatted_address;
      this.livePositionAddress = placeResultData.formatted_address;
      let searchLocationObjLet = this.degToRad(placeResultData.geometry.location.lat());
      let searchLocationObjLng = this.degToRad(placeResultData.geometry.location.lng());

      let newList = newPlacesList
        .filter((store) => {
          let x =
            (this.degToRad(store.longitude) - searchLocationObjLng) *
            Math.cos(
              (searchLocationObjLet + this.degToRad(store.latitude)) / 2
            );
          let y = this.degToRad(store.latitude) - searchLocationObjLet;
          let storeDistance = Math.sqrt(x * x + y * y) * 3959;
          store.storeDistance = storeDistance;
          return storeDistance <= this.radius;
        })
        .sort((a, b) => {
          return a.storeDistance - b.storeDistance;
        });
      this.$store.dispatch("storeLocatorList/serchFilterplaces", newList); 
      let mapRef=this.$refs.mapRef;
     if (this.circle) this.circle.setMap(null);
      this.circle = new window.google.maps.Circle({
         map:mapRef.map,
        radius: this.radius * 804,
        center: {
          lat: placeResultData.geometry.location.lat(),
          lng: placeResultData.geometry.location.lng(),
        },
        fillColor: "red",
        fillOpacity: 0,
        strokeColor: "green",
        strokeOpacity: 0,
        strokeWeight: 0,
      });
      mapRef.map.fitBounds(this.circle.getBounds()); 
      this.currentLocation = await this.setLocationOnMap(placeResultData);
    },
    degToRad(degrees) {
      return degrees * (Math.PI / 180);
    },
    async getDirectionLink(lat, lng) { 

      let url = `${this.directionUrl}${this.livePositionAddress}+&destination=${lat},${lng}`;
      window.open(url, "_blank");
    },
    async setRadius() {
      let newPlacesList = this.storesLocatorList;
      if (this.currentPlace) {
        this.setPlace(null,this.currentPlace);
      } else {
        this.$store.dispatch(
          "storeLocatorList/serchFilterplaces",
          newPlacesList
        );
      }
    },
    clearAddress: function () {
      this.address = "";
    },
    async getCurrentLocation() {
      
    let returnAddress = await this.geoLocate();
     
     if (returnAddress) this.setPlace(null,returnAddress.fullAddress);
    },
    pageScrollUp() {
    const data= this.$refs.divOne
     if(data){
      this.$smoothScroll({
      scrollTo: data.$el.scrollTop, 
      duration: 300, 
      easingFunction: "easeInOutCubic",
      updateHistory: false
     })
     }
    },
  },
  mounted() {
    this.pageScrollUp(); 
  },
  async created() {
    this.$store.dispatch("storeLocatorList/fetchStoreLocationsList");
    this.$store.dispatch("storeLocatorList/serchFilterplaces", "");
  },
};
</script>
<style src="./StoreLocatoreComponent.scss" lang="scss" scopped/>

