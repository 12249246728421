<template>
  <div class="homepage-overflow-x-hidden white">
 
    <div class="homepage-overflow-x-hidden white" v-if="pageContent.length">
      <div v-html="getPageContent()"></div>
    </div>
  </div>
</template>
<script>
import {
  goToCategoryPageRouting,
  getCategoryOne, addPictureTagInHtmlCode
} from "@/services/util.service";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;

export default {
  name: "GiftGuideComponent",
  components: {},
  computed: {
    pageContent: function () {
      return this.$store.state.layout.pageContent;
    },
    imgHeadTextFirst: function () {
      return "Downton Abbey stationery".replace(/(S+s*){1,2}/g, "$&</br>");
    },
    imgHeadTextSecond: function () {
      return "Vera Wang Papers".replace(/(S+s*){1,2}/g, "$&</br>");
    },
    blockOne() {
      return this.$store.state.block.footerBlock;
    },
    callOutsBlock: function () {
      return this.$store.state.layout.cmsBlockContent;
    },
    categoryDetailsList: function () {
      return this.$store.state.headerCategoryList.categoryDetails;
    },
    
  },
  data: () => ({
    categoryId: 10,
    calloutsblockId: 9,
    pageUrlKey: "home-page-content",
    variants: [1366,768,360],
    mediaImageUrl
  
  }),
  methods: {
    
    getPageContent() {
      if (this.pageContent.length) {
        let data = this.pageContent.filter((element) => {
          return element.identifier === "gift-guide";
        });
        if (data.length > 0) {
          let path = data[0].content;
          var div = document.createElement("div");
          div.innerHTML = path;
          let imgSrcUrls = div.getElementsByTagName("img");
          for (let i = 0; i < imgSrcUrls.length; i++) {
            imgSrcUrls[i].parentElement.innerHTML = addPictureTagInHtmlCode({ currentImageTag: imgSrcUrls[i], variants: this.variants, mediaImageUrl })
          }
          return div.innerHTML;
        }
      }
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PATH_URL;
    },
    getBlockContent() {
      if (this.callOutsBlock.length) {
        let data = this.callOutsBlock.filter((element) => {
          return element.id === this.calloutsblockId;
        });
        let path = data[0].content;
        var div = document.createElement("div");
        div.innerHTML = path;
        let imgSrcUrls = div.getElementsByTagName("img");
        for (let i = 0; i < imgSrcUrls.length; i++) {
          let urlValue = imgSrcUrls[i].getAttribute("src");
          if (urlValue) {
            imgSrcUrls[i].setAttribute(
              "src",

              this.imageBasePathUrl() +
                "/media/" +
                imgSrcUrls[i].src
                  .replace(window.location.href, "")
                  .replace(window.location.origin, "")
                  .replace(/%22%7D%7D/g, "")
                  .replace(/%7B%7B/g, "")
                  .replace(/%20url=%22/g, "")
                  .replace("media", "")
            );
          }
        }
        return div.innerHTML;
      }
    },
    routeToPage(pageName) {
      if (pageName == "CategoryPage") {
        let vm = this;
        goToCategoryPageRouting(vm, { n: "Stationery", id: 10 });
      }
    },
    goToPageRoutePage(pageName) {
      this.$router
        .push({
          name: pageName,
        })
        .catch((error) => {
          error;
        });
    },
    initialLink() {
      return import.meta.env.VITE_HOME_PAGE_URL;
    },
    async goToPage(main, sub, child, categoryId) {
      let categoryOne = await getCategoryOne(
        this.categoryDetailsList,
        categoryId
      );
      let vm = this;
      goToCategoryPageRouting(vm, categoryOne);
    },
    goToLink(pageLink) {
      window.location.replace(pageLink);
  
    },
  },

  created() {
  
  },
};
</script>
<style  lang="scss">
.gift-guide-hero-image {
  height: 62.5%;
  width: 100%;
}
.gift-guide-featured-img-height-500 {
  width: 100%;
}
.gift-guide-content-maxwidth-750 {
  max-width: 750px;
}

.gift-guide-row {
  display: flex;
  height: 100%;
  //width:100%;
}
.gift-guide-block {
  display: block;
  height: 100%;
  //width:100%;
}

.gift-guide-col-6 {
  width: 50%;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}

.gift-guide-moss-red-block {
  background-color: #c53c44;
}
.gift-guide-pink-text-block {
  background-color: #ffeaea;
}
.gift-guide-text {
  color: #ffeaea;
  font-weight: 100;
}
.gift-guide-red-text {
  color: #c53c44;
}
.gift-guide-blue-link {
  color: #2544dd;
}
@media screen and (max-width: 600px) {
  .gift-guide-col-6 {
    width: 100%;
    display: block;
    height: 100%;
  }

  .gift-guide-row {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.gift-guide-link {
  color: #2544dd !important;
  & .home-page-icon {
    background-image: url("@/assets/blue-arrow.png");
    max-width: 22px;
    height: 14px;
    background-position: right center;
    background-size: contain;
  }
}
.gift-guide-link:hover {
  color: #ccb7ff !important;
  & .home-page-icon {
    background-image: url("@/assets/hover-arrow.png");
    max-width: 22px;
    height: 14px;
    background-position: right center;
    background-size: contain;
  }
}
</style>