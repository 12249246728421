<template>
  <div
    v-scroll="onScroll"
    v-resize="OnResize"
    v-bind:class="!heroData ? 'mt-12 page-height' : 'height-100'"
  >
    <v-navigation-drawer
      v-model="showMenu"
      v-if="showMenu"
      width="320"
      :app="showMenu"
      :absolute="!showMenu"
      color="primary"
      tile
    >
      <div class="pl-5 pr-4">
        <v-icon
          :icon="mdiClose"
          @click.stop="showMenu = !showMenu"
          class="text-white cursor-pointer mt-8"
        />
        <v-text-field
          v-model="search"
          autofocus
          variant="underlined"
          type="text"
          class="mobile-header-search-field text-white background-transparent mb-0"
          @blur="showSearchInput = false"
          v-on:keyup.enter="GotoPage"
        >
          <template v-slot:prepend>
            <v-icon
              :icon="mdiMagnify"
              @click="GotoPage()"
              class="text-white magnify-icon mr-n2"
            />
          </template>
        </v-text-field>
      </div>
      <v-row class="mt-12" justify="center" v-if="inProgress">
        <v-progress-circular
          :size="60"
          color="white"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <v-list
        v-else
        class="pl-4 mobile-header-list background-transparent"
        v-for="(categories, index) in categoryList.childrenData"
        v-bind:key="index"
        v-show="index === 0"
        dense
      >
        <v-list-group
          class="mobile-header-list drop-down-icon"
          v-for="(category, i) in categories.childrenData"
          v-bind:key="i"
          :class="
            category.id == activeMainMenu && categoryId
              ? 'active-menu-nav-link'
              : 'mobile-header-list'
          "
          v-show="
            category.name !== 'Baby' &&
            category.name !== 'Gifts' &&
            category.isActive
          "
        >
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props">
              <span
                @click="categoryProducts('main', category.id, '', '')"
                :class="
                  category.id == activeMainMenu && categoryId
                    ? 'active-menu-nav-link'
                    : 'text-white'
                "
                class="text-uppercase h2 my-2"
                >{{ category.name }}</span
              >
            </v-list-item>
          </template>
          <v-list-group
            subgroup
            class="mobile-header-list-subcategory"
            v-for="(subcategorys, index) in category.childrenData"
            v-bind:key="index"
            v-show="subcategorys.isActive"
            :class="
              subcategorys.id == activeSubMenu
                ? 'active-menu-nav-link'
                : 'mobile-header-list-subcategory'
            "
          >
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" class="ml-n12">
                <span
                  @click="
                    categoryProducts(
                      'subManu',
                      category.id,
                      subcategorys.id,
                      ''
                    )
                  "
                  :class="
                    subcategorys.id == activeSubMenu && categoryId
                      ? 'active-menu-nav-link'
                      : 'text-white'
                  "
                  class="text-uppercase h4 no-word-break"
                  >{{ subcategorys.name }}</span
                >
              </v-list-item>
            </template>
            <v-list-item
              v-for="(items, index) in subcategorys.childrenData"
              v-bind:key="index"
              class="mt-n2 pl-0 ml-n8"
            >
              <span
                @click="
                  categoryProducts(
                    'subManuChild',
                    category.id,
                    subcategorys.id,
                    items.id
                  )
                "
                :class="
                  items.id == activeSubMenuItem && categoryId
                    ? 'active-menu-nav-link'
                    : 'text-white'
                "
                class="text-uppercase h5 no-word-break"
                >{{ items.name }}</span
              >
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>

      <v-list class="pl-4 mobile-header-list background-transparent">
        <v-list-item>
          <v-list-item-content class="pa-0">
            <router-link
              class="text-decoration-none"
              :to="{ name: 'StoreLocatore' }"
            >
              <v-list-item-title class="text-white text-uppercase h2 my-2"
                >Store Locator</v-list-item-title
              >
            </router-link>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list class="pl-5 mobile-header-list background-transparent">
        <v-list-group v-if="userAuth" class="drop-down-icon">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props">
              <v-list-item-title class="text-white text-uppercase h3 my-2"
                >Hi {{ userName }}</v-list-item-title
              >
            </v-list-item>
          </template>
          <v-list-item>
            <span
              class="text-white text-uppercase h4 my-2"
              @click="goToDashboard()"
              >My Account</span
            >
          </v-list-item>
          <v-list-item>
            <span
              @click="goToLogout()"
              class="text-white text-uppercase h4 my-2"
              >Logout</span
            >
          </v-list-item>
        </v-list-group>
        <v-list-item v-if="!userAuth">
          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="text-white text-uppercase h3 my-2"
              @click="goToLogin()"
              >Sign-In</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <span ref="divOne" id="divOne"></span>
    <Header
      v-bind:heroData="heroData"
      v-bind:offsetTop="offsetTop"
      @action="onAction"
      @actionScroll="onActionScroll"
      v-show="!maintainanceFlag"
      @logout="fingerprintCheck"
    />

    <HeroContainer
      v-if="heroData"
      v-show="!maintainanceFlag"
      v-bind:heroData="heroData"
    >
      <Header
        v-bind:heroData="heroData"
        v-bind:offsetTop="offsetTop"
        @action="onAction"
        @actionScroll="onActionScroll"
        v-show="!maintainanceFlag"
      />
    </HeroContainer>
    <div></div>
    <router-view />
  </div>
</template>

<script>
import * as easings from "vuetify/lib/services/goto/easing-patterns";
import Header from "@/components/layout/header/Header.vue";
import {
  customAttributeValue,
  goToCategoryPageRouting,
  getCategoryOne,
  snakeToCamel,
  setDdRumUser,
} from "@/services/util.service";
import HeroContainer from "@/components/layout/hero-image/HeroContainer.vue";
import { doFetchCatelogProducts } from "@/services/product.service";
import { createHelpers } from "vuex-map-fields";
import homePageHero from "@/assets/Home Page_Hero.jpg";
import homePageMobile from "@/assets/Home Page_Mobile.jpg";
import storeLocatorImage from "@/assets/CraneStationary-Store-locator.jpg";
import storeLocatorMobileImage from "@/assets/CraneStationary-Store-locator.jpg";
import { mdiMagnify, mdiClose } from "@mdi/js";
const fingerprintCookies = import.meta.env.VITE_FINGERPRINT_COOKIES;

const { mapFields } = createHelpers({
  getterType: "layout/getField",
  mutationType: "layout/updateField",
});
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
export default {
  name: "Layout",
  components: {
    Header,
    HeroContainer,
  },
  props: ["activeMainMenu1"],
  data: () => ({
    mdiMagnify,
    mdiClose,

    search: null,
    searchResultList: [],
    lookupCatelog: null,
    searchInProgress: false,
    easing: "easeInOutCubic",
    easings: Object.keys(easings),
    selector: "#scroll-with-options",
    duration: 100,
    activeMainMenu: "",
    activeSubMenu: "",
    activeSubMenuItem: "",
    offsetTop: 0,
    showMenu: false,
    heroData: null,
    pageNo: 1,
    pageSize: 9,

    blockId: "30",
    sortSelect: { label: "Position", value: "position" },
    aboutContentDescription: `
      <v-list flat class="content-description">
        <v-list-item class="d-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">We believe everyone has a second voice. A voice only found on paper.</v-list-item>
        <v-list-item class="d-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">It may take a moment to come to you, but what you say with it will never fade.</v-list-item>
        <v-list-item class="d-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">Our aim has always been to help you reach beyond limitation and stir your senses in discovering and sharing this voice.</v-list-item>
        <v-list-item class="d-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">By giving it color, shape, weight, and texture.</v-list-item>
        <v-list-item class="d-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">By adding a formal or friendly accent, an inviting or insightful tone.</v-list-item>
        <v-list-item class="d-none d-sm-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">By making it simple to say anything you wish, in a way that’s impossible for anyone to forget.</v-list-item>
        <v-list-item class="d-none d-sm-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">People across the world choose Crane stationery to find their second voice; to express themselves authentically and memorably by putting pen to thoughtfully crafted paper.  </v-list-item>
        <v-list-item class="d-none d-sm-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">With each invitation you send or letter you write, you’ll see that our craftsmanship is second-to-none—because it’s been 250 years in the making.</v-list-item>

      </v-list>`,
    etiquetteContentDescription: `
      <v-list flat class="content-description">
        <v-list-item class="d-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">The word etiquette originates from Old French, translating to “a ticket,” or a token representing a right to enter or participate. Since that time, the definition has evolved to center around conventional —and often intimidating—requirements around social behavior.</v-list-item>
        <v-list-item class="mb-4 fontsize-16 line-height-18pt body-font ls-n016 d-none d-sm-block">But we believe in the original intent of the word: a ticket. At its core, etiquette is about bringing people together. An invitation or thank- you note isn’t a duty or formality—it’s an authentic expression of inclusivity. A ticket that welcomes recipients into your life, where they will feel acknowledged, appreciated, and significant.</v-list-item>
        <v-list-item class="mb-4 fontsize-16 line-height-18pt body-font ls-n016 d-none d-sm-block">That perspective gives etiquette a new meaning. Yes, there are guidelines to help you know when to send a wedding save-the-date or how to address an invitation, and there’s also plenty of flexibility to incorporate your own unique style, tastes, and customs. You can turn to classic options if you prefer, or you can forgo traditional templates and forge your own path.</v-list-item>
        <v-list-item class="mb-4 fontsize-16 line-height-18pt body-font ls-n016 d-none d-sm-block">After all, no matter what you’re writing, the most effective expression can’t be dictated by someone else—it must come from a place that’s truly, authentically you. There’s no right or wrong way to share your message, as long as you find a way to express it.</v-list-item>
         <v-list-item class="mb-4 fontsize-16 line-height-18pt body-font ls-n016 d-none d-sm-block">Yes, when your options for what to say are limitless, the words may take a while to surface. But as you tuck your card into the envelope and envision it in the hands of your recipient, you’ll know: The time you invested was well spent.</v-list-item>
      </v-list>`,
    papersContentDescription: `
      <v-list flat class="content-description">
        <v-list-item class="d-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">The sheen of glossy ink. The crisp tearing of the envelope as you reveal what’s inside. The silky softness of the card, accented by subtle ridges of an embossed design.</v-list-item>
        <v-list-item class="d-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">The paper you choose for your stationery touches every sense. It precedes your message, conveying care and luxury long before the recipient reads the greeting.</v-list-item>
        <v-list-item class="d-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">The quality and finish of your paper can transform any thank you note or invitation, elevating it from an everyday message to a treasured memory that will be kept for years to come.</v-list-item>
         <v-list-item class="d-none d-sm-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">At Crane, we combine time-honored traditions and meticulous craftsmanship to create products ranging from premium business papers to high-end stationery. Our signature paper is crafted from 100 percent cotton for softness and texture, making it the perfect canvas for a variety of printing techniques—or your own handwriting.</v-list-item>
          <v-list-item class="d-none d-sm-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">Ultimately, our core paper types, available in a multitude of sizes, format, colors, and weights, are carefully handcrafted by us to be hand-made by you.</v-list-item>
      </v-list>`,
    printingProcessesContentDescription: `
      <v-list flat class="content-description">
        <v-list-item class="d-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">Like the elegant decor at a party, where you know the host has thought of every detail, your stationery sets the tone for your correspondence.</v-list-item>
        <v-list-item class="d-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">Professionalism and sophistication for your company’s letterhead. Joyful anticipation for your wedding invitations. Sincerity and gratitude for the thank you notes you’ll send following significant life events and small, thoughtful gestures alike.</v-list-item>
        <v-list-item class="d-block mb-4 fontsize-16 line-height-18pt body-font ls-n016">Rather than starting from a blank canvas, you can convey that tone from first glance through Crane’s masterful printing processes. The way the ink is pressed onto the paper, the raised design you can feel underneath your fingers, an array of colors or a sleek, monochromatic finish — every detail works together to give you a distinctive voice before you even begin writing.</v-list-item>
      </v-list>`,
    visitorId: null,
    errorMessage: null,
  }),
  computed: {
    categoryId() {
      return this.$route.meta.id;
    },
    easingOptions() {
      return {
        duration: this.duration,

        easing: this.easing,
      };
    },
    isDesktop: function () {
      return this.$vuetify.display.xsOnly ? true : false;
    },
    ...mapFields(["user", "user.userVisits"]),

    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function () {
      return this.$store.state.guestInfo.guestQuoteId;
    },
    userName: function () {
      return this.$store.state.accountOne.one.firstname;
    },
    inProgress: function () {
      return this.$store.state.headerCategoryList.inProgress;
    },
    categoryList: {
      get: function () {
        return this.$store.state.headerCategoryList.categoryList;
      },
      set: function () {},
    },
    footerBlock: function () {
      return this.$store.state.block.footerBlock;
    },
    maintainanceFlag: function () {
      return this.$route.name == "Maintenance-New" ? true : false;
    },
    categoryDetailsList: function () {
      return this.$store.state.headerCategoryList.categoryDetails;
    },
    basicInfo() {
      return this.$store.state.accountOne.one;
    },
  },
  watch: {
    $route() {
      this.heroData = this.getHeroData();
    },
    lookupCatelog: {
      handler: function (newVal) {
        clearTimeout(this.lookupCatelogProductTimeoutId);
        this.lookupCatelogProductTimeoutId = setTimeout(async () => {
          try {
            if (!newVal) this.searchResultList = [];
            else {
              this.searchInProgress = true;
              let results = await doFetchCatelogProducts(newVal);
              if (results != null) {
                this.searchResultList = snakeToCamel(results);
              } else {
                this.searchResultList = [];
              }
              this.searchInProgress = false;
            }
          } catch (error) {
            this.searchResultList = [];
          }
        }, 500);
      },
    },
  },
  methods: {
    GotoPage() {
      if (this.search) {
        this.onActionScroll();
        this.showSearchInput = false;
        this.searchResultList = [];
        let searchKeywords = this.search;
        let pagePath = this.$route.path;
        let isGateModulePage = pagePath.includes("/gate/");
        if (isGateModulePage) {
          window.open(
            `/search?pageNumber=${this.pageNo}&pageSize=${this.pageSize}&sortBy=relevance&q=${searchKeywords}&filter=e30=`,
            "_self"
          );
        } else {
          this.$router
            .push({
              name: "SearchPage",
              query: {
                pageNumber: this.pageNo,
                pageSize: this.pageSize,
                sortBy: "relevance",
                q: searchKeywords,
                filter: "W10=",
              },
            })
            .catch((error) => {
              error;
            });
          this.$store.dispatch("catalogBrowser/search", {
            fullCatalog: window.craneCatalog,
            keywords: searchKeywords,
            filters: [],
            pageNumber: 1,
            pageSize: 9,
            sortBy: "relevance",
          });
        }
        localStorage.removeItem("cr_subCategoryItemId");
        localStorage.removeItem("cr_subCategoryId");
        localStorage.removeItem("cr_mainCategoryId");
        this.search = null;
      }
    },
    pageName() {
      return this.$route.name;
    },
    onScroll() {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
    },
    onAction(action) {
      this.showMenu = action.showMenu;
      this.categoryList = action.categoryList;
      this.activeMainMenu = localStorage.getItem("cr_mainCategoryId");
      this.activeSubMenuItem = localStorage.getItem("cr_subCategoryItemId");
      this.activeSubMenu = localStorage.getItem("cr_subCategoryId");
      this.search = null;
    },
    onActionScroll() {
      if (this.$refs.divOne) {
        const data = this.$refs.divOne;
        if (data) {
          this.$smoothScroll({
            scrollTo: data.scrollTop,
            duration: 300,
            easingFunction: "easeInOutCubic",
            updateHistory: false,
          });
        }
      }
    },
    OnResize() {
      setTimeout(() => {
        if (this.$vuetify.display.mdAndUp) {
          this.showMenu = false;
        }
      }, 200);
    },
    getHeroData() {
      let currentPage = this.$route.name;
      if (currentPage === "HomePage") {
        return {
          image: homePageHero,
          mobileViewImage: homePageMobile,
          title: "Joyful Greetings",
          content:
            "Personalized holiday greeting cards are here, and 10% off for a limited time",
          buttonText: "Shop the Collection",
        };
      } else if (currentPage === "CategoryPage") {
        return {
          image: "",

          title: "Boxed Stationery Sets",
          content: `Find the perfect stationery box set to suit your taste and personality at Crane.
               Explore a variety of engraved designs, from whimsical images to classic text for
                thank you notes or general correspondence. Each Crane boxed stationery set
               comes with heavyweight note cards and matching fully lined envelopes for a
               complete, polished look.`,
          buttonText: null,
        };
      } else if (currentPage === "StoreLocatore") {
        return {
          image: storeLocatorImage,
          mobileViewImage: storeLocatorMobileImage,
          title: "Store Locator",
          content: ``,
          buttonText: null,
        };
      } else if (currentPage === "AboutUs") {
        return {
          header: "About Us",
          headerDescription: `The history of Crane encompasses 250 years of experience and craftsmanship. Learn more about Crane, Crane heritage, and how it expanded worldwide.`,
          contentHeader: "Share Your Voice",
          contentDescription: this.aboutContentDescription,
        };
      } else if (currentPage === "EtiquetteGuide") {
        return {
          header: "Crane's Etiquette Guide",
          headerDescription: `Etiquette rules have changed over the years, and today, etiquette looks different. Learn the rules of modern etiquette from the experts at Crane Stationery.`,
          contentHeader: "The Spirit of Modern Etiquette",
          contentDescription: this.etiquetteContentDescription,
        };
      } else if (currentPage === "PrintingProcesses") {
        return {
          header: "Our Printing Processes",
          headerDescription: `Various printing techniques can add personality to your correspondence. Learn about different printing processes and techniques from the experts at Crane.`,
          contentHeader: "Set the Tone",
          contentDescription: this.printingProcessesContentDescription,
        };
      } else if (currentPage === "OurPapers") {
        return {
          header: "Our Papers",
          headerDescription: `Crane’s high quality paper is made from cotton for premium softness and texture. Explore our options, from plain paper to high-end stationery.`,
          contentHeader: "The perfect canvas",
          contentDescription: this.papersContentDescription,
        };
      } else if (currentPage === "Accessibility") {
        return {
          header: "accessibility & compliance",
          headerDescription: `Explore a variety of engraved designs, from whimsical images to classic text for thank you notes or general correspondence. Each Crane boxed stationery set comes with heavyweight note cards and matching fully lined envelopes for a complete, polished look.`,
          firstContentHeader: "accessibility notice",
          secondContentHeader: "contact us with your accessibility questions",
          firstContentDescriptionFirst:
            "Crane is committed to accessibility. That commitment means Crane embraces WCAG guidlines and supports assitive technologies such as screen readers.",
          firstContentDescriptionSecond:
            "If you are using a screen reader, magnifier, or other assitive technologies and are experiencing difficulties using this website please call us at (800.555.1212) for assistance.",

          secondContentDescriptionFirst:
            "Please be aware that our efforts are ongoing. if you have questions or concerns about the accessibility website please contact us at CustomerSupports@Crane.com.",
          secondContentDescriptionSecond:
            "If you do encounter an accessiblity issue, please be sure to specify the web page and issue you are having in your email, and we will make all reasonable efforts to make that page accessible for you in the best way possible.",
        };
      } else if (currentPage === "ShippingAndDelivery") {
        return {
          header: "Shipping & Delivery",

          contentDescription:
            "Crane products are exclusively crafted in the United States of America and have been for over 219 years. We are inextricably connected to the wellbeing of our community, family and partners, and their wellbeing will always be our top priority.",
        };
      } else if (currentPage === "Maintenance-New") {
        return {
          header: "Maintenance",

          contentDescription: "Maintenance",
        };
      }
      return null;
    },
    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValue(customAttributes, attributeCode);
    },

    async categoryProducts(flag, category, subcategorys, items) {
      this.onActionScroll();
      let mainCat = "";
      switch (flag) {
        case "main":
          mainCat = category;
          localStorage.setItem("cr_mainCategoryId", category);
          localStorage.setItem("cr_subCategoryItemId", items);
          localStorage.setItem("cr_subCategoryId", subcategorys);
          break;
        case "subManu":
          mainCat = subcategorys;
          localStorage.setItem("cr_mainCategoryId", category);
          localStorage.setItem("cr_subCategoryItemId", items);
          localStorage.setItem("cr_subCategoryId", subcategorys);
          break;
        case "subManuChild":
          mainCat = items;
          localStorage.setItem("cr_mainCategoryId", category);
          localStorage.setItem("cr_subCategoryItemId", items);
          localStorage.setItem("cr_subCategoryId", subcategorys);
          break;
        default:
      }
      if (this.categoryDetailsList) {
        let categoryOne = await getCategoryOne(
          this.categoryDetailsList,
          mainCat
        );
        if (categoryOne) {
          this.$store.dispatch("layout/setHeroContent", categoryOne);

          console.log(categoryOne.n, categoryOne);
          let vm = this;
          goToCategoryPageRouting(vm, categoryOne);
          this.$store
            .dispatch("catalogBrowser/search", {
              fullCatalog: window.craneCatalog,
              keywords: "",
              filters: [{ label: "Category", prop: "ci", value: mainCat }],
              pageNumber: 1,
              pageSize: 9,
              sortBy: "position",
            })
            .catch((error) => {
              error;
            });
        }
      }
    },
    subCategory(subCategoryId) {
      this.$emit("onActionScrollSub");
      this.$emit("action", {
        mainMenu: this.mainMenu,
        subMenu: subCategoryId,
        subMenuItem: subCategoryId,
      });
      this.$store
        .dispatch("catalogBrowser/search", {
          fullCatalog: window.craneCatalog,
          keywords: "",
          filters: [{ label: "Category", prop: "ci", value: subCategoryId }],
          pageNumber: 1,
          pageSize: 9,
          sortBy: "position",
        })
        .catch((error) => {
          error;
        });
    },
    goToLogout() {
      this.$store.dispatch("auth/logout");
    },
    goToLogin() {
      window.open("/gate/sign-in", "_self");
    },
    goToDashboard() {
      window.open("/me/account-dashboard", "_self");
    },
    async setFingerprintUserId()
    {
      try{

       const visitorData = await this.$fpjs.getVisitorData({
            extendedResult: true,
          });

      if(visitorData)
      {
       // this.$store.dispatch("accountOne/setFingerprintWithUserId",{requestId:visitorData.requestId,userId:this.basicInfo.id});
         setDdRumUser({ basicInfo: { fingerprintId: visitorData.visitorId , id: this.basicInfo.id,email: this.basicInfo.email,firstname: this.basicInfo.firstname,lastname: this.basicInfo.lastname} });
      }
      }
      catch (error) {
      console.error("setFingerprintUserId Pro Error:", error);
    }
    },
    async fingerprintCheck()
    {

       try {
      if(!this.userAuth){
        let storedVisitorId = cookies.get(fingerprintCookies);

        if (storedVisitorId) {
          this.visitorId = storedVisitorId;

          setDdRumUser({ basicInfo: { fingerprintId: this.visitorId } });
        } else {
          const visitorData = await this.$fpjs.getVisitorData({
            extendedResult: true,
          });
          if (visitorData) {
            this.visitorId = visitorData.visitorId;

            // Store in a cookie (valid for 1 year)
            cookies.set(fingerprintCookies, this.visitorId, "365d");
            setDdRumUser({ basicInfo: { fingerprintId: this.visitorId } });
          }
        }
      }
    } catch (error) {
      console.error("FingerprintJS Pro Error:", error);
      this.errorMessage = error.message;
    }
    }
  },
  async created() {
    const vm = this;
    this.$store.dispatch("catalogBrowser/index", {
      productsCatalog: window.craneCatalog.products,
    });
    this.$store.dispatch("layout/fetchCmsBlock");
    this.$store.dispatch("layout/fetchPageContent");
    this.$store.dispatch("headerCategoryList/fetchCategoryList");
    this.$store.dispatch("productList/fetchAttributeList");

    if (this.userAuth) {
      this.$store.dispatch("userCartProduct/checkUserToken");
      this.$store.dispatch("userCartProduct/cartProductList");

      await this.$store.dispatch("accountOne/basicInfo");
     // setDdRumUser({ basicInfo: this.basicInfo });
      this.setFingerprintUserId();
    } else {
      if (this.guestQuoteId) {
        this.$store.dispatch("cartProduct/cartProductList", this.guestQuoteId);
        this.$store.dispatch("cartProduct/productTotalAmt", this.guestQuoteId);
      }
      this.$store.dispatch("layout/zinreloInit", {});
    }
    if (this.userVisits) {
      let userVisits = Number(this.userVisits) + 1;
      localStorage.setItem("cr_userVisitCount", userVisits);
      this.userVisits = userVisits;
    } else {
      localStorage.setItem("cr_userVisitCount", 1);
      this.userVisits = 1;
    }
    this.heroData = this.getHeroData();
  },
  async mounted() {
      this.fingerprintCheck();
  },
};
</script>
<style lang="scss">
.header-input-custom {
  .v-input__control {
    background-color: transparent !important;
  }
  .v-input__slot {
    &:before {
      border-color: #ffffff !important;
      border-style: solid;
      border-width: 1px;
    }
    &:after {
      border-color: #ffffff !important;
      border-style: solid;
      border-width: 1px;
    }
  }
  .v-text-field__slot {
    input {
      color: #ffffff !important;
      border-bottom-color: #ffffff !important;
    }
    input::placeholder {
      color: #ffffff !important;
    }
    input:-ms-input-placeholder {
      color: #ffffff !important;
    }

    input ::-ms-input-placeholder {
      color: #ffffff !important;
    }
  }
  .v-icon {
    color: #ffffff !important;
  }
  .mobile-header-list.theme--light.v-list {
    background: #2805da;
  }
  .v-list-item__icon {
    color: #ffffff !important;
  }
}
.mobile-header-autocomplete .v-input__append-inner .v-input__icon {
  display: none !important;
}
.mobile-header-autocomplete .theme--light.v-icon {
  color: #ffffff !important;
}
.mobile-header-autocomplete.theme--light.v-input input {
  color: #ffffff !important;
}
.mobile-header-autocomplete.theme--light.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background-color: transparent !important;
}
</style>
<style src="./Layout.scss" lang="scss"/>